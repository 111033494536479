import { Typography } from '@mui/material';
import React from 'react';

import styles from './styles.module.scss';

export const WarningMessage: React.FC<{ message?: any }> = ({ message }) => {
  return (
    <div className={styles.wrapper}>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.0004 31.1664C18.8612 31.1688 20.704 30.8034 22.4231 30.0913C24.1422 29.3792 25.7037 28.3344 27.0177 27.0169C28.3351 25.7029 29.38 24.1415 30.0921 22.4224C30.8042 20.7033 31.1695 18.8604 31.1671 16.9997C31.1695 15.1389 30.8041 13.2961 30.092 11.577C29.3799 9.8579 28.3351 8.29647 27.0177 6.98244C25.7037 5.66496 24.1422 4.62014 22.4231 3.90805C20.704 3.19595 18.8612 2.83061 17.0004 2.83302C15.1397 2.83065 13.2968 3.19601 11.5777 3.9081C9.85863 4.6202 8.2972 5.66499 6.98317 6.98244C5.66572 8.29647 4.62093 9.8579 3.90883 11.577C3.19674 13.2961 2.83138 15.1389 2.83375 16.9997C2.83134 18.8604 3.19668 20.7033 3.90878 22.4224C4.62088 24.1415 5.66569 25.7029 6.98317 27.0169C8.2972 28.3344 9.85863 29.3792 11.5777 30.0913C13.2968 30.8034 15.1397 31.1687 17.0004 31.1664Z"
          stroke="#E1E0E0"
          strokeWidth="2.83333"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0003 26.2077C17.47 26.2077 17.9204 26.0211 18.2525 25.689C18.5846 25.3569 18.7712 24.9065 18.7712 24.4368C18.7712 23.9672 18.5846 23.5168 18.2525 23.1847C17.9204 22.8526 17.47 22.666 17.0003 22.666C16.5307 22.666 16.0803 22.8526 15.7482 23.1847C15.4161 23.5168 15.2295 23.9672 15.2295 24.4368C15.2295 24.9065 15.4161 25.3569 15.7482 25.689C16.0803 26.0211 16.5307 26.2077 17.0003 26.2077Z"
          fill="#E1E0E0"
        />
        <path
          d="M17.0005 8.5V19.8333"
          stroke="#E1E0E0"
          strokeWidth="2.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Typography className={styles.content}>{message}</Typography>
    </div>
  );
};
