import Typography from '@mui/material/Typography';
import { AppButton, ButtonsType, Layout, Title, TitleType } from 'components';
import { APP_ROUTS } from 'const';
import { useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'store/slices/authSlice';
import { ITeamRole } from 'types';

import StocksTable from './StocksTable';
import SummaryCards from './SummaryCards';
import styles from './styles.module.scss';

type SelectedTab = 'day' | 'week';

const AgencyOverviewPage = () => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const isSimpleUser = user.role === ITeamRole.user;
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('day');

  useEffect(() => {
    if (isSimpleUser) {
      navigate(APP_ROUTS.HOME);
    }
  }, [navigate, isSimpleUser]);

  if (isSimpleUser) {
    return null;
  }

  const switcherCustomStyles = {
    minWidth: 100
  };

  const toggleTab = (tab: SelectedTab) => {
    return () => {
      setSelectedTab(tab);
    };
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <Title type={TitleType.h2}>Data Summary</Title>
            </div>
            <Typography variant="body2">Today Vs Yesterday</Typography>
          </div>
          <SummaryCards />
        </div>
        <div>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <Title type={TitleType.h2}>Control Table</Title>
            </div>
            <div className={styles.switchWrapper}>
              <AppButton
                onClick={toggleTab('day')}
                customStyles={switcherCustomStyles}
                btnType={selectedTab === 'day' ? ButtonsType.borderedFilled : ButtonsType.borderedFilledReverse}
                label="Day"
              />
              <AppButton
                onClick={toggleTab('week')}
                customStyles={switcherCustomStyles}
                btnType={selectedTab === 'week' ? ButtonsType.borderedFilled : ButtonsType.borderedFilledReverse}
                label="Week"
              />
            </div>
          </div>
          <StocksTable />
        </div>
      </div>
    </Layout>
  );
};

export default AgencyOverviewPage;
