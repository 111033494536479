import React from 'react';

import { Title, TitleType } from '../../../components';
import styles from './styles.module.scss';

const Heading: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className={styles.wrapper}>
      <Title type={TitleType.h3}>
        <>{title}</>
      </Title>
    </div>
  );
};

export default Heading;
