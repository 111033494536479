import { Popup } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getActiveItem, setActiveItem } from 'store/slices/responderSlice';

import RespondItem from '../components/ResponderList/Item';
import Form from './Form';
import styles from './styles.module.scss';

interface IReplyPopup {
  show: boolean;
}

const ReplyPopup: React.FC<IReplyPopup> = ({ show }) => {
  const { t } = useTranslation();
  const activeRespondItem = useAppSelector(getActiveItem);
  const dispatch = useAppDispatch();

  if (!show || !activeRespondItem) {
    return null;
  }

  const onClosePopup = () => {
    dispatch(setActiveItem(null));
  };

  return (
    <Popup title={t('pages.responder.replyTitle')} onClose={onClosePopup}>
      <>
        <RespondItem data={activeRespondItem} canReply={false} />
        <div className={styles.formWrapper}>
          <Form activeItem={activeRespondItem} />
        </div>
      </>
    </Popup>
  );
};

export default ReplyPopup;
