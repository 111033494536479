import { AutoCompleteList, CustomSelect, SwitchField } from 'components';
import useDataSourceOptions from 'hooks/useDataSourceOptions';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { Datasource, IConversationUser, ResponseWithPagination } from '../../../../types';
import { catchRequestErrors, fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';

interface FormValues {
  conversationUserId: string;
  isRobot: boolean;
  isBot: boolean;
  dataSource: Datasource | 'all';
}
export const BlackListUserCreate = () => {
  const { register, handleSubmit, setError, control, reset, setValue, watch } = useForm<FormValues>({
    defaultValues: { dataSource: 'all' }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IConversationUser | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataSourceVal = watch('dataSource');

  const formatSuggestion = (data: ResponseWithPagination<IConversationUser>) => {
    return data.list.map((item: any) => ({
      value: item._id,
      name: `${item.userName} ${item.displayName && `(${item.displayName})`}`
    }));
  };

  const chooseUser = (value: string, responseData: ResponseWithPagination<IConversationUser>) => {
    const user = responseData.list.find(({ _id }) => _id === value)!;

    const { isRobot, isBot } = user;
    setValue('isRobot', isRobot);
    setValue('isBot', isBot);

    setSelectedUser(user);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: `${API_ROUTES.CONVERSATION_USERS_BLACKLIST}/${selectedUser?._id}`,
        method: 'PATCH',
        data: {
          ...data
        }
      });

      ToastifyService.setToast('User updated succesfuly.', 'success');
      reset();
      setLoading(false);
      navigate(APP_ROUTS.BLACKLIST_USERS);
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  const dataSourceOptions = useDataSourceOptions();

  return (
    <DashboardLayout
      title={t('pages.admin.usersBlackList.title')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton disabled={!selectedUser} isLoading={loading} label={'Update'} btnType={ButtonsType.signIn} />
      }
    >
      <>
        <ContentBlock>
          <CustomSelect
            name="dataSource"
            register={register}
            control={control}
            data={dataSourceOptions}
            placeholder="Data Source"
          />
        </ContentBlock>
        <ContentBlock>
          <AutoCompleteList
            autocompleteInput={false}
            closeOnSelect
            queryOptions={!dataSourceVal || dataSourceVal === 'all' ? {} : { dataSource: dataSourceVal }}
            onClick={chooseUser}
            url={API_ROUTES.CONVERSATION_USERS}
            placeholder={t('pages.admin.usersBlackList.searchPlaceholder')}
            formatSuggestion={formatSuggestion}
          />
        </ContentBlock>
        {selectedUser && (
          <>
            <div>
              {selectedUser.userName} {selectedUser.displayName && `(${selectedUser.displayName})`}
            </div>
            <ContentBlock>
              <SwitchField
                name="isRobot"
                control={control}
                register={register}
                label={t('pages.admin.usersBlackList.isRobot')}
              />
            </ContentBlock>
            <ContentBlock>
              <SwitchField
                name="isBot"
                control={control}
                register={register}
                label={t('pages.admin.usersBlackList.isBot')}
              />
            </ContentBlock>
          </>
        )}
      </>
    </DashboardLayout>
  );
};
