import AddAlertIcon from '@mui/icons-material/AddAlert';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import React from 'react';

import { SidebarNav } from '../../../components';
import { Layout } from '../../../components/Layout';
import { APP_ROUTS } from '../../../const';
import PageHeader from '../PageHeader';
import styles from './styles.module.scss';

const config = [
  {
    title: 'pages.admin.homeTitle',
    path: APP_ROUTS.HOME,
    icon: HomeIcon
  },
  {
    title: 'pages.admin.stocks',
    path: APP_ROUTS.STOCKS,
    icon: ShowChartIcon
  },
  {
    title: 'pages.admin.stockKits',
    path: APP_ROUTS.STOCK_KITS,
    icon: StackedLineChartIcon
  },
  {
    title: 'pages.admin.teams',
    path: APP_ROUTS.TEAMS,
    icon: Diversity3Icon
  },
  {
    title: 'pages.admin.users',
    path: APP_ROUTS.USERS,
    icon: PersonIcon
  },
  {
    title: 'pages.admin.usersBlackList.title',
    path: APP_ROUTS.BLACKLIST_USERS,
    icon: GroupRemoveIcon
  },
  {
    title: 'pages.admin.analystAlert.title',
    path: APP_ROUTS.ANALYST_ALERT_CREATE,
    icon: AddAlertIcon
  }
];

type DashboardProps = {
  children: React.ReactElement;
  createLink?: React.ReactElement;
  title: string;
  onSubmit?: () => void;
  actionBtn?: React.ReactElement;
};

const DashboardLayout: React.FC<DashboardProps> = ({ children, createLink, title, onSubmit, actionBtn }) => {
  const Tag = onSubmit && typeof onSubmit === 'function' ? 'form' : 'div';
  const customProps = onSubmit && typeof onSubmit === 'function' ? { onSubmit } : {};
  return (
    <Layout>
      <div className={styles.wrapper}>
        <SidebarNav config={config} />
        <Tag className={styles.flexCol} {...customProps}>
          <div className={styles.row}>
            <PageHeader title={title} />
            {!!actionBtn && <div className={styles.gowFalse}>{actionBtn}</div>}
            {!!createLink && createLink}
          </div>
          {children}
        </Tag>
      </div>
    </Layout>
  );
};

export default DashboardLayout;
