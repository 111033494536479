import { UserAva } from 'components';
import AppButton, { ButtonsType } from 'components/AppButton';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { required } from 'services/InputFIeldsValidator/rules';
import ToastifyService from 'services/ToastifyService';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { setActiveItem, setRepliedId } from 'store/slices/responderSlice';
import { IRespondItem, UserAvaType } from 'types';
import { catchRequestErrors, fetchWithConfig, getDateSourceIcon } from 'utils';

import styles from './styles.module.scss';

type FormValues = {
  message: string;
};

interface IFormProps {
  activeItem: IRespondItem;
}

const Form: React.FC<IFormProps> = ({ activeItem }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const stock = useAppSelector(getUserStockSymbol);
  const { _id, dataSource } = activeItem;
  const ResponderIcon = getDateSourceIcon(dataSource);

  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
    setError
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      await fetchWithConfig({
        url: `${API_ROUTES.RESPONDER_REPLY}/${stock}`,
        method: 'POST',
        data: {
          conversationId: _id,
          message: data.message,
          dataSource
        }
      });

      ToastifyService.setToast('Successfully replied', 'success');
      reset();
      setLoading(false);

      dispatch(setActiveItem(null));
      setRepliedId(_id);
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.heading}>
        <UserAva
          type={UserAvaType.topPost}
          data={{
            src: '',
            name: stock,
            Icon: <ResponderIcon />
          }}
        />
      </div>
      <InputField
        className={styles.textArea}
        noFocus
        register={register}
        name="message"
        control={control}
        defaultValue=""
        placeholder={t('pages.responder.replyPlaceholder')}
        type={InputFieldType.textarea}
        errors={errors.message}
        validate={{ required }}
      />
      <div className={styles.submitWrapper}>
        <AppButton
          isLoading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          customStyles={{ minWidth: 145 }}
          btnType={ButtonsType.borderedFilled}
          label={t('buttons.send')}
        />
      </div>
    </div>
  );
};

export default Form;
