import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import React from 'react';

import { Title, TitleType } from '../Title';
import styles from './styles.module.scss';

export const SectionCardHeading: React.FC<{ title: string; subtitle?: string; nativeClassname?: string }> = ({
  title,
  subtitle,
  nativeClassname = ''
}) => {
  return (
    <div className={classNames(styles.wrapper, !!nativeClassname && nativeClassname)}>
      <Title type={TitleType.h3}>
        <>{title}</>
      </Title>
      {subtitle && <Typography variant="body2">{subtitle}</Typography>}
    </div>
  );
};
