import * as React from 'react';
import { SVGProps } from 'react';

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 20 20'} width={20} height={20} fill="none" {...props}>
    <path
      fill="#636C79"
      fillRule="evenodd"
      d="M.832 5.333a2.835 2.835 0 0 1 2.835-2.835h12.666a2.835 2.835 0 0 1 2.835 2.835v9.334a2.835 2.835 0 0 1-2.835 2.835H3.667a2.835 2.835 0 0 1-2.835-2.835V5.333Zm2.835-1.165c-.644 0-1.165.522-1.165 1.165V5.4l6.83 4.78c.401.282.935.282 1.336 0l6.83-4.78v-.066c0-.643-.521-1.165-1.165-1.165H3.667Zm13.831 3.27-5.872 4.11a2.835 2.835 0 0 1-3.252 0l-5.872-4.11v7.229c0 .643.521 1.165 1.165 1.165h12.666c.644 0 1.165-.522 1.165-1.165v-7.23Z"
      clipRule="evenodd"
    />
  </svg>
);
