import { useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getActiveAlertType, setUnreadAlerts } from '../../../store/slices/alertsSlice';
import { getUserStockSymbol } from '../../../store/slices/authSlice';
import { getAlertsFiltersTabsState, setAlertsStorageCounters } from '../../../store/slices/filtersSlice';
import { AlertStorageTypes } from '../../../types';
import { fetchWithConfig } from '../../../utils';

type TCountersResponse = {
  [AlertStorageTypes.INBOX]: {
    unread: number;
    total: number;
  };
  [AlertStorageTypes.ARCHIVED]: {
    unread: number;
    total: number;
  };
};

type TResultResponse = {
  [AlertStorageTypes.INBOX]: number;
  [AlertStorageTypes.ARCHIVED]: number;
};

const useAlertsCounts = (): {
  error: any;
  isLoading: boolean;
  data: TResultResponse | undefined;
} => {
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const dispatch = useAppDispatch();
  const activeAlertType = useAppSelector(getActiveAlertType);
  const { value: activeStorageType, counters: storageCounters } = useAppSelector(getAlertsFiltersTabsState);

  const queryOptions = useMemo(() => {
    return {
      ...(activeAlertType !== 'all' && { type: activeAlertType }),
      storage: activeStorageType
    };
  }, [activeAlertType, activeStorageType]);

  const { data, isLoading, error } = useSWR<TCountersResponse>(
    stockSymbol ? [`${API_ROUTES.ALERTS}/${stockSymbol}/counters`, queryOptions] : null,
    async (options: [string, Record<string, any>]): Promise<TCountersResponse> => {
      const response = await fetchWithConfig<TCountersResponse>({
        url: options[0],
        params: options[1]
      });
      dispatch(setUnreadAlerts(response[activeStorageType].unread || 0));
      dispatch(
        setAlertsStorageCounters({
          [AlertStorageTypes.INBOX]: response[AlertStorageTypes.INBOX].total,
          [AlertStorageTypes.ARCHIVED]: response[AlertStorageTypes.ARCHIVED].total
        })
      );
      return response;
    }
  );

  return {
    error,
    isLoading,
    data: storageCounters
  };
};

export default useAlertsCounts;
