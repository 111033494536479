import { PaletteColorOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });
const windowWidth = window.innerWidth;
export const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#101828'
    },
    primary: {
      main: '#0052cc',
      light: '#38BCF7',
      500: '#069CDF'
    },
    secondary: {
      main: '#edf2ff',
      light: '#F2F4F7'
    },
    grey: {
      100: '#F2F4F7',
      400: '#98A2B3',
      300: '#A4B0C9',
      500: '#667085',
      700: '#344054',
      800: '#1D2939'
    },
    error: {
      main: '#F04438'
    },
    success: {
      main: '#16B364',
      light: '#66C61C',
      contrastText: '#49D28D'
    },
    warning: {
      main: '#EAAA08'
    },
    steelBlue: createColor('#069CDF')
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#101828',
          border: '1px solid #2B3B5B',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

          padding: windowWidth > 400 ? 24 : 12,
          borderRadius: 12,
          backgroundImage: 'none'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          color: '#98A2B3'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12
        }
      }
    }
  }
});

declare module '@mui/material/styles' {
  interface CustomPalette {
    steelBlue: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    steelBlue: true;
  }
}
