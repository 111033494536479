import * as React from 'react';
import { SVGProps } from 'react';

export const Barchart = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 111 106'} width={111} height={106} fill="none" {...props}>
    <path
      stroke="#F2F4F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={10.333}
      d="M46.875 109.376H23.958c-2.917 0-4.375 0-5.49-.568a5.206 5.206 0 0 1-2.275-2.276c-.568-1.114-.568-2.572-.568-5.489V23.959c0-2.917 0-4.375.568-5.49a5.208 5.208 0 0 1 2.276-2.275c1.114-.568 2.572-.568 5.49-.568H38.54c2.918 0 4.376 0 5.49.568.98.499 1.777 1.296 2.276 2.276.568 1.114.568 2.572.568 5.49v12.5m0 72.916h31.25m-31.25 0V36.459m0 0H69.79c2.918 0 4.376 0 5.49.568.98.5 1.777 1.296 2.276 2.276.568 1.114.568 2.573.568 5.49v64.583m0-52.083h22.917c2.916 0 4.375 0 5.489.567.98.5 1.777 1.296 2.276 2.276.568 1.115.568 2.573.568 5.49v35.417c0 2.917 0 4.375-.568 5.489a5.204 5.204 0 0 1-2.276 2.276c-1.114.568-2.573.568-5.489.568H78.125"
      opacity={0.5}
    />
  </svg>
);
