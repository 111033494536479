import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Title, TitleType } from '../../../../components';
import AppButton, { ButtonsType } from '../../../../components/AppButton';
import InputField, { InputFieldType } from '../../../../components/InputField';
import { MailIcon } from '../../../../components/SvgComponents';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { IUser } from '../../../../types';
import { fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';
import styles from './styles.module.scss';

export const UserDetails = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading } = useSWR<IUser>(
    [`${API_ROUTES.USERS}/${userId}`],
    (options: [string, Record<string, any>]): Promise<IUser> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );
  const [loading, setLoading] = useState(false);

  const onRemove = async () => {
    try {
      setLoading(true);
      const response = await fetchWithConfig<{ message: string }>({
        url: `${API_ROUTES.USERS}/${userId}`,
        method: 'DELETE'
      });
      ToastifyService.setToast('User was removed', 'info');
      setLoading(false);
      navigate(APP_ROUTS.USERS, { replace: true });
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
      setLoading(false);
    }
  };
  return (
    <DashboardLayout
      title={t('pages.admin.users')}
      createLink={
        <AppButton
          onClick={onRemove}
          isLoading={isLoading}
          btnType={ButtonsType.simple}
          label={'remove'}
          disabled={isLoading}
        />
      }
    >
      <ContentBlock>
        <>
          <div className={styles.row}>
            <div className={styles.labelWrapper}>
              <p className={styles.label}>{t('pages.admin.usersEmail')}</p>
            </div>
            <div className={styles.labelRight}>
              <div className={styles.labelRight}>{data?.email}</div>
            </div>
          </div>
        </>
      </ContentBlock>
    </DashboardLayout>
  );
};
