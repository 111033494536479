import React from 'react';

import { AlertDetails } from './AlertDetails';
import AlertsList from './AlertsList';
import styles from './styles.module.scss';

const AlertsContainerGrid = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <AlertsList />
      </div>
      <div className={styles.col}>
        <AlertDetails />
      </div>
    </div>
  );
};
export default AlertsContainerGrid;
