import { AutoCompleteList } from 'components';
import { API_ROUTES } from 'const';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface ISuggestionStock {
  name: string;
  symbol: string;
}

interface IChooseCompetitiveStocksProps {
  mainStock: string;
  onChange: (stocks: string[]) => void;
  defaultStocks?: string[];
}

export const ChooseCompetitiveStocks: React.FC<IChooseCompetitiveStocksProps> = ({
  mainStock,
  onChange,
  defaultStocks
}) => {
  const { t } = useTranslation();

  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);

  useEffect(() => {
    if (!defaultStocks) {
      return;
    }

    setSelectedStocks(defaultStocks);
  }, [defaultStocks]);

  useEffect(() => {
    onChange(selectedStocks);
  }, [selectedStocks]);

  useEffect(() => {
    if (!selectedStocks.includes(mainStock)) {
      return;
    }

    setSelectedStocks((prevState) => prevState.filter((stock) => stock !== mainStock));
  }, [mainStock, selectedStocks]);

  const formatSuggestion = useCallback(
    (data: ISuggestionStock[]) => {
      return data
        .filter((item) => item.symbol !== mainStock && !selectedStocks.includes(item.symbol))
        .map((item) => ({
          value: item.symbol,
          name: `${item.name} (${item.symbol})`
        }));
    },
    [mainStock, selectedStocks]
  );

  const onSuggestionClick = (symbol: string) => {
    if (selectedStocks.length >= 3) {
      return;
    }

    setSelectedStocks((prevState) => [...prevState, symbol]);
  };

  const onRemoveSelectedStock = (e: React.MouseEvent<HTMLButtonElement>, symbol: string) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedStocks((prevState) => prevState.filter((item) => item !== symbol));
  };

  return (
    <div>
      <div className={styles.autocompleteWrapper}>
        <AutoCompleteList
          autocompleteInput={false}
          onClick={onSuggestionClick}
          url={API_ROUTES.STOCKS_SUGGESTIONS}
          placeholder={t('pages.competitiveAnalysis.configurePopup.searchPlaceholder')}
          formatSuggestion={formatSuggestion}
        />
      </div>
      <div className={styles.selectedStocks}>
        {selectedStocks.map((symbol) => {
          return (
            <div className={styles.selectedStock} key={symbol}>
              <div>{symbol}</div>
              <button
                onClick={(e) => {
                  onRemoveSelectedStock(e, symbol);
                }}
                className={styles.removeSelectedStock}
              >
                &#10006;
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
