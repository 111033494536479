import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const TabFilterItem: React.FC<{
  label: string;
  count?: string;
  onClick: () => void;
  isActive: boolean;
  Icon?: React.ReactElement;
}> = ({ label, count, onClick, isActive, Icon }) => {
  return (
    <button className={classNames(styles.btn, { [styles.active]: isActive })} onClick={onClick}>
      {Icon && <span className={styles.icon}>{Icon}</span>}
      {label}
      {count && (
        <div className={styles.nowrap}>
          <span>{count}</span>
        </div>
      )}
    </button>
  );
};

export default TabFilterItem;
