import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactNode } from 'react';

import styles from './styles.module.scss';

type DataType = {
  stock: string;
  value: string;
};

interface ICardProps {
  title: string;
  Icon: ReactNode;
  data: { up: DataType; down: DataType }[];
}

const Card = (props: ICardProps) => {
  const { title, Icon, data } = props;
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {Icon}
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.cols}>
        <div className={styles.col}>
          <KeyboardArrowUpIcon className={styles.positive} fontSize="large" />
        </div>
        <div className={styles.col}>
          <KeyboardArrowDownIcon className={styles.negative} fontSize="large" />
        </div>
      </div>
      {data.map((item, index) => {
        const { up, down } = item;
        return (
          <div key={index} className={styles.cols}>
            <div className={styles.col}>
              {up.stock} {up.value}
            </div>
            <div className={styles.col}>
              {down.stock} {down.value}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Card;
