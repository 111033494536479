import { AlertTypes } from 'modules/Alerts/types';
import { Datasource } from 'types';

export const alertsListPreloaderData = [
  {
    _id: '1',
    title: 'Stock mentioned by influencer',
    subTitle: 'subTitle',
    insights: {
      header:
        'Your stock was mentioned by the influencer Seeking Alpha, with a potential reach of 240650. This influencer usually posts about: ',
      body: [
        "Main Topic 1: AMC Entertainment's stock performance and future prospects",
        '',
        "Explanation 1: AMC Stock Soars Big - What's Next?",
        '',
        'Explanation 2: Even The Best Case Justifies A Sell Rating.',
        '',
        'Explanation 3: Bankruptcy Is Almost Certain.',
        '',
        'Main Topic 2: The impact of external factors on AMC Entertainment',
        '',
        'Explanation 1: Be Prepared For The Taylor Swift Effect.',
        '',
        'Explanation 2: Consider Arizona Metals Ahead Of Expected Gold/Copper Recovery.',
        '',
        'Main Topic 3: Stocks to watch in the market',
        '',
        "Explanation 1: Spotlight On Nvidia, AMC Entertainment, Snowflake, Twilio And DICK'S Sporting Goods."
      ]
    },
    recommendation:
      'Your IR might consider to reach out to the influencer for further engagement and possible collaboration.',
    createdAt: '2023-09-26T12:31:18.245Z',
    type: AlertTypes.HATER_IDENTIFIED,
    dataSource: Datasource.Twitter,
    isRead: false
  },
  {
    _id: '2',
    title: 'Stock mentioned by influencer',
    subTitle: 'subTitle',
    dataSource: Datasource.Twitter,
    insights: {
      header:
        'Your stock was mentioned by the influencer Seeking Alpha, with a potential reach of 240650. This influencer usually posts about: ',
      body: [
        "Main Topic 1: AMC Entertainment's stock performance and future prospects",
        '',
        "Explanation 1: AMC Stock Soars Big - What's Next?",
        '',
        'Explanation 2: Even The Best Case Justifies A Sell Rating.',
        '',
        'Explanation 3: Bankruptcy Is Almost Certain.',
        '',
        'Main Topic 2: The impact of external factors on AMC Entertainment',
        '',
        'Explanation 1: Be Prepared For The Taylor Swift Effect.',
        '',
        'Explanation 2: Consider Arizona Metals Ahead Of Expected Gold/Copper Recovery.',
        '',
        'Main Topic 3: Stocks to watch in the market',
        '',
        "Explanation 1: Spotlight On Nvidia, AMC Entertainment, Snowflake, Twilio And DICK'S Sporting Goods."
      ]
    },
    recommendation:
      'Your IR might consider to reach out to the influencer for further engagement and possible collaboration.',
    createdAt: '2023-09-26T12:31:18.245Z',
    type: AlertTypes.HATER_IDENTIFIED,
    isRead: false
  },
  {
    _id: '3',
    title: 'Stock mentioned by influencer',
    subTitle: 'subTitle',
    dataSource: Datasource.Twitter,
    insights: {
      header:
        'Your stock was mentioned by the influencer Seeking Alpha, with a potential reach of 240650. This influencer usually posts about: ',
      body: [
        "Main Topic 1: AMC Entertainment's stock performance and future prospects",
        '',
        "Explanation 1: AMC Stock Soars Big - What's Next?",
        '',
        'Explanation 2: Even The Best Case Justifies A Sell Rating.',
        '',
        'Explanation 3: Bankruptcy Is Almost Certain.',
        '',
        'Main Topic 2: The impact of external factors on AMC Entertainment',
        '',
        'Explanation 1: Be Prepared For The Taylor Swift Effect.',
        '',
        'Explanation 2: Consider Arizona Metals Ahead Of Expected Gold/Copper Recovery.',
        '',
        'Main Topic 3: Stocks to watch in the market',
        '',
        "Explanation 1: Spotlight On Nvidia, AMC Entertainment, Snowflake, Twilio And DICK'S Sporting Goods."
      ]
    },
    recommendation:
      'Your IR might consider to reach out to the influencer for further engagement and possible collaboration.',
    createdAt: '2023-09-26T12:31:18.245Z',
    type: AlertTypes.HATER_IDENTIFIED,
    isRead: false
  },
  {
    _id: '4',
    title: 'Stock mentioned by influencer',
    subTitle: 'subTitle',
    dataSource: Datasource.Twitter,
    insights: {
      header:
        'Your stock was mentioned by the influencer Seeking Alpha, with a potential reach of 240650. This influencer usually posts about: ',
      body: [
        "Main Topic 1: AMC Entertainment's stock performance and future prospects",
        '',
        "Explanation 1: AMC Stock Soars Big - What's Next?",
        '',
        'Explanation 2: Even The Best Case Justifies A Sell Rating.',
        '',
        'Explanation 3: Bankruptcy Is Almost Certain.',
        '',
        'Main Topic 2: The impact of external factors on AMC Entertainment',
        '',
        'Explanation 1: Be Prepared For The Taylor Swift Effect.',
        '',
        'Explanation 2: Consider Arizona Metals Ahead Of Expected Gold/Copper Recovery.',
        '',
        'Main Topic 3: Stocks to watch in the market',
        '',
        "Explanation 1: Spotlight On Nvidia, AMC Entertainment, Snowflake, Twilio And DICK'S Sporting Goods."
      ]
    },
    recommendation:
      'Your IR might consider to reach out to the influencer for further engagement and possible collaboration.',
    createdAt: '2023-09-26T12:31:18.245Z',
    type: AlertTypes.HATER_IDENTIFIED,
    isRead: false
  }
];
