import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { IStockKit } from '../types';
import { fetchWithConfig } from '../utils';

export type IStockKitForSelect = Pick<IStockKit, '_id' | 'name'>;

const useOptionStockKits = () => {
  const [kitsData, setKitsData] = useState<IStockKitForSelect[]>([]);
  const { data, error, isLoading } = useSWR<IStockKitForSelect[]>(API_ROUTES.SELECT_OPTION_STOCK_KITS, (url) =>
    fetchWithConfig({ url })
  );

  useEffect(() => {
    setKitsData(data || []);
  }, [data]);

  return {
    stockKitsOptionData: kitsData,
    stockKitsOptionError: error,
    stockKitsOptionLoading: isLoading
  };
};

export default useOptionStockKits;
