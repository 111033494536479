import { useAppSelector } from 'hooks';
import { useMemo } from 'react';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { getFiltersDatasource, getResponderFiltersTabsState } from 'store/slices/filtersSlice';
import { getSort } from 'store/slices/responderSlice';
import { ResponderTabsTypes } from 'types';

const useResponderList = () => {
  const { value, page } = useAppSelector(getResponderFiltersTabsState);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const dataSource = useAppSelector(getFiltersDatasource);
  const sort = useAppSelector(getSort);

  const filtersQuery = useMemo(() => {
    if (value === ResponderTabsTypes.ALL) {
      return { dataSource, sort };
    }

    if (value === ResponderTabsTypes.STARRED) {
      return { isStarred: true, dataSource, sort };
    }

    if (value === ResponderTabsTypes.REPLIED) {
      return { isReplied: true, dataSource, sort };
    }

    if (value === ResponderTabsTypes.ARCHIVED) {
      return { isArchived: true, dataSource, sort };
    }

    return { dataSource, sort };
  }, [value, dataSource, sort]);

  return { page, query: filtersQuery, stockSymbol };
};

export default useResponderList;
