import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AppButton, ButtonsType } from 'components';
import { API_ROUTES } from 'const';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface IMoveToResponderProps {
  convId: string;
  defaultInResponder: boolean;
  stock: string;
}

const MoveToResponder = (props: IMoveToResponderProps) => {
  const { convId, defaultInResponder, stock } = props;
  const [isInResponder, setIsInResponder] = useState(defaultInResponder);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const moveToResponder = async () => {
    setIsLoading(true);
    try {
      await fetchWithConfig<any>({
        url: `${API_ROUTES.RESPONDER_MARK}/${stock}/${convId}`,
        method: 'POST'
      });
      setIsInResponder(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isInResponder ? (
        <div className={styles.responderLabel}>
          <CheckCircleIcon />
          Responder
        </div>
      ) : (
        <AppButton
          isLoading={isLoading}
          label={t('buttons.moveToResponder')}
          btnType={ButtonsType.simpleSave}
          onClick={moveToResponder}
        />
      )}
    </>
  );
};

export default MoveToResponder;
