import { Datasource, IAlertConversation, IAlertUser, MongoDbId } from '../../types';

export interface IAlertsDetailsResponse extends MongoDbId {
  title: string;
  subTitle: string;
  type: AlertTypes;
  createdAt: string;
  recommendation: string;
  insights: {
    header: string;
    body: string[];
  };
  dataSource: Datasource;
  isRead: boolean;
  user?: IAlertUser;
  conversation?: IAlertConversation;
}

export interface IAlertsListItemResponse {
  _id: string;
  title: string;
  type: AlertTypes;
  createdAt: string;
  dataSource: Datasource;
  isRead: boolean;
}

export enum AlertTypes {
  VOLUME_INCREASE = 1,
  INFLUENCER = 6,
  HATER_IDENTIFIED = 12,
  ANALYST = 14
}
