import ParsedTextWithLink from 'components/ParsedTextWithLink';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IReadMoreProps {
  children: string;
  symbols?: number;
  withParsedLinks?: boolean;
}

export const ReadMore: React.FC<IReadMoreProps> = ({ children, symbols = 300, withParsedLinks }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation();
  const canReadMore = text.length > symbols;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const getSlicedText = () => {
    const trimmedContent = text.slice(0, symbols);
    const lastSpaceIndex = trimmedContent.lastIndexOf(' ');

    return trimmedContent.slice(0, lastSpaceIndex) + '...';
  };

  const textContent = isReadMore && canReadMore ? getSlicedText() : text;

  return (
    <>
      {withParsedLinks ? <ParsedTextWithLink text={textContent} /> : textContent}
      {canReadMore && (
        <>
          <br />
          <button onClick={toggleReadMore} className={styles.toggleButton}>
            {isReadMore ? t('buttons.readMore') : t('buttons.showLess')}
          </button>
        </>
      )}
    </>
  );
};
