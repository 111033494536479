import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { ISubscriptionPlan } from '../types';
import { fetchWithConfig } from '../utils';

const useOptionSubscriptions = () => {
  const { data, error, isLoading } = useSWR<any>(API_ROUTES.SUBSCRIPTION_PLANS, (url) => fetchWithConfig({ url }));

  return {
    subscriptions: (data as ISubscriptionPlan[]) || [],
    subscriptionsError: error,
    subscriptionsLoading: isLoading
  };
};

export default useOptionSubscriptions;
