import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRecord, ITableColumns } from 'types';

const CrudTableRowList = <TData extends BaseRecord = any>(props: {
  data: TData[];
  columns: ITableColumns<TData>[];
  isLoading: boolean;
  mutate: any;
}) => {
  const { t } = useTranslation();
  const { data, columns, isLoading, mutate = () => {} } = props;
  return (
    <>
      {data.map((row: TData, index: number) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={`row_${index}`}>
            {columns.map((column, indexCol) => {
              const align = columns[indexCol]?.align ? { align: columns[indexCol].align } : {};
              if (isLoading) {
                return (
                  <TableCell key={indexCol} size={'small'} {...align}>
                    <>{indexCol}</>
                  </TableCell>
                );
              }

              const colContent = row[column.key];

              const innerTd =
                typeof column.render === 'function'
                  ? column.render(row, colContent, mutate, t)
                  : colContent?.toString() || '';
              return (
                <TableCell key={indexCol} style={column.width ? { width: column.width } : {}} {...align}>
                  <>{innerTd}</>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default CrudTableRowList;
