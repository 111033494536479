import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { AppButton, BulletListItem, ButtonsType, UserAva } from 'components';
import ParsedTextWithLink from 'components/ParsedTextWithLink';
import { IAlertsDetailsResponse } from 'modules/Alerts/types';
import MoveToResponder from 'modules/Conversation/components/MoveToResponder';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvaType } from 'types';
import { getDateSourceIcon } from 'utils';

import styles from './styles.module.scss';

const InfluencerContent = ({ details }: { details: IAlertsDetailsResponse }) => {
  const { insights, user, conversation, dataSource } = details;
  const { t } = useTranslation();
  const SourceIcon = getDateSourceIcon(dataSource!);

  const bodyInsights = insights?.body || [];

  return (
    <>
      <div className={styles.alertInflHeading}>
        <UserAva
          type={UserAvaType.topUser}
          data={{
            name: user?.displayName!,
            src: user?.image!,
            bottomLabel: (
              <a
                className={styles.outerLink}
                href={user?.profileUrl!}
                target="blank"
                rel="noopener"
                onClick={(e: any) => e.stopPropagation()}
              >
                {user?.userName!}
              </a>
            )
          }}
          size={40}
        />
        <SourceIcon />
      </div>
      <div className={styles.influenceInsights}>
        {bodyInsights.map((item) => (
          <BulletListItem key={item} text={item} />
        ))}
      </div>
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <SpeakerNotesIcon />
          {t('alerts.message')}
        </div>
        <div className={styles.content}>
          <ParsedTextWithLink text={conversation?.body!} />
        </div>
        <div className={styles.contentActions}>
          <AppButton
            label={t('buttons.viewPost')}
            btnType={ButtonsType.borderedTransparentLowBorder}
            onClick={() => window.open(conversation?.url)}
          />
          <MoveToResponder
            stock={conversation?.stock!}
            defaultInResponder={conversation?.isResponderMark!}
            convId={conversation?._id!}
          />
        </div>
      </div>
    </>
  );
};

export default InfluencerContent;
