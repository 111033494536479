import React from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { IStock } from '../types';
import { fetchWithConfig } from '../utils';

const useOptionStocks = () => {
  const { data, error, isLoading } = useSWR<IStock[]>(API_ROUTES.SELECT_OPTION_STOCKS, (url) =>
    fetchWithConfig({ url })
  );

  return { stocksOptionData: data as IStock[], stocksOptionError: error, stocksOptionLoading: isLoading };
};

export default useOptionStocks;
