import * as React from 'react';
import { SVGProps } from 'react';

export const VisibilityOfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg className="svg-icon" width={20} height={20} viewBox="0 0 1024 1024" {...props}>
    <path
      fill="#636C79"
      d="M836.48 708.48c-9.6 0-18.56-3.84-24.96-12.16-10.88-14.08-8.96-33.92 5.12-44.8 30.72-24.32 58.24-53.12 81.28-84.48 18.56-24.32 18.56-57.6 0-82.56-87.68-117.76-221.44-184.96-368-184.96-60.16 0-119.04 11.52-174.08 33.92-16.64 7.04-35.2-1.28-41.6-17.28-6.4-16.64 1.28-35.2 17.28-41.6 62.72-25.6 129.92-39.04 198.4-39.04 164.48 0 321.28 78.72 419.2 211.2 35.2 47.36 35.2 111.36 0 158.72-26.88 35.84-57.6 67.84-92.8 96-5.76 5.12-12.8 7.04-19.84 7.04zM529.92 816.64c-164.48 0-321.28-78.72-419.2-211.2-35.2-47.36-35.2-111.36 0-158.72 21.12-28.8 46.08-55.68 72.96-79.36 13.44-11.52 33.28-10.24 45.44 2.56 11.52 13.44 10.24 33.28-2.56 45.44-23.68 21.12-45.44 44.16-64 69.76-18.56 24.32-18.56 57.6 0 82.56 87.04 117.12 220.8 184.96 367.36 184.96 60.16 0 119.04-11.52 174.08-34.56 16.64-7.04 35.2 1.28 41.6 17.28 7.04 16.64-1.28 35.2-17.28 41.6-62.72 26.24-129.28 39.68-198.4 39.68z"
    />
    <path
      fill="#636C79"
      d="M525.44 680.96c-86.4 0-156.8-70.4-156.8-156.8 0-17.92 14.08-32 32-32s32 14.08 32 32c0 51.2 41.6 92.8 92.8 92.8 21.76 0 42.24-7.04 58.88-21.12 13.44-11.52 33.92-8.96 44.8 4.48 11.52 13.44 8.96 33.92-4.48 44.8-27.52 23.68-62.72 35.84-99.2 35.84zm124.8-124.16c-17.92 0-32-14.08-32-32 0-51.2-41.6-92.8-92.8-92.8-20.48 0-40.32 6.4-56.96 19.2a31.744 31.744 0 0 1-44.8-5.76c-10.88-14.08-8.32-33.92 5.76-44.8 27.52-21.76 60.8-32.64 96-32.64 86.4 0 156.8 70.4 156.8 156.8 0 17.28-14.08 32-32 32z"
    />
    <path
      fill="#636C79"
      d="M815.36 880.64c-8.96 0-17.28-3.84-23.68-10.88L197.76 211.2c-11.52-13.44-10.88-33.28 2.56-45.44 13.44-11.52 33.28-10.88 45.44 2.56l593.28 659.2c11.52 13.44 10.88 33.28-2.56 45.44-6.4 5.12-13.44 7.68-21.12 7.68z"
    />
  </svg>
);
