import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import i18n from 'i18n/i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { SWRConfig } from 'swr';
import { theme } from 'theme';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename="/">
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                errorRetryCount: 0
              }}
            >
              <App />
            </SWRConfig>
          </BrowserRouter>
        </I18nextProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>
);
