import { Alerts, ExecutiveSummary, StockNegativePositiveTopics } from 'modules';
import { Conversation } from 'modules';
import ChartToMarket from 'modules/Charts/ChartToMarket';
import { TUiConfigItem } from 'types';

import ChartsSection from './ChartsSection';
import SentimentGraphsSection from './ChartsSection/SetinementGraphs';

type IGeneralUiConfigComponents = Partial<{
  [key in TUiConfigItem['uiKey']]: any;
}>;

type IChartsUiConfigComponents = Partial<{
  [key in TUiConfigItem['uiKey']]: any;
}>;

export const generalUiConfigComponents: IGeneralUiConfigComponents = {
  summary: ExecutiveSummary,
  alerts: Alerts,
  topics: StockNegativePositiveTopics,
  charts: ChartsSection
};

export const chartsUiConfigComponents: IChartsUiConfigComponents = {
  market: ChartToMarket,
  sentiment: SentimentGraphsSection,
  top: Conversation
};
