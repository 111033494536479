import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SideBarConfig } from '../../types';
import NavItem from './NavItem';
import styles from './styles.module.scss';

export const SidebarNav: React.FC<{ config: SideBarConfig[] }> = ({ config }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className={classNames(styles.menuIcon, { [styles.isActive]: isOpen })} onClick={toggleIsOpen}>
        <span className={styles.line}></span>
        <span className={styles.line}></span>
        <span className={styles.line}></span>
      </button>
      <div className={classNames(styles.wrapper, { [styles.isActive]: isOpen })}>
        {config.map((item) => {
          return <NavItem key={item.title} to={item.path} title={t(item.title)} Icon={item.icon} />;
        })}
      </div>
    </div>
  );
};
