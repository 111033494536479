import { Title, TitleType } from 'components';
import React from 'react';

import styles from './styles.module.scss';

const FormGroup: React.FC<{
  children: React.ReactElement;
  submitLink?: React.ReactElement;
  title: string;
  subtitle?: string;
  onSubmit?: () => void;
}> = ({ children, submitLink, title, subtitle, onSubmit = () => {} }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexCol}>
        <form className={styles.row} onSubmit={onSubmit}>
          <div>
            <Title type={TitleType.h3}>
              <>{title}</>
            </Title>
            {!!subtitle && <p className={styles.sublabel}>{subtitle}</p>}
          </div>
          {!!submitLink && submitLink}
        </form>
        {children}
      </div>
    </div>
  );
};

export default FormGroup;
