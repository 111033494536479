import { Box, Skeleton } from '@mui/material';
import React from 'react';

import { SectionCard, Title, TitleType } from '../index';
import styles from './styles.module.scss';

const CardSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <SectionCard nativeClassName={styles.card}>
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.titleIcon}>
              <Skeleton width={30} height={30} variant="circular" />
            </div>
            <Title type={TitleType.h3}>
              <Skeleton width={100} />
            </Title>
          </div>
          <>
            <div className={styles.title}>
              <Skeleton width="60%" />
            </div>
            <div className={styles.row}>
              <Box sx={{ width: '100%' }}>
                <Skeleton height={24} width="70%" />
              </Box>
            </div>
          </>
        </>
      </SectionCard>
    </div>
  );
};

export default CardSkeleton;
