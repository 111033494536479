import { AppButton, BulletListItem, ButtonsType, CheckboxComponent, Popup } from 'components';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormValues } from '../index';
import styles from './styles.module.scss';

interface ISubmitPopupProps {
  show: boolean;
  data: FormValues;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
}

const SubmitPopup = (props: ISubmitPopupProps) => {
  const { show, onClose, data, onSubmit, loading } = props;
  const { register, control, watch, setValue } = useForm<{ agreed: boolean }>({
    defaultValues: {
      agreed: false
    }
  });

  const isAgreed = watch('agreed');

  useEffect(() => {
    if (show) {
      return;
    }

    setValue('agreed', false);
  }, [show]);

  if (!show) {
    return null;
  }

  const { stock, topics, title, emails } = data;

  return (
    <Popup title="Analyst Report" onClose={onClose}>
      <>
        <p>
          Are you sure you want to submit an Alert for “{stock}”? Please make sure the data is verified and accurate.
        </p>
        <div className={styles.details}>
          <h3>{title}</h3>
          <ul className={styles.topics}>
            {topics.map((topic, index) => (
              <li key={index} className={styles.topic}>
                <BulletListItem text={topic} />
              </li>
            ))}
          </ul>
          <p className={styles.emailsTitle}>The alert will be sent to the following emails:</p>
          <div>{emails.join(', ')}</div>
          <CheckboxComponent
            control={control}
            defaultValue={false}
            register={register}
            name="agreed"
            label="I approve this topics"
          />
        </div>
        <div className={styles.actions}>
          <AppButton isLoading={loading} btnType={ButtonsType.simple} label="Cancel" onClick={onClose} />
          <AppButton
            isLoading={loading}
            disabled={!isAgreed}
            btnType={ButtonsType.outlined}
            label="Submit"
            onClick={onSubmit}
          />
        </div>
      </>
    </Popup>
  );
};

export default SubmitPopup;
