import { Layout } from 'components';
import React from 'react';

const NotFoundPage = () => {
  return (
    <Layout>
      <h2>404</h2>
    </Layout>
  );
};

export default NotFoundPage;
