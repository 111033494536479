import { NoData } from 'components';
import BluresSpinner from 'components/BluresSpinner';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAlertsDetailsResponse } from 'modules/Alerts/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearActiveAlert,
  getActiveAlertId,
  getIsLoadingDetailsAlert,
  setIsLoadingDetailsAlert
} from 'store/slices/alertsSlice';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { fetchWithConfig } from 'utils';

import AlertContent from './Content';
import styles from './styles.module.scss';

export const AlertDetails = () => {
  const [alertDetails, setAlertDetails] = useState<IAlertsDetailsResponse | null>(null);
  const isLoadingDetails = useAppSelector(getIsLoadingDetailsAlert);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const activeAlertId = useAppSelector(getActiveAlertId);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const fetchAlert = async (id: string) => {
    dispatch(setIsLoadingDetailsAlert(true));
    try {
      const data = await fetchWithConfig<IAlertsDetailsResponse>({
        url: `${API_ROUTES.ALERTS}/${stockSymbol}/details/${id}`
      });
      setAlertDetails(data);
      dispatch(setIsLoadingDetailsAlert(false));
    } catch (error) {
      dispatch(setIsLoadingDetailsAlert(false));
    }
  };

  useEffect(() => {
    if (!activeAlertId) {
      return;
    }

    fetchAlert(activeAlertId);
  }, [activeAlertId]);

  useEffect(() => {
    dispatch(clearActiveAlert());
  }, [dispatch, stockSymbol]);

  if (!activeAlertId && !isLoadingDetails) {
    return (
      <div className={styles.noDataWrapper}>
        <NoData message={t('errors.noActiveStock')} />
      </div>
    );
  }

  if (isLoadingDetails || !alertDetails) {
    return (
      <div className={styles.noDataWrapper}>
        <BluresSpinner />
      </div>
    );
  }

  return <AlertContent details={alertDetails} />;
};
