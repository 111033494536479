import * as React from 'react';
import { SVGProps } from 'react';

export const YouTubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M18.6625 5.58788C18.5585 5.20245 18.3557 4.85106 18.0743 4.56871C17.7929 4.28636 17.4426 4.0829 17.0584 3.97858C15.6519 3.59375 9.99096 3.59375 9.99096 3.59375C9.99096 3.59375 4.32978 3.6054 2.92323 3.99023C2.53902 4.09455 2.18876 4.29802 1.90732 4.58039C1.62588 4.86275 1.42309 5.21415 1.31914 5.5996C0.893687 8.10677 0.728648 11.9271 1.33082 14.334C1.43478 14.7194 1.63758 15.0708 1.91902 15.3532C2.20045 15.6355 2.55071 15.839 2.93491 15.9433C4.34146 16.3281 10.0025 16.3281 10.0025 16.3281C10.0025 16.3281 15.6635 16.3281 17.07 15.9433C17.4542 15.839 17.8044 15.6355 18.0859 15.3532C18.3673 15.0708 18.5701 14.7194 18.6741 14.334C19.1229 11.8233 19.2611 8.00527 18.6625 5.58788Z"
        fill="#FF0000"
      />
      <path d="M8.20312 12.6562L12.8906 9.96094L8.20312 7.26562V12.6562Z" fill="white" />
    </g>
  </svg>
);
