import { createAction, createSlice } from '@reduxjs/toolkit';
import { APP_ROUTS, TOKEN_KEY } from 'const';
import { RootState } from 'store/index';
import { IStateUser, IUiConfig, IUser } from 'types';

const initialState: { user: IStateUser; selectedTimeZone: string; uiConfig: IUiConfig | null } = {
  user: {
    _id: '',
    email: '',
    accessToken: '',
    selectedStock: { stock: '', competitiveStocks: [] },
    role: null,
    isTeamOwner: false,
    team: '',
    timeZone: 'Etc/UTC'
  },
  uiConfig: null,
  selectedTimeZone: 'Etc/UTC'
};

export const getUser = (state: RootState) => state.auth.user;
export const getSelectedTimeZone = (state: RootState) => state.auth.selectedTimeZone;
export const getUserStockSymbol = (state: RootState) => state.auth.user.selectedStock.stock;
export const getUserCompetitiveStocks = (state: RootState) => state.auth.user.selectedStock.competitiveStocks;
export const getUserStockKits = (state: RootState) => (state.auth.user.team ? state.auth.user.team.stockKits : []);

export const getUserAuthToken = (state: RootState) => state.auth.user.accessToken;
export const getUiConfig = (state: RootState) => state.auth.uiConfig;

export const authUser = createAction<IUser>('auth/authUser');
export const updateUser = createAction<Partial<IUser>>('auth/updateUser');
export const setUserCompetitiveStocks = createAction<{ stock: string; competitiveStocks: string[] }>(
  'auth/setCompetitiveStocks'
);
export const logout = createAction('auth/logout');
export const toggleAvailableStock = createAction<string>('auth/setStock');
export const setSelectedTimeZone = createAction<string>('auth/setTimeZone');
export const setUiConfig = createAction<IUiConfig>('auth/setUiConfig');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authUser, (store, { payload }) => {
        const { team } = payload;
        const selectedStockData: IStateUser['selectedStock'] = { stock: '', competitiveStocks: [], kitId: '' };

        if (team) {
          const { stockKits } = team;
          const mainStock = stockKits.find(({ isMain }) => !!isMain);

          if (mainStock) {
            selectedStockData.stock = mainStock.stock;
            selectedStockData.competitiveStocks = mainStock.competitiveStocks;
            selectedStockData.kitId = mainStock._id;
          }
        }

        store.user = {
          ...payload,
          selectedStock: selectedStockData
        };
      })
      .addCase(updateUser, (store, { payload }) => {
        if (payload.timeZone) {
          store.user.timeZone = payload.timeZone;
        }

        return store;
      })
      .addCase(logout, (store) => {
        localStorage.removeItem(TOKEN_KEY);
        store.user = initialState.user;
        window.location.replace(APP_ROUTS.LOGIN);
      })
      .addCase(setSelectedTimeZone, (store, { payload }) => {
        store.selectedTimeZone = payload;
      })
      .addCase(setUiConfig, (store, { payload }) => {
        store.uiConfig = payload;
      })
      .addCase(toggleAvailableStock, (store, { payload }) => {
        const { team } = store.user;

        if (team) {
          const { stockKits } = team;
          const mainStock = stockKits.find(({ stock }) => stock === payload);

          if (!mainStock) {
            store.user.selectedStock.competitiveStocks = [];
            store.user.selectedStock.stock = payload;
            store.user.selectedStock.kitId = '';
          } else {
            store.user.selectedStock.stock = mainStock.stock;
            store.user.selectedStock.competitiveStocks = mainStock.competitiveStocks;
            store.user.selectedStock.kitId = mainStock._id;
          }

          return store;
        }

        store.user.selectedStock.stock = payload;
      })
      .addCase(setUserCompetitiveStocks, (store, { payload }) => {
        store.user.selectedStock = payload;
        if (store.user.team) {
          store.user.team.stockKits = store.user.team.stockKits.map(({ stock, ...rest }) => {
            if (stock === payload.stock) {
              return {
                stock,
                ...rest,
                competitiveStocks: payload.competitiveStocks
              };
            }
            return {
              stock,
              ...rest
            };
          });
        }
      });
  }
});

export const authReducers = authSlice.reducer;
