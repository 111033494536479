import React, { SyntheticEvent } from 'react';

const useOutsideClick = (callback: any) => {
  const ref = React.useRef<HTMLButtonElement | undefined>();

  React.useEffect(() => {
    const handleClick: any = (event: SyntheticEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;
