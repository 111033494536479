import useOptionSubscriptions from 'hooks/useOptionSubscriptions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProfileLayout from '../../ProfileLayout';
import PlansList from '../../components/PlansList';

export const PlansPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const { subscriptions } = useOptionSubscriptions();

  return (
    <ProfileLayout title={t('pages.profile.plans')}>
      <PlansList plans={subscriptions} />
    </ProfileLayout>
  );
};
