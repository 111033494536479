import * as React from 'react';
import { SVGProps } from 'react';

export const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox={'0 0 21 20'} width={21} height={20} fill="none" {...props}>
      <path
        stroke={props.stroke || '#17B26A'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m15.333 12.5-5-5-5 5"
      />
    </svg>
  );
};
