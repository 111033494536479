import { Grid } from '@mui/material';
import { Layout } from 'components';
import BluresSpinner from 'components/BluresSpinner';
import { useAppDispatch, useAppSelector } from 'hooks';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import CompetitiveChart from 'modules/Charts/Competitive';
import StockFilterHeader from 'modules/StockFilterHeader';
import OutOfStock from 'pages/OutOfStock';
import React, { useEffect } from 'react';
import { getUser, getUserStockSymbol } from 'store/slices/authSlice';
import { setCompetitiveActiveStock } from 'store/slices/filtersSlice';

import ConfigurePopup from './ConfigurePopup';
import CompetitiveAnalysisFilters from './Filters';
import OutDataPopup from './OutDataPopup';
import CompetitiveMentions from './components/Mentions';
import CompetitiveSentiments from './components/Sentiments';
import CompetitiveTopics from './components/Topics';
import styles from './styles.module.scss';

const CompetitiveAnalysisPage = () => {
  const user = useAppSelector(getUser);
  const selectedStock = useAppSelector(getUserStockSymbol);
  const { competitiveStocks: competitiveStocksInfo } = useCompetitiveStocks();
  const { team, isTeamOwner, _id } = user;

  const dispatch = useAppDispatch();
  const stockKits = team ? team.stockKits : [];
  const selectedStockKit = stockKits.find(({ stock }) => stock === selectedStock);

  useEffect(() => {
    if (!team) {
      return;
    }

    dispatch(setCompetitiveActiveStock(selectedStockKit?.competitiveStocks || []));
  }, [dispatch, selectedStockKit, team]);

  if (!_id || (selectedStockKit && selectedStockKit.competitiveStocks.length && !competitiveStocksInfo.length)) {
    return <BluresSpinner />;
  }

  if (!team || (team && selectedStockKit && !selectedStockKit.competitiveStocks.length && !isTeamOwner)) {
    return <OutOfStock Popup={<OutDataPopup />} />;
  }

  if (selectedStockKit && !selectedStockKit.competitiveStocks.length && isTeamOwner) {
    return <OutOfStock Popup={<ConfigurePopup />} />;
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.stickyFilters}>
          <div className={styles.companyFilters}>
            <StockFilterHeader />
          </div>
          <div className={styles.competitiveFilters}>
            <CompetitiveAnalysisFilters />
          </div>
        </div>
        <CompetitiveChart />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CompetitiveSentiments />
          </Grid>
          <Grid item xs={12} md={6}>
            <CompetitiveMentions />
          </Grid>
        </Grid>
        <CompetitiveTopics />
      </div>
    </Layout>
  );
};

export default CompetitiveAnalysisPage;
