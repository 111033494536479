import classNames from 'classnames';
import React from 'react';

import RangePicker from '../../../../components/RangePicker';
import { CalendarIcon } from '../../../../components/SvgComponents';
import { useAppSelector } from '../../../../hooks';
import { getFiltersDataRangeLoadingState, getRangeOpenState } from '../../../../store/slices/filtersSlice';
import { DatePickerFilterConfig, FiltersDateRangeType } from '../../../../types';
import styles from './styles.module.scss';

type DateFilterItemProps = {
  filterConfig: DatePickerFilterConfig;
  minDate: Date;
  initialRange: Date[];
};

const DateFilterItem: React.FC<DateFilterItemProps> = ({
  filterConfig: { label, type: filterType, isActive, onClose, onClick },
  minDate,
  initialRange
}) => {
  const loading = useAppSelector(getFiltersDataRangeLoadingState);
  const isOpen = useAppSelector(getRangeOpenState);

  return (
    <div className={styles.container}>
      <button
        onClick={onClick}
        className={classNames(styles.wrapper, { [styles.active]: isActive })}
        disabled={loading}
      >
        {filterType === FiltersDateRangeType.range ? <CalendarIcon /> : label}
      </button>
      {filterType === FiltersDateRangeType.range && isOpen && (
        <div className={styles.picker}>
          <RangePicker minDate={minDate} isOpen={isOpen} onBtnClose={onClose} initialRange={initialRange} />
        </div>
      )}
    </div>
  );
};

export default DateFilterItem;
