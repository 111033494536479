import React, { useMemo } from 'react';

import styles from './styles.module.scss';

interface IParsedTextWithLinkProps {
  text: string;
  linkText?: string;
}

const urlPattern = /https?:\/\/\S+|www\.\S+/g;

const ParsedTextWithLink: React.FC<IParsedTextWithLinkProps> = ({ text, linkText }) => {
  const urls = text.match(urlPattern);

  const parsedLink = useMemo(() => {
    if (!urls) {
      return text;
    }
    const parts = text.split(urlPattern).filter(Boolean);
    const linkifiedText = parts.map((part, index) => {
      if (urls[index]) {
        let url = urls[index];
        if (!url.startsWith('http')) {
          url = 'http://' + url;
        }
        return (
          <span key={index}>
            {part}
            <a href={url} target="_blank" rel="noopener noreferrer" className={styles.outerLink}>
              {linkText || url}
            </a>
          </span>
        );
      }
      return part;
    });

    return linkifiedText;
  }, [linkText, text, urls]);

  return <>{parsedLink}</>;
};

export default ParsedTextWithLink;
