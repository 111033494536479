import { CustomSelect, Layout } from 'components';
import { SectionCard } from 'components';
import BluresSpinner from 'components/BluresSpinner';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SourceFilters } from 'modules';
import Heading from 'modules/Charts/Heading';
import StockFilterHeader from 'modules/StockFilterHeader';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';
import { resetDatasourceFilters, updateRequestedListPage } from 'store/slices/filtersSlice';
import { getActiveItem, setSort } from 'store/slices/responderSlice';

import ReplyPopup from './ReplyPopup';
import ResponderList from './components/ResponderList';
import ResponderTabs from './components/Tabs';
import useDataSource from './hooks/useDataSource';
import styles from './styles.module.scss';

interface FormValues {
  sort: 'impression' | 'dateCreated';
}

const ResponderPage = () => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { _id } = user;
  const activeItem = useAppSelector(getActiveItem);
  const showPopup = !!activeItem;
  const { dataSourceConfig } = useDataSource();
  const dispatch = useAppDispatch();
  const { register, watch, control } = useForm<FormValues>({ defaultValues: { sort: 'impression' } });
  const sortValue = watch('sort');

  useEffect(() => {
    dispatch(setSort(sortValue));
    dispatch(updateRequestedListPage({ storeKeyName: 'responderFilters', count: 1 }));
  }, [sortValue]);

  useEffect(() => {
    return () => {
      dispatch(resetDatasourceFilters());
    };
  }, [dispatch]);

  if (!_id) {
    return <BluresSpinner />;
  }

  return (
    <>
      <Layout blurContent={showPopup}>
        <div className={styles.wrapper}>
          <StockFilterHeader />
          <SectionCard noBorder>
            <>
              <div className={styles.headingWrapper}>
                <Heading title={t('pages.responder.title')} />
                <CustomSelect
                  name="sort"
                  register={register}
                  control={control}
                  defaultValue={sortValue}
                  data={[
                    { _id: 'impression', name: 'Sort By Impressions' },
                    { _id: 'datePosted', name: 'Sort By Date' }
                  ]}
                />
                <SourceFilters data={dataSourceConfig} isLoading={false} />
              </div>
              <ResponderTabs />
              <ResponderList />
            </>
          </SectionCard>
        </div>
      </Layout>
      <ReplyPopup show={showPopup} />
    </>
  );
};

export default ResponderPage;
