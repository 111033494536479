import React from 'react';

import BlurredSpinner from '../../../components/BluresSpinner';
import { TabConfig } from '../../../types';
import TabFilterItem from './TabFilterItem';
import styles from './styles.module.scss';

type TabFiltersProps = {
  data: TabConfig[];
  loading?: boolean;
};
export const TabFilters: React.FC<TabFiltersProps> = ({ data, loading }) => {
  return (
    <div className={styles.wrapper}>
      {data.map((item, index) => {
        return loading ? (
          <BlurredSpinner fillContent={true} size={24} key={item.name} />
        ) : (
          <TabFilterItem
            Icon={item.Icon}
            key={item.name}
            label={item.name}
            count={item.count}
            onClick={item.toggleFn}
            isActive={item.isActive}
          />
        );
      })}
    </div>
  );
};
