import * as React from 'react';
import { SVGProps } from 'react';

import { theme } from '../../../theme';

export const NegativeCampaning = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={props.stroke || theme.palette.error.main}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22 8v4M10.25 5.5H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.31C2 7.78 2 8.62 2 10.3v1.2c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083c.367.152.833.152 1.765.152v4.25c0 .232 0 .348.01.446a2 2 0 0 0 1.794 1.794c.098.01.214.01.446.01s.348 0 .446-.01a2 2 0 0 0 1.794-1.794c.01-.098.01-.214.01-.446V14.5h.75c1.766 0 3.927.947 5.594 1.856.973.53 1.46.795 1.778.756a.946.946 0 0 0 .691-.411c.187-.26.187-.783.187-1.827V5.126c0-1.044 0-1.566-.187-1.827a.946.946 0 0 0-.691-.411c-.319-.039-.805.226-1.778.756-1.667.909-3.828 1.856-5.594 1.856Z"
    />
    <path
      fill="#101828"
      d="m12.195 20.05 4.113-7.104c.227-.393.34-.589.489-.655a.5.5 0 0 1 .406 0c.148.066.262.262.49.655l4.112 7.103c.228.394.342.591.325.753a.5.5 0 0 1-.203.352c-.132.096-.36.096-.815.096h-8.225c-.455 0-.683 0-.814-.096a.5.5 0 0 1-.203-.352c-.017-.162.097-.359.325-.753Z"
    />
    <path
      stroke={props.stroke || theme.palette.error.main}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M17 15.5v2m0 2h.005m-.697-6.554-4.113 7.103c-.228.394-.342.591-.325.753a.5.5 0 0 0 .203.352c.131.096.36.096.814.096h8.225c.456 0 .683 0 .815-.096a.5.5 0 0 0 .203-.352c.017-.162-.097-.359-.325-.753l-4.113-7.103c-.227-.393-.34-.589-.489-.655a.5.5 0 0 0-.406 0c-.149.066-.262.262-.49.655Z"
    />
  </svg>
);
