import { Skeleton } from '@mui/material';
import React, { useCallback } from 'react';

import { SectionCard, Title, TitleType } from '../../../components';
import DefaultContent from '../../../components/SentimentalsBlocks/DefaultContent';
import SentimentalContent from '../../../components/SentimentalsBlocks/SentimentalContent';
import { SummaryAnalyze, SummarySentimentAnalyze, SummaryType } from '../types';
import styles from './styles.module.scss';

type CardProps = {
  data: SummaryAnalyze | SummarySentimentAnalyze | null;
  title: string;
  type: SummaryType;
  Icon: React.FC;
  loading: boolean;
  cardKey?: string;
};

const Card: React.FC<CardProps> = ({ Icon, loading, data, type, title, cardKey }) => {
  const Content = useCallback(() => {
    return type === SummaryType.simple ? (
      <DefaultContent data={data as SummaryAnalyze} loading={loading} cardKey={cardKey} />
    ) : (
      <SentimentalContent data={data as SummarySentimentAnalyze} loading={loading} />
    );
  }, [data, loading, type]);
  return (
    <div className={styles.wrapper}>
      <SectionCard nativeClassName={styles.card}>
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.titleIcon}>
              <Icon />
            </div>
            <Title type={TitleType.h3}>{loading ? <Skeleton width="60%" /> : <>{title}</>}</Title>
          </div>
          <Content />
        </>
      </SectionCard>
    </div>
  );
};

export default Card;
