import { Tooltip } from '@mui/material';
import React from 'react';

import { convertNumbersToK } from '../../utils';

export const ValueWithTooltip: React.FC<{ value: number }> = ({ value }) => {
  const needConvert = value >= 1000;
  const Tag: any = needConvert ? Tooltip : 'span';
  const props = needConvert ? { title: value, placement: 'top' } : {};
  const renderValue = needConvert ? convertNumbersToK(value) : value;
  return (
    <Tag {...props}>
      <span>{renderValue}</span>
    </Tag>
  );
};
