import { Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import { Title, TitleType } from 'components';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from 'utils';

import { IStockMention } from '../index';
import styles from './styles.module.scss';

interface IMentionsListProps {
  data?: IStockMention[];
  loading: boolean;
}

const MentionsList: React.FC<IMentionsListProps> = ({ data, loading }) => {
  const { competitiveStocks, mainStock } = useCompetitiveStocks();
  const { t } = useTranslation();

  if (!data || loading || !competitiveStocks) {
    return (
      <div className={classNames(styles.listWrapper, styles.noMargin)}>
        {Array.from(Array(4)).map((item, index) => {
          return (
            <div className={styles.skeletonWrapper} key={index}>
              <Skeleton variant="rounded" height={46} width={46} />
              <Skeleton variant="text" height={46} width="100%" />
            </div>
          );
        })}
      </div>
    );
  }

  const sortedData = [...data].sort(function (x, y) {
    return x.stock == mainStock.symbol ? -1 : y.stock == mainStock.symbol ? 1 : 0;
  });

  return (
    <div className={styles.listWrapper}>
      {sortedData.map(({ stock, count }) => {
        const stockData = [...competitiveStocks, mainStock].find(({ symbol }) => symbol === stock);

        if (!stockData) {
          return null;
        }

        const isMainStock = stock === mainStock.symbol;

        const { image, symbol } = stockData;

        return (
          <div className={classNames(styles.wrapper, { [styles.main]: isMainStock })} key={stock}>
            <div className={styles.imageWrapper}>
              <img width={24} height={24} src={image} alt="" />
            </div>
            <div className={styles.titleWrapper}>
              <Title type={TitleType.h2}>{symbol}</Title>
              {isMainStock && (
                <Typography className={styles.mainStockText} variant="body2">
                  {t('pages.competitiveAnalysis.yourStock')}
                </Typography>
              )}
            </div>
            <Typography className={styles.count}>{numberWithCommas(count)}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default MentionsList;
