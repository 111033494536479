import { Avatar } from '@mui/material';
import { CrudTable } from 'components';
import { API_ROUTES } from 'const';
import React from 'react';
import { IStock, ITableColumns } from 'types';

const rows: ITableColumns<IStock>[] = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: 30,
    render: (item, value) => {
      return <Avatar src={value} sx={{ width: 24, height: 24 }} alt={item.name} />;
    }
  },
  {
    title: 'Company Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Total Mentions',
    dataIndex: 'mentions',
    key: 'mentions'
  },
  {
    title: 'Positive Sentiment',
    dataIndex: 'positiveSentiment',
    key: 'positiveSentiment'
  },
  {
    title: 'Negative Sentiment',
    dataIndex: 'negativeSentiment',
    key: 'negativeSentiment'
  },
  {
    title: 'Latest Pr',
    dataIndex: 'lastPrLink',
    key: 'lastPrLink',
    render: (item, value) => {
      if (!value) {
        return 'No';
      }

      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          Pr
        </a>
      );
    }
  }
];

const StocksTable = () => {
  return <CrudTable<IStock> columns={rows} requestUrl={API_ROUTES.AGENCY_OVERVIEW} />;
};

export default StocksTable;
