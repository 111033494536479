import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import WelcomePopupImg from '../../../resources/images/welcome-popup-img.jpg';
import styles from './styles.module.scss';

const PagePopup = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Typography variant="body1">{t('pages.withOutStock.welcomeText')}</Typography>
      <Typography className={styles.title} variant="h4">
        {t('pages.withOutStock.title')}
      </Typography>
      <div className={styles.imageWrapper}>
        <img src={WelcomePopupImg} alt="" />
      </div>
      <Typography variant="body1">{t('pages.withOutStock.subTitle')}</Typography>
    </div>
  );
};

export default PagePopup;
