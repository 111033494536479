import * as React from 'react';
import { SVGProps } from 'react';

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 16 16'} width={16} height={16} fill="none" {...props}>
    <path
      stroke="#EAECF0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.667 11.333 14 8m0 0-3.333-3.333M14 8H6m0-6h-.8c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 3.52 2 4.08 2 5.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 14 4.08 14 5.2 14H6"
    />
  </svg>
);
