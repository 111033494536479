import * as process from 'process';

export const TOKEN_KEY = 'authToken';
export const ERROR_API_RESPONSE = {
  BAD_REQUEST: 400,
  NOT_AUTHORIZED: 401,
  SERVER_ERROR: 500,
  FORBIDDEN: 403
};

export const APP_ROUTS = {
  HOME: '/',
  AGENCY_OVERVIEW: '/agency-overview',
  LOGIN: '/sign-in',
  COMPETITIVE_ANALYSIS: '/competitive-analysis',
  RESPONDER: '/responder',
  RECOMMENDED_ACTIONS: '/recommended-actions',
  REPORTS: '/reports',
  FORGOT_PASS: '/forgot-password',
  RESET_PASS: '/reset-password',
  SVG_STORY_BOOK: '/svg-story-book',
  OUT_OF_STOCK: '/out-of-stock',
  ADMIN_DASHBOARD: '/admin',
  TEAMS: '/admin/teams',
  TEAMS_DETAILS: '/admin/teams',
  TEAMS_CREATE: '/admin/teams/create',
  TEAMS_EDIT: '/admin/teams/edit',
  STOCKS: '/admin/stocks',
  STOCKS_CREATE: '/admin/stocks/create',
  STOCKS_EDIT: '/admin/stocks/edit',
  USERS: '/admin/users',
  USERS_EDIT: '/admin/users/edit',
  USERS_CREATE: '/admin/users/create',
  PROFILE: '/profile',
  PLANS: '/profile/plans',
  BLACKLIST_USERS: '/admin/blacklist-users',
  BLACKLIST_USERS_CREATE: '/admin/blacklist-users/create',
  BLACKLIST_USERS_EDIT: '/admin/blacklist-users/edit',
  STOCK_KITS: '/admin/stock-kits',
  STOCK_KITS_CREATE: '/admin/stock-kits/create',
  STOCK_KITS_EDIT: '/admin/stock-kits/edit',
  ANALYST_ALERT: '/admin/analyst-alerts',
  ANALYST_ALERT_CREATE: '/admin/analyst-alerts/create'
};

export const API_ROUTES = {
  AGENCY_OVERVIEW: 'stocks/agency-overview',
  LOGIN: 'auth/sign-in',
  FORGOT_PASS: 'auth/forgot-password',
  AUTH_RESET_PASSWORD: 'auth/reset-password',
  USER: 'auth/user',
  USERS: 'users',
  UPDATE_USER: 'auth/update-user',
  CHANGE_PASSWORD: 'auth/change-password',
  EXECUTIVE_SUMMARY: 'stocks/executive-summary',
  CHART_TO_MARKET: 'stocks/chat-market-trend',
  PR_LINKS: 'pr-links',
  COMPETITIVE_CHART: 'competitive-analysis/stocks-competitive',
  TOP_POST: 'conversations/top-posts',
  TOP_USERS: 'conversations/top-users',
  DATA_SOURCE: 'conversations/source-counters',
  TOP_POST_COUNT: 'conversations/top-posts-counters',
  SENTIMENT_GRAPH: 'stocks/sentiments-graph',
  SENTIMENT_GRAPH_SNAPSHOT: 'stocks/sentiments-snapshot',
  STOCKS: 'stocks',
  STOCKS_UPDATE_LOGO: 'stocks/update-logo',
  ALERTS: 'alerts',
  REPORTS: 'reports',
  REPORT_CONFIGS: 'report-configs',
  DOWNLOAD_REPORT: 'reports/download',
  ALERTS_READ: 'alerts/:stockSymbol/read',
  ALERTS_ARCHIVE: 'alerts/:stockSymbol/archive',
  ALERTS_ARCHIVE_ALL: 'alerts/:stockSymbol/archive-all',
  ALERTS_UNARCHIVE: 'alerts/:stockSymbol/unarchive',
  ALERTS_UNARCHIVE_ALL: 'alerts/:stockSymbol/unarchive-all',
  TEAMS: 'teams',
  SELECT_OPTION_STOCKS: 'select-options/stocks',
  SELECT_OPTION_STOCK_KITS: 'select-options/stock-kits',
  SELECT_OPTION_USERS: 'select-options/users',
  SELECT_OPTION_TEAMS: 'select-options/teams',
  SUBSCRIPTION_PLANS: 'subscription-plans',
  STOCKS_SUGGESTIONS: 'stocks/search-suggestions',
  SET_COMPETITIVE_STOCKS: 'competitive-analysis/set-competitive-stocks',
  STOCKS_COMPETITIVE_SHORT_INFO: 'stocks/info/companies',
  COMPETITIVE_MENTIONS: 'competitive-analysis/mentions',
  COMPETITIVE_SENTIMENTS: 'competitive-analysis/sentiments',
  STOCKS_WEEKLY_TOPICS: 'topics/weekly',
  STOCK_TRENDING_TOPICS: 'topics/trending',
  CONVERSATION_USERS: 'conversation-users',
  CONVERSATION_USERS_BLACKLIST: 'conversation-users/blacklist',
  RESPONDER_LIST: 'responder/posts',
  RESPONDER_REPLY: 'responder/reply',
  RESPONDER_MARK: 'responder/mark-responder',
  RESPONDER_DECLINE: 'responder/decline-reply',
  STOCK_KITS: 'stock-kits',
  UI_CONFIG: 'ui-configs/user-config',
  CONVERSATION_SUMMARIES: 'conversation-summaries',
  RESPONDER_ACCOUNTS: 'responder-account',
  RESPONDER_DISCLAIMERS: 'responder-disclaimer',
  REDDIT_SOURCE_CONFIG: 'reddit-source-config',
  INFLUENCER_CONFIG: 'influencer-config',
  CREATE_ANALYST_ALERT: 'alerts/analyst-alerts'
};

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export const SPASE_GUTTER = 10;
export const IS_DEV = process.env.REACT_APP_ENVIRONMENT === 'local';
