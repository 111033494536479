import { Grid } from '@mui/material';
import Heading from 'modules/Charts/Heading';
import SentimentGraphs from 'modules/Charts/SantimentedGraphs';
import SnapshotGraphs from 'modules/Charts/SnapshotGraphs';
import { useTranslation } from 'react-i18next';

const SentimentGraphsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading title={t('charts.sentimentGraphs')} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <SentimentGraphs />
        </Grid>
        <Grid item xs={12} md={4}>
          <SnapshotGraphs />
        </Grid>
      </Grid>
    </>
  );
};

export default SentimentGraphsSection;
