import React from 'react';

import { Spinner } from '../../../components';
import styles from './styles.module.scss';

const ChartSpinner: React.FC<{ size?: number }> = ({ size = 72 }) => {
  return (
    <div className={styles.loader}>
      <Spinner size={size} />
    </div>
  );
};

export default ChartSpinner;
