import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APP_ROUTS } from '../../../../../const';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { getUser, logout } from '../../../../../store/slices/authSlice';
import { theme } from '../../../../../theme';
import { ITeamRole } from '../../../../../types';
import { LogoutIcon, UserIcon } from '../../../../SvgComponents';
import { stringAvatar } from '../../../utils';
import styles from './styles.module.scss';

const Modal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const grayThemeColor = theme.palette.grey;
  const textItemStyle = { color: grayThemeColor[100], margin: 0 };
  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <div
      className={classNames(styles.wrapper, { [styles.active]: isOpen })}
      style={{ borderColor: grayThemeColor[500], background: grayThemeColor[800] }}
    >
      <List sx={{ p: 0 }}>
        <ListItem style={{ padding: 0, borderBottom: `1px solid ${grayThemeColor[500]}` }}>
          <ListItemButton onClick={onClose} sx={{ padding: '10px 12px' }}>
            <ListItemAvatar>
              <Avatar alt="KOIOS" {...stringAvatar(user.email)} />
            </ListItemAvatar>
            <Box>{user?.email && <ListItemText style={textItemStyle}>{user.email}</ListItemText>}</Box>
          </ListItemButton>
        </ListItem>
        <ListItem style={{ padding: 0, borderBottom: `1px solid ${grayThemeColor[500]}` }}>
          <ListItemButton component={Link} to={APP_ROUTS.PROFILE} disableGutters sx={{ padding: '10px 12px' }}>
            <ListItemAvatar>
              <Box sx={{ width: 40, display: 'flex', justifyContent: 'center' }}>
                <UserIcon />
              </Box>
            </ListItemAvatar>
            <Box>
              <ListItemText style={textItemStyle}>{t('userMenu.viewProfile')}</ListItemText>
            </Box>
          </ListItemButton>
        </ListItem>
        {user.role === ITeamRole.admin && (
          <ListItem style={{ padding: 0, borderBottom: `1px solid ${grayThemeColor[500]}` }}>
            <ListItemButton component={Link} to={APP_ROUTS.STOCKS} disableGutters sx={{ padding: '10px 12px' }}>
              <ListItemAvatar>
                <Box sx={{ width: 40, display: 'flex', justifyContent: 'center' }}>
                  <SettingsIcon style={{ color: '#EAECF0' }} />
                </Box>
              </ListItemAvatar>
              <Box>
                <ListItemText style={textItemStyle}>{t('userMenu.dashboard')}</ListItemText>
              </Box>
            </ListItemButton>
          </ListItem>
        )}
        <ListItem style={{ padding: 0 }}>
          <ListItemButton onClick={onLogout} disableGutters sx={{ padding: '10px 12px' }}>
            <ListItemAvatar>
              <Box sx={{ width: 40, display: 'flex', justifyContent: 'center' }}>
                <LogoutIcon />
              </Box>
            </ListItemAvatar>
            <Box>
              <ListItemText style={textItemStyle}>{t('userMenu.logout')}</ListItemText>
            </Box>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default Modal;
