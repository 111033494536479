import classNames from 'classnames';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';

import styles from './styles.module.scss';

const NavItem: React.FC<{ to: string; title: string; Icon?: React.FC }> = ({ to, title, Icon }) => {
  const isMatch = useMatch(to);
  return (
    <Link className={classNames(styles.wrapper, { [styles.active]: isMatch })} to={to}>
      {!!Icon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
      {title}
    </Link>
  );
};

export default NavItem;
