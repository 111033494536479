import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { fetchWithConfig } from '../utils';

const useOptionUsers = (filters?: { filter?: string; teamId?: string }, canFetch: boolean = true) => {
  const { data, error, isLoading } = useSWR<any>(
    canFetch && [API_ROUTES.SELECT_OPTION_USERS, filters ? { ...filters } : ''],
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  return {
    usersOptionData: data as { name: string; _id: string }[],
    usersOptionError: error,
    usersOptionLoading: isLoading
  };
};

export default useOptionUsers;
