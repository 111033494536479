import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

interface ICheckboxComponentProps {
  register: any;
  control: any;
  defaultValue: boolean;
  label: string;
  name: string;
}

export const CheckboxComponent: React.FC<ICheckboxComponentProps> = ({
  register,
  defaultValue,
  label,
  name,
  control
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field }) => {
        return <FormControlLabel control={<Checkbox {...register(name)} />} checked={field.value} label={label} />;
      }}
    />
  );
};

export default CheckboxComponent;
