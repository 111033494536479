import * as React from 'react';
import { SVGProps } from 'react';

export const ActionMakePrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.5" clipPath="url(#clip0_1894_1657)">
      <g clipPath="url(#clip1_1894_1657)">
        <path
          d="M20.125 28.0354C19.7875 28.0354 19.45 27.9904 19.1125 27.8779L5.6125 23.6704C4.195 23.2204 3.25 21.9379 3.25 20.4529V12.5104C3.25 11.0254 4.195 9.74292 5.6125 9.29292L19.1125 5.08542C20.1475 4.77042 21.25 4.95042 22.1275 5.58042C23.005 6.23292 23.5 7.22292 23.5 8.30292V24.6829C23.5024 25.1249 23.4174 25.563 23.2501 25.9721C23.0827 26.3811 22.8362 26.7531 22.5247 27.0667C22.2132 27.3803 21.8429 27.6293 21.435 27.7994C21.027 27.9695 20.5895 28.0573 20.1475 28.0579L20.125 28.0354ZM20.125 7.15542C20.0125 7.15542 19.9 7.15542 19.7875 7.20042L6.2875 11.4304C5.815 11.5879 5.5 12.0154 5.5 12.5104V20.4529C5.5 20.9479 5.815 21.3754 6.2875 21.5329L19.7875 25.7404C20.125 25.8529 20.5075 25.7854 20.8 25.5829C21.0925 25.3579 21.25 25.0429 21.25 24.6829V8.28042C21.2537 8.10514 21.2146 7.93161 21.1362 7.77481C21.0578 7.618 20.9424 7.48264 20.8 7.38042C20.6092 7.24321 20.3823 7.16499 20.1475 7.15542H20.125ZM31.375 18.0004H26.875C26.245 18.0004 25.75 17.5054 25.75 16.8754C25.75 16.2454 26.245 15.7504 26.875 15.7504H31.375C32.005 15.7504 32.5 16.2454 32.5 16.8754C32.5 17.5054 32.005 18.0004 31.375 18.0004ZM25.75 14.6254C25.6025 14.6272 25.4562 14.5981 25.3205 14.54C25.1849 14.4819 25.063 14.396 24.9625 14.2879C24.5125 13.8379 24.5125 13.1404 24.9625 12.6904L28.3375 9.31542C28.7875 8.86542 29.485 8.86542 29.935 9.31542C30.385 9.76542 30.385 10.4629 29.935 10.9129L26.56 14.2879C26.335 14.5129 26.0425 14.6254 25.7725 14.6254H25.75ZM29.125 24.7504C28.9775 24.7522 28.8312 24.7231 28.6955 24.665C28.5599 24.6069 28.438 24.521 28.3375 24.4129L24.9625 21.0379C24.5125 20.5879 24.5125 19.8904 24.9625 19.4404C25.4125 18.9904 26.11 18.9904 26.56 19.4404L29.935 22.8154C30.385 23.2654 30.385 23.9629 29.935 24.4129C29.71 24.6379 29.4175 24.7504 29.1475 24.7504H29.125Z"
          fill="black"
        />
        <path
          d="M10 31.0962C7.525 31.0962 5.5 29.0712 5.5 26.5962V22.9512H7.75V26.5962C7.75 27.8337 8.7625 28.8462 10 28.8462C11.2375 28.8462 12.25 27.8337 12.25 26.5962V25.6962H14.5V26.5962C14.5 29.0712 12.475 31.0962 10 31.0962Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1894_1657">
        <rect width="36" height="36" fill="white" />
      </clipPath>
      <clipPath id="clip1_1894_1657">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
