import { Avatar } from '@mui/material';
import React, { SyntheticEvent } from 'react';

import { useAppSelector } from '../../../../hooks';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { getUser } from '../../../../store/slices/authSlice';
import { theme } from '../../../../theme';
import { stringAvatar } from '../../utils';
import Modal from './Modal';
import styles from './styles.module.scss';

const UserMenu = () => {
  const [open, setOpen] = React.useState(false);

  const user = useAppSelector(getUser);

  const toggleOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };
  const ref = useOutsideClick(onClose);

  return (
    <div className={styles.wrapper}>
      <button
        /*@ts-ignore*/
        ref={ref}
        onClick={toggleOpen}
        className={styles.btn}
        style={{ borderColor: open ? theme.palette.grey[500] : 'transparent' }}
      >
        <Avatar alt="KOIOS" {...stringAvatar(user.email)} />
      </button>
      <Modal isOpen={open} onClose={onClose} />
    </div>
  );
};
export default UserMenu;
