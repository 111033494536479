import React from 'react';
import { Datasource, IRecommendedItem, RecommendedActionTypes } from 'types';

import RecommendedItem from './Item';

const list: IRecommendedItem[] = [
  {
    type: RecommendedActionTypes.BAD_ACCOUNT,
    text: 'We’d like to offer balances to everyone. But there are a few countries and US states where you can’t open any balances just yet.',
    dataSource: Datasource.Twitter
  },
  {
    type: RecommendedActionTypes.COLLECT_DOCUMENTATION,
    text: 'We’ve noticed suspicious activity that may require legal action. These can be used as evidence for future legal action We’ve collected the info for him since manipulators usually erase all their activity once the attack is completed.'
  },
  {
    type: RecommendedActionTypes.MAKE_PR,
    text: 'We’ve noticed suspicious activity that may require legal action. These can be used as evidence for future legal action We’ve collected the info for him since manipulators usually erase all their activity once the attack is completed.'
  }
];

const RecommendedList = () => {
  return (
    <div>
      {list.map((item, index) => {
        return <RecommendedItem key={index} data={item} />;
      })}
    </div>
  );
};

export default RecommendedList;
