import { Box, Grid } from '@mui/material';
import { GridSpacing } from '@mui/material/Grid/Grid';
import { ResponsiveStyleValue } from '@mui/system';
import React from 'react';
import { theme } from 'theme';

export const MuiBoxContainer: React.FC<{
  children: React.ReactElement;
  columns?: ResponsiveStyleValue<number>;
  spacing?: ResponsiveStyleValue<GridSpacing>;
  nativeClassNames?: string;
}> = ({ children, spacing = { xs: 1 }, columns, nativeClassNames }) => {
  return (
    <Box
      sx={{ flexGrow: 1, width: '100%', padding: theme.spacing(1) }}
      {...(Boolean(nativeClassNames) && { className: nativeClassNames })}
    >
      <Grid container spacing={spacing} columns={columns}>
        {children}
      </Grid>
    </Box>
  );
};

export default MuiBoxContainer;
