import { SectionCard, SectionCardHeading } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUserStockSymbol } from 'store/slices/authSlice';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import TopicsList from './List';

export interface ITopic {
  negativeTopics: string[];
  positiveTopics: string[];
}

export const StockNegativePositiveTopics = () => {
  const { t } = useTranslation();
  const stockSymbol = useAppSelector(getUserStockSymbol);

  const { data, error, isLoading } = useSWR<ITopic>(`${API_ROUTES.STOCK_TRENDING_TOPICS}/${stockSymbol}`, (url) =>
    fetchWithConfig({ url })
  );

  return (
    <SectionCard noBorder>
      <>
        <SectionCardHeading title={t('topics.chatterHeading')} subtitle={t('topics.mainSubtitle')} />
        <TopicsList data={data} loading={isLoading} />
      </>
    </SectionCard>
  );
};
