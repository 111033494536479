import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from 'utils';

import { ChangeStatusType, SummaryAnalyze } from '../../../modules/ExecutiveSummary/types';
import { ChevronDownIcon, ChevronUpIcon } from '../../SvgComponents';
import styles from '../styles.module.scss';

export type DefaultContentProps = {
  data: SummaryAnalyze;
  loading: boolean;
  cardKey?: string;
};
const DefaultContent: React.FC<DefaultContentProps> = ({ data, loading, cardKey }) => {
  const {
    value: totalValue,
    change: { value: changeValue, status: changeStatus }
  } = data;
  const positive = changeStatus === ChangeStatusType.up;
  const negative = changeStatus === ChangeStatusType.down;
  const valueToNumber = Number(changeValue.replace(/%/gi, ''));
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.title}>{loading ? <Skeleton width="60%" /> : numberWithCommas(data?.value)}</div>
      <div className={styles.row}>
        <>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <Skeleton height={20} width="70%" />
              <Skeleton height={20} width="70%" />
            </Box>
          ) : (
            <>
              {positive && valueToNumber > 0 && <ChevronUpIcon {...(cardKey === 'bots' && { stroke: '#F04438' })} />}
              {negative && valueToNumber < 0 && <ChevronDownIcon {...(cardKey === 'bots' && { stroke: '#17B26A' })} />}
              {valueToNumber === 0 && <RemoveIcon sx={{ color: grey[500] }} />}
              <span
                className={classNames(
                  styles.statisticValue,
                  {
                    [styles.red]:
                      (valueToNumber > 0 && positive && cardKey === 'bots') ||
                      (cardKey !== 'bots' && negative && valueToNumber < 0)
                  },
                  {
                    [styles.green]:
                      (cardKey !== 'bots' && positive && valueToNumber > 0) ||
                      (cardKey === 'bots' && negative && valueToNumber < 0)
                  }
                )}
              >
                {changeValue}
              </span>
              <Typography className={styles.statisticVsValue} variant="body2">
                {t('executiveSummary.vsYestard')}
              </Typography>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default DefaultContent;
