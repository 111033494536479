import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCard, SectionCardHeading } from '../../components';
import SummaryList from './List';

export const ExecutiveSummary = () => {
  const { t } = useTranslation();
  return (
    <SectionCard noBorder>
      <>
        <SectionCardHeading title={t('executiveSummary.mainTitle')} subtitle={t('executiveSummary.mainSubtitle')} />
        <SummaryList />
      </>
    </SectionCard>
  );
};
