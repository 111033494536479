import * as React from 'react';
import { SVGProps } from 'react';

export const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 16 16'} width={16} height={16} fill="none" {...props}>
    <path
      stroke="#EAECF0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.333 14c0-.93 0-1.396-.114-1.774a2.667 2.667 0 0 0-1.778-1.778c-.379-.115-.844-.115-1.774-.115H6.333c-.93 0-1.395 0-1.774.115a2.666 2.666 0 0 0-1.778 1.778c-.114.378-.114.844-.114 1.774M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);
