import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { InfiniteScroll } from '../../../../components';
import { API_ROUTES } from '../../../../const';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getUserStockSymbol } from '../../../../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  getTopPostTabsState,
  getTopUsersActiveId,
  updateCountFn,
  updateRequestedListPage
} from '../../../../store/slices/filtersSlice';
import { ITopPostResponse } from '../../types';
import { preloaderTopPostData } from '../../utils';
import PostItem from './PostItem';
import styles from './styles.module.scss';

const PostsList: React.FC<{ requestTrigger: boolean }> = ({ requestTrigger }) => {
  const from = useAppSelector(getFiltersDateRangeFrom);
  const to = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const { page, value } = useAppSelector(getTopPostTabsState);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const activeTopUserId = useAppSelector(getTopUsersActiveId);
  const listRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const updatePage = (page?: number) => {
    dispatch(updateRequestedListPage({ storeKeyName: 'postFilters', count: page }));
  };

  const queryOptions = useMemo(() => {
    return {
      from,
      to,
      dataSource,
      sentiment: value,
      withBotsAndRobots: !withoutBotsAndRobots,
      ...(activeTopUserId && { userId: activeTopUserId })
    };
  }, [dataSource, from, value, to, withoutBotsAndRobots, activeTopUserId]);

  const Wrapper = useCallback(({ children }: any) => <div>{children}</div>, []);

  const setCountFn = (count: number) => {
    dispatch(updateCountFn({ key: 'postFilters', value: count }));
  };

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    listRef.current.scrollTo(0, 0);
  }, [stockSymbol, value]);

  return (
    <div className={styles.wrapper} ref={listRef}>
      <InfiniteScroll<ITopPostResponse>
        page={page}
        queryOptions={queryOptions}
        updatePage={updatePage}
        ListComponent={Wrapper}
        ItemComponent={PostItem}
        requestUrl={`${API_ROUTES.TOP_POST}/${stockSymbol}`}
        preloaderData={preloaderTopPostData}
        requestTrigger={requestTrigger}
        updateCountFn={setCountFn}
      />
    </div>
  );
};

export default PostsList;
