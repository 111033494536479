import { AppButton, ButtonsType, CustomSelect, Popup, Spinner } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { required } from 'services/InputFIeldsValidator/rules';
import useSWR, { useSWRConfig } from 'swr';
import { Datasource, IResponderAccount } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import styles from './styles.module.scss';
import { IRedditCredentials, IStockTwitsCredentials, ITwitterCredentials } from './types';

interface ICreateConnectionPopupProps {
  stock: string;
  onClose: () => void;
  editId: string | null;
}

interface FormValues {
  name: string;
  source: Datasource;
  disclaimerType: 'image' | 'text';
  credentials: IRedditCredentials | IStockTwitsCredentials | ITwitterCredentials;
}

const CreateConnectionPopup: React.FC<ICreateConnectionPopupProps> = ({ onClose, stock, editId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm<FormValues>();
  const { mutate } = useSWRConfig();

  const {
    data: connectionEditData,
    isLoading: isLoadingConnection,
    isValidating: isValidatingConnection
  } = useSWR<IResponderAccount>(
    editId ? `${API_ROUTES.RESPONDER_ACCOUNTS}/${editId}` : null,
    (url): Promise<IResponderAccount> => {
      return fetchWithConfig({
        url
      });
    }
  );

  useEffect(() => {
    if (!connectionEditData) {
      return;
    }

    const { credentials, name, source } = connectionEditData;
    setValue('credentials', credentials);
    setValue('name', name);
    setValue('source', source);
  }, [connectionEditData]);

  const [loading, setLoading] = useState(false);
  const selectedSource = watch('source');

  const isStockTwitsSelected = selectedSource === Datasource.StockTwits;
  const isRedditSelected = selectedSource === Datasource.Reddit;
  const isTwitterSelected = selectedSource === Datasource.Twitter;

  const onCreate = async (data: FormValues) => {
    setLoading(true);

    try {
      const { name, source, credentials, disclaimerType } = data;
      await fetchWithConfig({
        url: editId ? `${API_ROUTES.RESPONDER_ACCOUNTS}/${editId}` : API_ROUTES.RESPONDER_ACCOUNTS,
        method: editId ? 'PATCH' : 'POST',
        data: {
          stock,
          disclaimerType,
          name,
          source,
          credentials
        }
      });

      mutate([`${API_ROUTES.RESPONDER_ACCOUNTS}/list/${stock}`, { page: 1, take: 10 }]);
      setLoading(false);
      onClose();
    } catch (error: any) {
      catchRequestErrors(
        error,
        () => {},
        () => {},
        setLoading
      );
    }
  };

  return (
    <Popup size="small" title={editId ? 'Edit connection' : 'Create connection'} onClose={onClose}>
      <>
        <div>
          {editId && (isLoadingConnection || isValidatingConnection) ? (
            <Spinner staticPosition size={150} />
          ) : (
            <>
              <div className={styles.field}>
                <CustomSelect
                  name="source"
                  placeholder="Select source"
                  required
                  data={[
                    { name: 'X (Twitter)', _id: Datasource.Twitter },
                    { name: 'StockTwits', _id: Datasource.StockTwits },
                    { name: 'Reddit', _id: Datasource.Reddit }
                  ]}
                  register={register}
                  control={control}
                  errors={errors.source}
                />
              </div>
              <div className={styles.field}>
                <CustomSelect
                  name="disclaimerType"
                  placeholder="Select disclaimer type"
                  required
                  data={[
                    { name: 'Image', _id: 'image' },
                    { name: 'Text', _id: 'text' }
                  ]}
                  register={register}
                  control={control}
                  errors={errors.disclaimerType}
                />
              </div>
              <div className={styles.field}>
                <InputField
                  label="Name"
                  control={control}
                  defaultValue=""
                  name="name"
                  type={InputFieldType.text}
                  register={register}
                  validate={{ required }}
                  errors={errors.name}
                  placeholder="Enter connection name"
                />
              </div>
              {isTwitterSelected && (
                <>
                  <div className={styles.field}>
                    <InputField
                      label="Access Token"
                      control={control}
                      defaultValue=""
                      name="credentials.token"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.token}
                      placeholder="Enter access token"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Secret token"
                      control={control}
                      defaultValue=""
                      name="credentials.tokenSecret"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.tokenSecret}
                      placeholder="Enter secret token value"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Secret"
                      control={control}
                      defaultValue=""
                      name="credentials.secret"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.secret}
                      placeholder="Enter secret value"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Key"
                      control={control}
                      defaultValue=""
                      name="credentials.key"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.key}
                      placeholder="Enter key value"
                    />
                  </div>
                </>
              )}
              {isRedditSelected && (
                <>
                  <div className={styles.field}>
                    <InputField
                      label="Login"
                      control={control}
                      defaultValue=""
                      name="credentials.login"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.login}
                      placeholder="Enter login"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Password"
                      control={control}
                      defaultValue=""
                      name="credentials.password"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.password}
                      placeholder="Enter password"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Secret"
                      control={control}
                      defaultValue=""
                      name="credentials.secret"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.secret}
                      placeholder="Enter secret value"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Client Id"
                      control={control}
                      defaultValue=""
                      name="credentials.clientId"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.clientId}
                      placeholder="Enter ClientId value"
                    />
                  </div>
                </>
              )}
              {isStockTwitsSelected && (
                <>
                  <div className={styles.field}>
                    <InputField
                      label="Login"
                      control={control}
                      defaultValue=""
                      name="credentials.login"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.login}
                      placeholder="Enter login"
                    />
                  </div>
                  <div className={styles.field}>
                    <InputField
                      label="Password"
                      control={control}
                      defaultValue=""
                      name="credentials.password"
                      type={InputFieldType.text}
                      register={register}
                      validate={{ required }}
                      //@ts-ignore
                      errors={errors.credentials?.password}
                      placeholder="Enter password"
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.footerActions}>
          <AppButton
            htmlType="button"
            disabled={loading}
            btnType={ButtonsType.borderedTransparentLowBorder}
            onClick={onClose}
            label="Cancel"
          />
          <AppButton
            isLoading={loading}
            btnType={ButtonsType.simpleSave}
            htmlType="button"
            label={editId ? 'Update' : 'Create'}
            onClick={handleSubmit(onCreate)}
          />
        </div>
      </>
    </Popup>
  );
};

export default CreateConnectionPopup;
