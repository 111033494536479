import * as React from 'react';
import { SVGProps } from 'react';

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9883 17.8125L11.7404 8.70596L11.7511 8.71449L17.3845 2.1875H15.5019L10.9129 7.5L7.26855 2.1875H2.33139L8.16436 10.6896L8.16365 10.6889L2.01172 17.8125H3.89424L8.99623 11.902L13.0511 17.8125H17.9883ZM6.52266 3.60795L15.2888 16.3921H13.797L5.02377 3.60795H6.52266Z"
      fill="white"
    />
  </svg>
);
