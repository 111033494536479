import {
  AccessTime as AccessTimeIcon,
  Close as CloseIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@mui/icons-material';
import classNames from 'classnames';
import { ReadMore, UserAva } from 'components';
import AppButton, { ButtonsType } from 'components/AppButton';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { getRepliedId, setActiveItem } from 'store/slices/responderSlice';
import { theme } from 'theme';
import { IRespondItem, Sentiment, UserAvaType } from 'types';
import { convertNumbersToK, fetchWithConfig, getDateSourceIcon } from 'utils';

import ReplyUserItem from './ReplyUserItem';
import styles from './styles.module.scss';

interface IRespondItemProps {
  data: IRespondItem;
  canReply?: boolean;
}

const RespondItem: React.FC<IRespondItemProps> = ({ data, canReply = true }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const repliedId = useAppSelector(getRepliedId);
  const { _id, user, text, date, impression, dataSource, ai, reply, link, stock } = data;
  const { displayName, userName, image, followers } = user;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const luxonDateTime = DateTime.fromJSDate(new Date(date)).setZone(selectedTimeZone);
  const formatedDate = luxonDateTime.toFormat('d LLL, yyyy HH:mm');

  const iconStyles = { fontSize: 20, margin: 0, color: theme.palette.grey[400] };
  const DataSourceIcon = getDateSourceIcon(dataSource);

  useEffect(() => {
    if (!repliedId) {
      return;
    }

    if (repliedId === _id) {
      setShow(false);
    }
  }, [repliedId]);

  if (!show) {
    return null;
  }

  const replyBtnStyles = reply
    ? {
        pointerEvents: 'none',
        cursor: 'default',
        background: '#2B3B5B',
        borderColor: '#2B3B5B',
        color: '#fff',
        '&:hover': {
          background: '#2B3B5B'
        }
      }
    : {};
  const replyTextKey = reply ? `pages.responder.actions.replyStatus.${reply.status}` : 'pages.responder.actions.reply';

  const onDeclineClick = async () => {
    setLoading(true);
    try {
      await fetchWithConfig({
        url: `${API_ROUTES.RESPONDER_DECLINE}/${stock}`,
        method: 'POST',
        data: {
          conversationId: _id
        }
      });
      setShow(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const onReplyClick = () => {
    if (reply || loading) {
      return;
    }

    dispatch(setActiveItem(data));
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.itemWrapper, {
          [styles.negative]: ai === Sentiment.negative,
          [styles.positive]: ai === Sentiment.positive
        })}
      >
        <div className={styles.content}>
          <div className={styles.userWrapper}>
            <UserAva
              type={UserAvaType.topPost}
              data={{
                name: displayName || userName,
                src: image,
                bottomLabel: `${convertNumbersToK(followers)} ${t('posts.followers')}`
              }}
            />
            {canReply ? <CloseIcon onClick={onDeclineClick} className={styles.closeIcon} /> : <DataSourceIcon />}
          </div>
          <p className={classNames(styles.message, { [styles.withScroll]: !canReply })}>
            {canReply ? <ReadMore>{text}</ReadMore> : text}
          </p>
          <div className={styles.footer}>
            <div className={styles.footerContent}>
              {canReply && <DataSourceIcon />}
              <div className={styles.contentWithIconRow}>
                <VisibilityOutlinedIcon sx={iconStyles} />
                <span>{convertNumbersToK(impression)}</span>
              </div>
              <div className={styles.contentWithIconRow}>
                <AccessTimeIcon sx={iconStyles} />
                <span>{formatedDate}</span>
              </div>
            </div>
            {(link || canReply) && (
              <div className={styles.actionsWrapper}>
                {link && (
                  <AppButton
                    disabled={loading}
                    link={link}
                    btnType={ButtonsType.borderedTransparent}
                    label={t('pages.responder.actions.viewPost')}
                  />
                )}
                {(canReply || !!reply?.status) && (
                  <AppButton
                    disabled={loading}
                    onClick={onReplyClick}
                    customStyles={replyBtnStyles}
                    btnType={ButtonsType.borderedFilled}
                    label={t(replyTextKey)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {reply && <ReplyUserItem data={reply} />}
    </div>
  );
};

export default RespondItem;
