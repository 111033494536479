import { Grid } from '@mui/material';
import React from 'react';

import TopPosts from './TopPosts';
import TopUsers from './TopUsers';

export const Conversation = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
        <TopPosts />
      </Grid>
      <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
        <TopUsers />
      </Grid>
    </Grid>
  );
};
