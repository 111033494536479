import classNames from 'classnames';
import React from 'react';

import { Title, TitleType } from '../../Title';
import styles from './styles.module.scss';

const FormHeading: React.FC<{ title: string; subtitle?: string }> = ({ title, subtitle }) => {
  return (
    <>
      <Title type={TitleType.h1}>
        <>{title}</>
      </Title>
      <p className={classNames(styles.text)}>{!!subtitle && subtitle}</p>
    </>
  );
};

export default FormHeading;
