import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface ISeriesFilterProps {
  legends: { name: string; color: string; symbol: string }[];
  activeLegend: string;
  onClick: (value: string) => () => void;
}

const Legends: React.FC<ISeriesFilterProps> = ({ legends, onClick, activeLegend }) => {
  return (
    <div className={styles.legends}>
      {legends.map((legend) => {
        const { color, name, symbol } = legend;
        return (
          <div
            key={symbol}
            role="button"
            tabIndex={-1}
            className={classNames(styles.legend, { [styles.activeLegend]: symbol === activeLegend })}
            onClick={onClick(symbol)}
          >
            <span className={styles.legendMarker} style={{ backgroundColor: color }} />
            <span className={styles.legendText}>{name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Legends;
