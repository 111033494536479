import * as React from 'react';
import { SVGProps } from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 20 20'} width={20} height={20} fill="none" {...props}>
    <path
      fill="#636C79"
      fillRule="evenodd"
      d="M2.551 10c1.079 2.894 3.991 4.998 7.45 4.998 3.457 0 6.37-2.103 7.448-4.998C16.37 7.106 13.458 5.002 10 5.002c-3.458 0-6.37 2.104-7.449 4.998ZM.874 9.739C2.103 6.005 5.74 3.332 10 3.332s7.898 2.673 9.127 6.407a.835.835 0 0 1 0 .522c-1.23 3.734-4.867 6.407-9.127 6.407-4.26 0-7.897-2.673-9.126-6.407a.835.835 0 0 1 0-.522ZM10 8.335c-1.023 0-1.785.782-1.785 1.665s.762 1.665 1.785 1.665 1.785-.782 1.785-1.665S11.023 8.335 10 8.335ZM6.545 10c0-1.878 1.584-3.335 3.455-3.335 1.87 0 3.455 1.457 3.455 3.335S11.87 13.335 10 13.335c-1.871 0-3.455-1.457-3.455-3.335Z"
      clipRule="evenodd"
    />
  </svg>
);
