import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeStatusType, SummarySentimentAnalyze } from '../../../modules/ExecutiveSummary/types';
import { ChevronDownIcon, ChevronUpIcon, LikeNegativeIcon, LikePositiveIcon } from '../../SvgComponents';
import styles from '../styles.module.scss';

export type SentimentalContentProps = {
  data: SummarySentimentAnalyze;
  loading: boolean;
};
const SentimentalContent: React.FC<SentimentalContentProps> = ({ data, loading }) => {
  const {
    positive: {
      value: totalPositiveValue,
      change: { value: changePositiveValue, status: changePositiveStatus }
    },
    negative: {
      value: totalNegativeValue,
      change: { value: changeNegativeValue, status: changeNegativeStatus }
    },
    neutral: { value: neutralValue }
  } = data;
  const { t } = useTranslation();
  const negativeGrow = changeNegativeStatus === ChangeStatusType.up;
  const negativeFall = changeNegativeStatus === ChangeStatusType.down;
  const positiveGrow = changePositiveStatus === ChangeStatusType.up;
  const positiveFall = changePositiveStatus === ChangeStatusType.down;
  const negativeValueToNumber = Number(totalNegativeValue.replace(/%/gi, ''));
  const neutralValueToNumber = Number(neutralValue.replace(/%/gi, ''));
  const positiveValueToNumber = Number(totalPositiveValue.replace(/%/gi, ''));
  return (
    <>
      <div className={styles.chart}>
        <div className={classNames(styles.chartValue, styles.positive)} style={{ width: totalPositiveValue }}>
          <div className={styles.valueWrapper}>
            <LikePositiveIcon className={styles.chartDataIcon} />
            {totalPositiveValue}
          </div>
          <div className={styles.bar}></div>
        </div>
        <div className={classNames(styles.chartValue, styles.default)}>
          <div className={styles.valueWrapper}>{neutralValue}</div>
          <div className={styles.bar}></div>
        </div>
        <div className={classNames(styles.chartValue, styles.negative)} style={{ width: totalNegativeValue }}>
          <div className={styles.valueWrapper}>
            <LikeNegativeIcon className={styles.chartDataIcon} />
            {totalNegativeValue}
          </div>
          <div className={styles.bar}></div>
        </div>
      </div>
      <Typography className={classNames(styles.analyzeText, styles.spacer)} variant="body2">
        {t('executiveSummary.analyze')}
      </Typography>
      <div className={classNames(styles.row, styles.sentimentRow)}>
        <div className={styles.label}>
          {t('executiveSummary.positive')}
          {positiveFall && !!positiveValueToNumber && <ChevronDownIcon />}
          {positiveGrow && !!positiveValueToNumber && <ChevronUpIcon />}
          {!positiveValueToNumber && <RemoveIcon sx={{ color: grey[500] }} />}
          <span className={styles[positiveFall ? 'negative' : 'positive']}>{changePositiveValue}</span>
        </div>
        <div className={styles.label}>
          {t('executiveSummary.negative')}
          {negativeGrow && !!negativeValueToNumber && (
            <ChevronUpIcon className={styles[negativeFall ? 'positive' : 'negative']} />
          )}
          {negativeFall && !!negativeValueToNumber && (
            <ChevronDownIcon className={styles[negativeFall ? 'positive' : 'negative']} />
          )}
          {!negativeValueToNumber && <RemoveIcon sx={{ color: grey[500] }} />}
          <span className={styles[negativeFall ? 'positive' : 'negative']}>{changeNegativeValue}</span>
        </div>
      </div>
    </>
  );
};

export default SentimentalContent;
