import { InfiniteScroll } from 'components';
import { API_ROUTES } from 'const';
import { useAppDispatch } from 'hooks';
import React, { useCallback } from 'react';
import { updateRequestedListPage } from 'store/slices/filtersSlice';
import { IRespondItem } from 'types';

import useResponderList from '../../hooks/useResponderList';
import RespondItem from './Item';
import SkeletonItem from './SkeletonItem';

interface IResponderListProps {}

const ResponderList: React.FC<IResponderListProps> = () => {
  const dispatch = useAppDispatch();
  const Wrapper = useCallback(({ children }: any) => <div>{children}</div>, []);

  const { query, stockSymbol, page } = useResponderList();

  const ItemComponent: React.FC<{ data: IRespondItem }> = ({ data }) => {
    return <RespondItem data={data} canReply={!data.reply} />;
  };

  const updatePage = (page?: number) => {
    dispatch(updateRequestedListPage({ storeKeyName: 'responderFilters', count: page }));
  };

  return (
    <InfiniteScroll<IRespondItem>
      page={page}
      queryOptions={query}
      updatePage={updatePage}
      ListComponent={Wrapper}
      ItemComponent={ItemComponent}
      requestUrl={`${API_ROUTES.RESPONDER_LIST}/${stockSymbol}`}
      SkeletonComponent={SkeletonItem}
      preloaderData={Array.from(Array(10))}
      requestTrigger={stockSymbol}
    />
  );
};

export default ResponderList;
