import * as React from 'react';
import { SVGProps } from 'react';

export const CrownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={18} fill="none" {...props}>
    <path
      fill="#EAAA08"
      d="M20.133 7.686a1.319 1.319 0 0 0-.64-.263 1.358 1.358 0 0 0-.693.092l-4.04 1.668-2.346-3.929a1.242 1.242 0 0 0-.471-.442 1.362 1.362 0 0 0-1.293 0 1.242 1.242 0 0 0-.471.442L7.832 9.183l-4.04-1.668a1.366 1.366 0 0 0-.693-.092c-.236.03-.459.12-.642.261-.184.142-.321.327-.397.537-.075.21-.086.435-.031.65L4.056 16.9c.039.156.111.302.213.43.101.13.23.238.378.319a1.368 1.368 0 0 0 .998.126c3.695-.949 7.6-.949 11.295 0 .337.082.696.037.998-.126.149-.08.278-.188.38-.317.101-.129.173-.276.21-.432l2.036-8.027c.054-.216.043-.44-.033-.65a1.188 1.188 0 0 0-.398-.536Z"
    />
    <circle cx={11.294} cy={2.658} r={2.658} fill="#EAAA08" />
    <circle cx={11.296} cy={11.96} r={1.993} fill="#BE9502" />
    <circle cx={20.929} cy={6.976} r={1.661} fill="#EAAA08" />
    <circle cx={1.661} cy={6.976} r={1.661} fill="#EAAA08" />
    <circle cx={6.311} cy={12.956} r={0.997} fill="#BE9502" />
    <circle cx={16.278} cy={12.956} r={0.997} fill="#BE9502" />
  </svg>
);
