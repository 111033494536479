import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { InfiniteScroll } from '../../../../components';
import { API_ROUTES } from '../../../../const';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getUserStockSymbol } from '../../../../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  getTopUsersTabsState,
  updateRequestedListPage
} from '../../../../store/slices/filtersSlice';
import { ITopUserResponse } from '../../types';
import { preloaderTopUsersData } from '../../utils';
import UserItem from './UserItem';
import styles from './styles.module.scss';

const UserList = () => {
  const from = useAppSelector(getFiltersDateRangeFrom);
  const to = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const { page, value } = useAppSelector(getTopUsersTabsState);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const dispatch = useAppDispatch();
  const listRef = useRef<HTMLDivElement>(null);
  const updatePage = (page?: number) => {
    dispatch(updateRequestedListPage({ storeKeyName: 'usersFilters', count: page }));
  };

  const queryOptions = useMemo(() => {
    return {
      from,
      to,
      dataSource,
      order: value,
      withBotsAndRobots: !withoutBotsAndRobots
    };
  }, [dataSource, value, from, to, withoutBotsAndRobots]);

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    listRef.current.scrollTo(0, 0);
  }, [stockSymbol, value]);

  const Wrapper = useCallback(({ children }: any) => <div>{children}</div>, []);
  return (
    <div className={styles.wrapper} ref={listRef}>
      <InfiniteScroll<ITopUserResponse>
        page={page}
        queryOptions={queryOptions}
        updatePage={updatePage}
        ListComponent={Wrapper}
        ItemComponent={UserItem}
        requestUrl={`${API_ROUTES.TOP_USERS}/${stockSymbol}`}
        preloaderData={preloaderTopUsersData}
        requestTrigger={stockSymbol}
      />
    </div>
  );
};

export default UserList;
