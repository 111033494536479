import { Box, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { BaseRecord, ResponseWithPagination } from 'types';
import { fetchWithConfig } from 'utils';

import BlurredSpinner from '../BluresSpinner';
import { NoData } from '../NoData';
import CrudHeadingList from './CrudHeadingList';
import CrudTableRowList from './CrudTableRowList';
import styles from './styles.module.scss';

interface CrudTableProps {
  columns: any[];
  requestUrl: string;
  take?: number;
  queryOptions?: Record<string, any>;
  defaultSorting?: { name: string; direction: 1 | -1 };
  noBorder?: boolean;
  noPagination?: boolean;
}

export interface initialStateInterface<TData> {
  page: number;
  listData: TData;
  hasMore: boolean;
  rowsPerPage: number;
  sortOption: {
    dataIndex: string;
    direction: 1 | -1;
  };
}

export const CrudTable = <TResponse extends BaseRecord = any>(props: CrudTableProps) => {
  const { columns, requestUrl, take = 10, queryOptions = {}, defaultSorting, noBorder, noPagination = false } = props;
  const prevRequestUrl = useRef(requestUrl);
  const initialState = {
    page: 0,
    listData: [],
    hasMore: true,
    rowsPerPage: take,
    sortOption: {
      dataIndex: defaultSorting?.name || '',
      direction: defaultSorting?.direction || 1
    }
  };

  const [
    {
      page,
      listData,
      rowsPerPage,
      sortOption: { dataIndex, direction }
    },
    setState
  ] = useState<initialStateInterface<TResponse[]>>(initialState);

  useEffect(() => {
    setState(initialState);
    prevRequestUrl.current = requestUrl;
  }, [requestUrl, JSON.stringify(queryOptions)]);

  const {
    data: { list, meta } = { list: [], meta: { hasNextPage: true, itemsCount: 1 } },
    error,
    isLoading = true,
    mutate,
    isValidating
  } = useSWR<ResponseWithPagination<TResponse>>(
    [
      requestUrl,
      {
        ...queryOptions,
        page: prevRequestUrl.current === requestUrl ? page + 1 : 1,
        take: rowsPerPage,
        ...(dataIndex && {
          sort: dataIndex,
          direction
        })
      }
    ],
    (options: [string, Record<string, any>]): Promise<ResponseWithPagination<TResponse>> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  useEffect(() => {
    if (isLoading || error) {
      return;
    }

    setState((state) => {
      return {
        ...state,
        listData: [...list],
        hasMore: meta.hasNextPage
      };
    });
  }, [error, isLoading, list, meta.hasNextPage]);

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({
      ...state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }));
  };

  const onChangePage = (event: unknown, newPage: number) => {
    setState((state) => ({
      ...state,
      page: newPage
    }));
  };
  const renderData: any = useMemo(() => {
    return isLoading ? [1, 2, 3] : listData;
  }, [isLoading, listData]);

  return (
    <Box
      sx={{ width: '100%', position: 'relative' }}
      className={classNames(styles.wrapper, { [styles.noBorder]: !!noBorder })}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750, tableLayout: 'auto' }} aria-labelledby="tableTitle">
            <CrudHeadingList<TResponse>
              columns={columns}
              sortFn={setState}
              direction={direction}
              dataIndex={dataIndex}
            />
            <TableBody>
              {!listData.length && !isLoading && !error && !isValidating && (
                <tr>
                  <td colSpan={columns.length}>
                    <NoData />
                  </td>
                </tr>
              )}
              <CrudTableRowList<TResponse> data={renderData} columns={columns} isLoading={isLoading} mutate={mutate} />
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading || isValidating ? (
          <BlurredSpinner size={42} fillContent={true} />
        ) : !noPagination ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={meta.itemsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </Box>
  );
};
