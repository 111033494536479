import { Box, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AppLogo, { AppLogoSize } from '../../AppLogo';
import styles from '../styles.module.scss';
import ChooseTimeZone from './ChooseTimeZone';
import MainNavigation from './MainNavigation';
import UserMenu from './UserMenu';

const Header = () => {
  const location = useLocation();
  return (
    <header className={styles.header}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={2} sx={{ display: 'flex' }}>
              <div className={styles.row}>
                <AppLogo size={AppLogoSize.small} />
              </div>
            </Grid>
            <Grid item xs={8} sx={{ display: 'flex' }}>
              <div className={styles.row}>
                <MainNavigation />
              </div>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!location.pathname.includes('profile') && <ChooseTimeZone />}
              <UserMenu />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </header>
  );
};

export default Header;
