import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { RangePicker } from 'components';
import { CalendarIcon } from 'components/SvgComponents';
import React, { InputHTMLAttributes, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';

import styles from './styles.module.scss';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  control: any;
  defaultValue: string | number;
  name: string;
  type: InputFieldType;
  register: any;
  validate: Record<string, any>;
  errors: FieldError | undefined;
  onRightIconClick?: () => void;
  LeftIcon?: React.FC;
  RightIcon?: React.FC;
  placeholder: string;
  validateText?: string;
  className?: string;
  label?: string;
  prefix?: string;
  disabled?: boolean;
  noFocus?: boolean;
  autocomplete?: boolean;
  rangePickerConfig?: {
    initialRange: Date[];
    minDate: Date;
    onRangeChange: (fromDate: string, toDate: string) => void;
  };
  toolTip?: string;
  writePattern?: RegExp;
}

export enum InputFieldType {
  text = 'text',
  email = 'email',
  password = 'password',
  textarea = 'textarea',
  number = 'number'
}

const InputField: React.FC<InputFieldProps> = ({
  control,
  defaultValue = '',
  name,
  type,
  register,
  validate,
  errors,
  onRightIconClick,
  LeftIcon,
  RightIcon,
  placeholder,
  validateText,
  label,
  onFocus = () => {},
  prefix,
  disabled = false,
  noFocus,
  className,
  autocomplete = true,
  rangePickerConfig,
  toolTip,
  writePattern,
  ...restInputProps
}) => {
  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  const { min, max } = restInputProps;
  const maxMinRules =
    typeof min !== 'undefined' && typeof max !== 'undefined'
      ? {
          min: {
            value: min,
            message: `Min value should be ${min}`
          },
          max: {
            value: max,
            message: `Max value should be ${max}`
          }
        }
      : {};

  const onWrapperClick = () => {
    if (!rangePickerConfig) {
      return;
    }

    setRangePickerOpen(true);
  };

  const Tag = onRightIconClick ? 'button' : 'div';
  const TagComponent = type === InputFieldType.textarea ? 'textarea' : 'input';
  const RightIconComponent = rangePickerConfig && !RightIcon ? CalendarIcon : RightIcon;

  return (
    <div className={styles.inputWrapper}>
      {label && (
        <div className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
          {toolTip && (
            <Tooltip title={toolTip}>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          const handleChange = (event: any) => {
            const value = event.target.value;
            if (writePattern) {
              if (writePattern.test(value)) {
                field.onChange(value);
              }
              return;
            }

            field.onChange(value);
          };

          return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className={styles.flex} onClick={onWrapperClick}>
              {!!prefix && <div className={styles.prefix}>{prefix}</div>}
              <div className={classNames(styles.relative)}>
                {!!LeftIcon && (
                  <div className={classNames(styles.icon, styles.iconLeft)}>
                    <LeftIcon />
                  </div>
                )}
                <TagComponent
                  {...restInputProps}
                  autoComplete={autocomplete ? 'on' : 'off'}
                  disabled={disabled}
                  onFocus={onFocus}
                  className={classNames(
                    styles.input,
                    {
                      [styles.leftPadding]: !!LeftIcon,
                      [styles.rightPadding]: !!RightIcon,
                      [styles.leftBorder]: !!prefix,
                      [styles.noFocus]: noFocus,
                      [styles.clickable]: !!rangePickerConfig
                    },
                    className
                  )}
                  type={type}
                  placeholder={placeholder}
                  {...register(name, {
                    ...maxMinRules,
                    required: validateText,
                    validate: {
                      ...validate
                    }
                  })}
                  {...field}
                  onChange={handleChange}
                />
                {!!RightIconComponent && (
                  <Tag
                    className={classNames(styles.icon, styles.iconRight, {
                      [styles.hover]: !!onRightIconClick
                    })}
                    {...(!!onRightIconClick && {
                      onClick: onRightIconClick,
                      type: 'button'
                    })}
                  >
                    <RightIconComponent />
                  </Tag>
                )}
              </div>
            </div>
          );
        }}
      />

      {!!errors && <span className={styles.error}>{errors.message}</span>}
      {rangePickerConfig && rangePickerOpen && (
        <div className={classNames(styles.rangePickerWrapper, 'range-picker-global-wrapper')}>
          <RangePicker
            isOpen={rangePickerOpen}
            onBtnClose={() => setRangePickerOpen(false)}
            onApply={rangePickerConfig.onRangeChange}
            initialRange={rangePickerConfig.initialRange}
            minDate={rangePickerConfig.minDate}
          />
        </div>
      )}
    </div>
  );
};

export default InputField;
