import { SectionCard } from 'components';
import { useAppSelector } from 'hooks';
import React from 'react';
import { getUiConfig } from 'store/slices/authSlice';
import { sortArrByKey } from 'utils';

import StickyFilters from '../StickyFilters';
import styles from '../styles.module.scss';
import { chartsUiConfigComponents } from '../ui-config';

const ChartsSection = () => {
  const uiConfig = useAppSelector(getUiConfig);

  if (!uiConfig) {
    return null;
  }

  const {
    config: {
      homepage: { charts }
    }
  } = uiConfig;

  const sortedCharts = sortArrByKey(charts, 'order');

  return (
    <SectionCard nativeClassName={styles.wrapper} noBorder>
      <>
        <StickyFilters />
        {sortedCharts.map(({ uiKey, enabled }) => {
          const Component = chartsUiConfigComponents[uiKey];

          if (!Component || !enabled) {
            return null;
          }

          return <Component key={uiKey} />;
        })}
      </>
    </SectionCard>
  );
};

export default ChartsSection;
