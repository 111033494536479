import { Typography } from '@mui/material';
import classNames from 'classnames';
import { ChooseCompetitiveStocks, WarningMessage } from 'components';
import AppButton, { ButtonsType } from 'components/AppButton';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import useCompanyInfo from 'hooks/userCompanyInfo';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUser, setUserCompetitiveStocks } from 'store/slices/authSlice';
import { IUser } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

const Form = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const { selectedStock } = user;
  const { companyInfo } = useCompanyInfo(selectedStock.stock);
  const { image, name, symbol } = companyInfo;

  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    let error = '';

    if (!selectedStocks.length) {
      error = t('pages.competitiveAnalysis.configurePopup.stocksError');
    }

    if (error) {
      setError(error);
      return;
    }

    try {
      setError('');
      setLoading(true);
      const responseData = await fetchWithConfig<{
        accessToken: string;
        user: IUser;
      }>({
        url: API_ROUTES.SET_COMPETITIVE_STOCKS,
        method: 'POST',
        data: {
          stocks: selectedStocks,
          teamId: user.team ? user?.team._id : null,
          stockKitId: selectedStock.kitId
        }
      });

      setLoading(false);
      dispatch(setUserCompetitiveStocks({ stock: selectedStock.stock, competitiveStocks: selectedStocks }));
    } catch (error: any) {
      console.error(error);
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.companyCompetitorHeader}>
        <div className={styles.companyImg}>
          <img src={image} alt={symbol} />
        </div>
        <Typography>{name}</Typography>
        <Typography className={styles.companySymbol} variant="body2">
          {symbol}
        </Typography>
        <span className={styles.versusText}>Vs.</span>
      </div>
      <ChooseCompetitiveStocks mainStock={selectedStock.stock} onChange={setSelectedStocks} />
      <WarningMessage message={t('pages.competitiveAnalysis.configurePopup.warningMessage')} />
      <div className={classNames(styles.saveWrapper, { [styles.withError]: !!error })}>
        {error && <div className={styles.error}>{error}</div>}
        <AppButton
          isLoading={loading}
          disabled={loading}
          onClick={onSubmit}
          customStyles={{ minWidth: 170, paddingTop: '10px', paddingBottom: '10px', minHeight: 48 }}
          btnType={ButtonsType.simpleSave}
          label={t('buttons.save')}
        />
      </div>
    </div>
  );
};

export default Form;
