import * as React from 'react';
import { SVGProps } from 'react';

export const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g fill="#EAECF0" clipPath="url(#a)">
      <path d="M12.667 3.34h-1.334v-.673c0-.367-.3-.667-.666-.667-.367 0-.667.3-.667.667v.673H6v-.673C6 2.3 5.7 2 5.333 2c-.366 0-.666.3-.666.667v.673H3.333C2.593 3.34 2 3.94 2 4.673v7.994C2 13.407 2.6 14 3.333 14h9.327c.74 0 1.333-.593 1.333-1.333V4.673c0-.74-.6-1.333-1.333-1.333h.007Zm0 9.327H3.333v-8h1.334v.666c0 .367.3.667.666.667C5.7 6 6 5.7 6 5.333v-.666h4v.666c0 .367.3.667.667.667.366 0 .666-.3.666-.667v-.666h1.334v8Z" />
      <path d="M10.667 10H5.333c-.366 0-.666.3-.666.667 0 .366.3.666.666.666h5.334c.366 0 .666-.3.666-.666 0-.367-.3-.667-.666-.667Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
