import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface ISeriesFilterProps {
  filters: { name: string; value: string; icon?: React.ReactElement }[];
  activeFilter: string;
  onClick: (value: string) => () => void;
}

const SeriesFilter: React.FC<ISeriesFilterProps> = ({ filters, onClick, activeFilter }) => {
  return (
    <div className={styles.seriesFiltersWrapper}>
      {filters.map(({ name, value, icon }) => {
        return (
          <div
            role="button"
            tabIndex={-1}
            onClick={onClick(value)}
            className={classNames(styles.seriesFilter, { [styles.active]: value === activeFilter })}
            key={value}
          >
            {icon}
            {name}
          </div>
        );
      })}
    </div>
  );
};

export default SeriesFilter;
