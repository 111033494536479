import classNames from 'classnames';
import { CheckboxComponent } from 'components';
import ContentBlock from 'pages/Admin/ContentBlock';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { required } from 'services/InputFIeldsValidator/rules';
import useSWR from 'swr';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import BlurredSpinner from '../../../../components/BluresSpinner';
import { FormErrorsLabel } from '../../../../components/FormErrorsLabel';
import InputField, { InputFieldType } from '../../../../components/InputField';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { IStock } from '../../../../types';
import { catchRequestErrors, fetchWithConfig } from '../../../../utils';
import DashboardLayout from '../../DashboardLayout';
import ConnectionsList from '../ConnectionsList';
import CreateConnectionPopup from '../CreateConnectionPopup';
import CreateDisclaimer from '../CreateDisclaimer';
import CreateInfluencerConfig, { CreateInfluencerFormValues } from '../CreateInfluencerConfig';
import CreateRedditSources, { CreateRedditSourcesFormValues } from '../CreateRedditSources';
import styles from '../styles.module.scss';

function filterAndConvertObject(obj: any) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => [key, Number(value)])
  );
}

type FormValues = {
  name: string;
  symbol: string;
  determKeywordId: number;
  services: {
    trendingTopics: boolean;
  };
};

export const CreateEditStock = () => {
  const { t } = useTranslation();
  const { stocksSymbol } = useParams();
  const {
    data: stockEditData,
    isLoading,
    mutate
  } = useSWR<IStock>(stocksSymbol ? `${API_ROUTES.STOCKS}/${stocksSymbol}` : null, (url) => fetchWithConfig({ url }));

  const navigate = useNavigate();

  const [redditSources, setRedditSources] = useState<CreateRedditSourcesFormValues>({
    stockSubReddit: '',
    generalSubReddit: '',
    generalSubRedditQuery: '',
    mainSubRedditQuery: '',
    mainQuery: ''
  });

  const [influencerConfig, setInfluencerConfig] = useState<CreateInfluencerFormValues>(
    {} as CreateInfluencerFormValues
  );

  const onChangeRedditSources = (data: CreateRedditSourcesFormValues) => {
    setRedditSources((prevState) => ({
      ...prevState,
      ...data
    }));
  };

  const onChangeInfluencerConfig = (data: CreateInfluencerFormValues) => {
    setInfluencerConfig((prevState) => ({
      ...prevState,
      ...data
    }));
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control
  } = useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');
  const [showConnectionPopup, setShowConnectionPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const editAccountId = searchParams.get('editAccountId');
  const [stockLogoFile, setStockLogoFile] = useState<File | null>(null);
  const [stockLogo, setStockLogo] = useState('');

  useEffect(() => {
    setStockLogo(stockEditData?.image || '');
  }, [stockEditData?.image]);

  const onFileLogoChange = (file: File) => {
    if (!file) {
      return;
    }

    setStockLogoFile(file);
    setStockLogo(URL.createObjectURL(file));
  };

  const onSubmit = async (formData: FormValues) => {
    setGlobalError('');
    try {
      setLoading(true);

      if (stocksSymbol && stockLogoFile) {
        const formData = new FormData();
        formData.append('logo', stockLogoFile!);
        await fetchWithConfig({
          url: `${API_ROUTES.STOCKS}/update-logo/${stocksSymbol}`,
          method: 'POST',
          data: formData
        });
      }

      await fetchWithConfig({
        url: stocksSymbol ? `${API_ROUTES.STOCKS}/${stockEditData?._id}` : API_ROUTES.STOCKS,
        method: stocksSymbol ? 'PATCH' : 'POST',
        data: {
          name: formData.name,
          symbol: formData.symbol,
          determKeywordId: +formData.determKeywordId,
          services: formData.services
        }
      });

      const { generalSubReddit, stockSubReddit, ...restRedditSources } = redditSources;
      await fetchWithConfig({
        url: API_ROUTES.REDDIT_SOURCE_CONFIG,
        method: 'POST',
        data: {
          stock: formData.symbol || stocksSymbol,
          generalSubReddit: generalSubReddit.split(',').filter(Boolean),
          stockSubReddit: stockSubReddit.split(',').filter(Boolean),
          ...restRedditSources
        }
      });

      await fetchWithConfig({
        url: API_ROUTES.INFLUENCER_CONFIG,
        method: 'POST',
        data: {
          stock: formData.symbol || stocksSymbol,
          influencerFollowersConfig: filterAndConvertObject(influencerConfig.influencerFollowersConfig)
        }
      });

      ToastifyService.setToast(`Stock ${stocksSymbol ? 'updated' : 'created'} succesfuly`, 'success');
      setLoading(false);

      if (stocksSymbol) {
        mutate();
        setStockLogoFile(null);
        return;
      }

      navigate(APP_ROUTS.STOCKS);
    } catch (error: any) {
      catchRequestErrors(error, setError, setGlobalError, setLoading);
    }
  };

  const onCloseConnectionPopup = () => {
    setShowConnectionPopup(false);
    navigate(`${APP_ROUTS.STOCKS}/edit/${stocksSymbol}`);
  };

  return (
    <DashboardLayout
      title={stocksSymbol ? stockEditData?.name || t('pages.admin.stockEdit') : t('pages.admin.stockCreate')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton
          disabled={Boolean(errors.name)}
          isLoading={loading}
          label={stocksSymbol ? 'update' : 'create'}
          btnType={ButtonsType.signIn}
        />
      }
    >
      {isLoading ? (
        <BlurredSpinner />
      ) : (
        <>
          {stockEditData && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelRight}>
                  <div className={styles.logoWrapper}>
                    <div className={styles.img}>
                      <img src={stockLogo} alt={stockEditData.name} />
                    </div>
                    <AppButton
                      btnType={ButtonsType.outlined}
                      label="Change logo"
                      isInputFile
                      onFileChange={onFileLogoChange}
                    />
                  </div>
                </div>
              </div>
            </ContentBlock>
          )}
          {stocksSymbol && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{'Enter new stock name'}</p>
                </div>
                <div className={styles.labelRight}>
                  <InputField
                    label={''}
                    control={control}
                    defaultValue={stockEditData?.name || ''}
                    name={'name'}
                    type={InputFieldType.text}
                    register={register}
                    placeholder={'Stock name'}
                    validateText={t('errors.requiredField')}
                    validate={{
                      required
                    }}
                    errors={errors.name}
                  />
                </div>
              </div>
            </ContentBlock>
          )}
          {!stocksSymbol && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{'Enter a stock symbol'}</p>
                </div>
                <div className={styles.labelRight}>
                  <InputField
                    label={''}
                    control={control}
                    defaultValue={''}
                    name={'symbol'}
                    type={InputFieldType.text}
                    register={register}
                    placeholder={'Stock symbol'}
                    validateText={t('errors.requiredField')}
                    validate={{
                      required
                    }}
                    errors={errors.symbol}
                  />
                </div>
              </div>
            </ContentBlock>
          )}
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{'Determ keyword id'}</p>
              </div>
              <div className={styles.labelRight}>
                <InputField
                  label={''}
                  control={control}
                  defaultValue={stockEditData?.determKeywordId || ''}
                  name={'determKeywordId'}
                  type={InputFieldType.number}
                  register={register}
                  placeholder={'Determ keyword id'}
                  validateText={t('errors.requiredField')}
                  validate={{
                    required
                  }}
                  errors={errors.determKeywordId}
                />
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>Services</p>
              </div>
              <div className={styles.labelRight}>
                <CheckboxComponent
                  control={control}
                  defaultValue={!!stockEditData?.services?.trendingTopics}
                  register={register}
                  name="services.trendingTopics"
                  label="Trending Topics"
                />
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <CreateInfluencerConfig stock={stocksSymbol} onChange={onChangeInfluencerConfig} />
          </ContentBlock>
          <ContentBlock>
            <CreateRedditSources stock={stocksSymbol} onChange={onChangeRedditSources} />
          </ContentBlock>
          {stocksSymbol && (
            <>
              <div className={classNames(styles.row, styles.centered)}>
                <div className={styles.labelWrapper}>
                  <div className={styles.title}>Responder connection</div>
                </div>
                <div className={styles.labelRightButton}>
                  <AppButton
                    btnType={ButtonsType.outlined}
                    onClick={() => setShowConnectionPopup(true)}
                    htmlType="button"
                    label="Add connection"
                  />
                </div>
              </div>
              <div className={styles.connectionsWrapper}>
                <ConnectionsList stock={stocksSymbol} />
              </div>
              <CreateDisclaimer stock={stocksSymbol} />
            </>
          )}
          {(showConnectionPopup || editAccountId) && (
            <CreateConnectionPopup editId={editAccountId} stock={stocksSymbol!} onClose={onCloseConnectionPopup} />
          )}
          <FormErrorsLabel message={globalError} />
        </>
      )}
    </DashboardLayout>
  );
};
