import { theme } from '../../theme';

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: theme.palette.grey[700],
      width: 40,
      color: theme.palette.secondary.main,
      textTransform: 'uppercase'
    },
    children: `${name[0]}`
  };
};
