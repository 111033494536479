import { createAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/index';

import { CompanyResponse } from '../../modules/ExecutiveSummary/types';

const initialState: { stockData: CompanyResponse } = {
  stockData: {
    _id: '',
    name: '',
    symbol: '',
    image: '',
    isActive: false,
    dateCreate: ''
  }
};
export const setStockData = createAction<CompanyResponse>('stocksSlice/setStockData');
export const getStockData = (store: RootState) => store.stock.stockData;

const stocksSlice = createSlice({
  name: 'stocksSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setStockData, (store, { payload }) => {
      store.stockData = payload;
    });
  }
});

export const stocksReducers = stocksSlice.reducer;
