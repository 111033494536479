const env = process.env.REACT_APP_ENVIRONMENT;

class LoggerService {
  public env: string;
  constructor(env: string = '') {
    this.env = env;
  }

  canLog() {
    return (this.env && this.env) === 'local';
  }

  log(message: string, ...rest: any) {
    if (this.canLog()) {
      console.log(message, ...rest);
    }
  }
}

export default new LoggerService(env);
