import DoneIcon from '@mui/icons-material/Done';
import classNames from 'classnames';
import AppButton, { ButtonsType } from 'components/AppButton';
import { ActionBadAccountIcon, ActionCollectIcon, ActionMakePrIcon } from 'components/SvgComponents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRecommendedItem, RecommendedActionTypes } from 'types';
import { getDateSourceIcon } from 'utils';

import styles from './styles.module.scss';

interface IRecommendedItemProps {
  data: IRecommendedItem;
}

const getRecommendationContent = (type: RecommendedActionTypes, translate: (key: string) => any) => {
  switch (type) {
    case RecommendedActionTypes.BAD_ACCOUNT:
      return {
        name: translate('pages.recommendedActions.itemTypes.badAccount'),
        icon: <ActionBadAccountIcon />,
        bottomSection: <>{translate('pages.recommendedActions.badAccount.bottomLabel')}</>
      };
    case RecommendedActionTypes.MAKE_PR:
      return {
        name: translate('pages.recommendedActions.itemTypes.makePr'),
        icon: <ActionMakePrIcon />
      };
    case RecommendedActionTypes.COLLECT_DOCUMENTATION:
      return {
        name: translate('pages.recommendedActions.itemTypes.collectDocumentation'),
        icon: <ActionCollectIcon />,
        bottomSection: (
          <>
            {translate('pages.recommendedActions.collectDocumentation.bottomLabel')}
            &nbsp;
            <a className={styles.link} href="mailto:koios@gmail.com">
              {translate('pages.recommendedActions.collectDocumentation.contact')}
            </a>
          </>
        )
      };
  }
};

const RecommendedItem: React.FC<IRecommendedItemProps> = ({ data }) => {
  const { t } = useTranslation();
  const { type, text, dataSource } = data;
  const { name, icon, bottomSection } = getRecommendationContent(type, t);

  const SourceIcon = dataSource ? getDateSourceIcon(dataSource) : null;

  return (
    <div className={classNames(styles.wrapper, styles[type])}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <div className={styles.iconNameWrapper}>
            <div className={styles.iconWrapper}>{icon}</div>
            <div>{name}</div>
          </div>
          <div className={styles.actionButtons}>
            {type === RecommendedActionTypes.COLLECT_DOCUMENTATION && (
              <AppButton
                btnType={ButtonsType.borderedTransparent}
                label={t('pages.recommendedActions.collectDocumentation.viewButton')}
              />
            )}
            {type === RecommendedActionTypes.MAKE_PR && (
              <AppButton
                btnType={ButtonsType.borderedTransparent}
                label={t('pages.recommendedActions.makePr.showLast')}
              />
            )}
            {type === RecommendedActionTypes.BAD_ACCOUNT && (
              <>
                <AppButton
                  btnType={ButtonsType.borderedTransparent}
                  label={
                    <>
                      {t('pages.recommendedActions.badAccount.viewPostButton')}
                      {SourceIcon && <SourceIcon className={styles.dataSourceIcon} />}
                    </>
                  }
                />
                <AppButton
                  btnType={ButtonsType.borderedFilled}
                  label={t('pages.recommendedActions.badAccount.reportButton')}
                />
              </>
            )}
            <AppButton btnType={ButtonsType.borderedTransparent} label={<DoneIcon />} />
          </div>
        </div>
        <p className={styles.message}>{text}</p>
      </div>
      {bottomSection && <div className={styles.bottomSection}>{bottomSection}</div>}
    </div>
  );
};

export default RecommendedItem;
