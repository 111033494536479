import { configureStore } from '@reduxjs/toolkit';
import { authReducers } from 'store/slices/authSlice';

import { adminReducers } from './slices/adminSlice';
import { alertsReducers } from './slices/alertsSlice';
import { filtersReducers } from './slices/filtersSlice';
import { responderReducers } from './slices/responderSlice';
import { stocksReducers } from './slices/stocksSlice';

export const store = configureStore({
  reducer: {
    auth: authReducers,
    filters: filtersReducers,
    alerts: alertsReducers,
    admin: adminReducers,
    stock: stocksReducers,
    responder: responderReducers
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
