import { Close as CloseIcon } from '@mui/icons-material';
import classNames from 'classnames';
import { BulletListItem } from 'components';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import { IConversationSummaryItem } from 'modules/Charts/types';
import React, { useEffect, useRef, useState } from 'react';
import { getSelectedTimeZone } from 'store/slices/authSlice';

import GeneratingSummaryLoader from '../GeneratingSummaryLoader';
import styles from './styles.module.scss';

interface ISummaryItemProps {
  data: IConversationSummaryItem | null;
  prevData: IConversationSummaryItem | null;
  onClose: () => void;
  isLoading: boolean;
}

const SummaryContent = ({ data, onClose }: { data: IConversationSummaryItem | null; onClose: () => void }) => {
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);

  if (!data) {
    return null;
  }

  const { dayDate, summary } = data;
  const parsedDate = DateTime.fromJSDate(new Date(dayDate));
  const formattedTime = parsedDate.setZone(selectedTimeZone).toFormat('MM/dd/yyyy');

  const summaryItems = summary
    .split('\n')
    .filter(Boolean)
    .map((item) => item.replace('- ', ''));

  return (
    <>
      <div className={styles.summaryTitleWrapper}>
        <div className={styles.summaryTitle}>Report: {formattedTime}</div>
        <CloseIcon onClick={onClose} className={styles.summaryCloseIcon} />
      </div>
      {summaryItems.map((item) => {
        return <BulletListItem key={item} text={item} />;
      })}
    </>
  );
};

const SummaryItem: React.FC<ISummaryItemProps> = ({ data, prevData, onClose, isLoading }) => {
  const summaryItemRef = useRef<HTMLDivElement>(null);
  const summaryPrevItemRef = useRef<HTMLDivElement>(null);
  const summaryWrapperRef = useRef<HTMLDivElement>(null);
  const prevIsDifference = prevData && data && prevData.summary !== data.summary;
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const onCloseClick = () => {
    summaryWrapperRef.current?.style.setProperty('height', '0px');

    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const isLoadingWithFirstData = isLoading && data;

  useEffect(() => {
    const summaryItem = summaryItemRef.current;
    const height = isLoadingWithFirstData ? summaryPrevItemRef.current?.offsetHeight : summaryItem?.offsetHeight;
    summaryWrapperRef.current?.style.setProperty('height', `${height}px`);

    setShouldAnimate(false);
  }, [data?.summary, isLoading, isLoadingWithFirstData]);

  useEffect(() => {
    if (!shouldAnimate) {
      setTimeout(() => {
        setShouldAnimate(true);
      }, 10);
    }
  }, [shouldAnimate]);

  return (
    <div className={styles.summaryWrapper} ref={summaryWrapperRef}>
      <div
        className={classNames(styles.summaryItem, {
          [styles.currentSummaryItem]: prevIsDifference && shouldAnimate,
          [styles.prevSummaryItem]: isLoadingWithFirstData
        })}
        ref={summaryItemRef}
      >
        {isLoading ? <GeneratingSummaryLoader /> : <SummaryContent data={data} onClose={onCloseClick} />}
      </div>
      {(prevIsDifference || isLoadingWithFirstData) && (
        <div
          className={classNames(styles.summaryItem, {
            [styles.prevSummaryItem]: shouldAnimate && !isLoadingWithFirstData,
            [styles.currentSummaryItem]: isLoadingWithFirstData
          })}
          ref={summaryPrevItemRef}
        >
          <SummaryContent data={isLoadingWithFirstData ? data : prevData} onClose={onCloseClick} />
        </div>
      )}
    </div>
  );
};

export default SummaryItem;
