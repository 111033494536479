import ForumIcon from '@mui/icons-material/Forum';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Card from './Card';
import styles from './styles.module.scss';

const SummaryCards = () => {
  return (
    <div className={styles.gridIcons}>
      <Card
        Icon={<TrendingUpIcon fontSize="large" />}
        title="Stock Price"
        data={[
          {
            up: { stock: '$MCD', value: '5%' },
            down: { stock: '$KO', value: '-2%' }
          },
          {
            up: { stock: '$MMM', value: '4%' },
            down: { stock: '$GME ', value: '-1.5%' }
          }
        ]}
      />
      <Card
        Icon={<ForumIcon fontSize="large" />}
        title="Mentions"
        data={[
          {
            up: { stock: '$AMC', value: '11%' },
            down: { stock: '$TSLA', value: '-12%' }
          },
          {
            up: { stock: '$CVS', value: '9%' },
            down: { stock: '$NNDM', value: '-10%' }
          }
        ]}
      />
      <Card
        Icon={<SentimentSatisfiedIcon fontSize="large" />}
        title="Sentiment"
        data={[
          {
            up: { stock: '$TLRY', value: '14%' },
            down: { stock: '$NNE', value: '-5%' }
          },
          {
            up: { stock: '$OKLO', value: '12.30%' },
            down: { stock: '$MARA', value: '-6%' }
          }
        ]}
      />
      <Card
        Icon={<SmsFailedIcon fontSize="large" />}
        title="Alerts"
        data={[
          {
            up: { stock: '$AAPL', value: '2%' },
            down: { stock: '$AMZN', value: '-4%' }
          },
          {
            up: { stock: '$ANF', value: '1%' },
            down: { stock: '$BITF', value: '-3%' }
          }
        ]}
      />
    </div>
  );
};

export default SummaryCards;
