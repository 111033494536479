import WithoutBotsAndRobots from 'pages/HomePage/StickyFilters/WithoutBotsAndRobots';
import React, { useCallback } from 'react';
import { getSelectedTimeZone } from 'store/slices/authSlice';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import useDateFilters from '../../../hooks/useDateFilters';
import { DateFilter, SourceFilters } from '../../../modules/index';
import {
  getRangeOpenState,
  setDateRangeFilter,
  setOpenRangeState,
  setRangeFilterType
} from '../../../store/slices/filtersSlice';
import { FiltersDateRangeType } from '../../../types';
import { getStaticTimeRange } from '../../../utils';
import useStocksFilter from './hooks/useStocksFilter';
import styles from './styles.module.scss';

const CompetitiveAnalysisFilters = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getRangeOpenState);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);

  const onDateFilterBtnClick = useCallback(
    (filterType: FiltersDateRangeType) => {
      const result = getStaticTimeRange(filterType, selectedTimeZone);

      if (filterType === FiltersDateRangeType.range) {
        dispatch(setRangeFilterType(filterType));
        dispatch(setOpenRangeState(!isOpen));
        return;
      }

      dispatch(setOpenRangeState(false));
      dispatch(setDateRangeFilter({ type: filterType, ...result }));
    },
    [dispatch, isOpen]
  );
  const onClose = () => {
    dispatch(setOpenRangeState(false));
  };
  const { dataSourceConfig } = useStocksFilter();
  const { filterConfig, initialRange, minDate } = useDateFilters({ onClick: onDateFilterBtnClick, onClose });
  return (
    <div className={styles.wrapper}>
      <SourceFilters data={dataSourceConfig} isLoading={false} />
      <div>
        <WithoutBotsAndRobots />
      </div>
      <DateFilter
        className={styles.dateWrapper}
        filterConfig={filterConfig}
        initialRange={initialRange}
        minDate={minDate}
      />
    </div>
  );
};
export default CompetitiveAnalysisFilters;
