import * as React from 'react';
import { SVGProps } from 'react';

export const SmallBarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M8 13.333V6.666m4 6.667V2.667M4 13.333v-2.667"
    />
  </svg>
);
