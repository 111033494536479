import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyDataImage from '../../resources/images/empty-box.png';
import styles from './styles.module.scss';

export const NoData: React.FC<{ message?: string; maxImgHeight?: number }> = ({ message, maxImgHeight }) => {
  const { t } = useTranslation();

  const { width, height } = useMemo(() => {
    if (!maxImgHeight) {
      return { width: 256, height: 256 };
    }

    return {
      height: maxImgHeight,
      width: 'auto'
    };
  }, [maxImgHeight]);

  return (
    <div className={styles.messageWrapper}>
      <img src={EmptyDataImage} width={width} height={height} alt="No data" />
      <p className={styles.message}>{message || t('errors.noData')}</p>
    </div>
  );
};
