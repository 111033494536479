import { AppButton, ButtonsType, Popup } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { required } from 'services/InputFIeldsValidator/rules';
import ToastifyService from 'services/ToastifyService';
import { IResponderDisclaimer } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface ICreateDisclaimerPopupProps {
  initialData?: IResponderDisclaimer;
  stock: string;
  onClose: () => void;
}

interface FormValues {
  text: string;
  imageUri: string;
}

const CreateConnectionPopup: React.FC<ICreateDisclaimerPopupProps> = (props) => {
  const { initialData, onClose, stock } = props;
  const [imageFile, setImageFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
    watch,
    setValue
  } = useForm<FormValues>();

  useEffect(() => {
    register('imageUri', { required: 'Image is required' });
  }, []);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    setValue('imageUri', initialData.imageUri);
    setValue('text', initialData.text);
  }, [initialData]);

  const onFileLogoChange = (file: File) => {
    if (!file) {
      return;
    }

    setImageFile(file);
    setValue('imageUri', URL.createObjectURL(file));
    clearErrors('imageUri');
  };

  const [loading, setLoading] = useState(false);

  const onCreate = async (data: FormValues) => {
    setLoading(true);

    try {
      const { text } = data;
      const formData = new FormData();

      if (!initialData) {
        formData.append('text', text);
        formData.append('image', imageFile!);
        formData.append('stock', stock);
      } else {
        formData.append('stock', stock);

        if (imageFile) {
          formData.append('image', imageFile!);
        }

        formData.append('text', text);
      }

      const res = await fetchWithConfig({
        url: API_ROUTES.RESPONDER_DISCLAIMERS,
        method: initialData ? 'PATCH' : 'POST',
        data: formData
      });

      setLoading(false);
      ToastifyService.setToast(res?.message as string, 'success');
      onClose();
    } catch (error: any) {
      catchRequestErrors(
        error,
        () => {},
        () => {},
        setLoading
      );
    }
  };

  const disclaimerImage = imageFile ? URL.createObjectURL(imageFile) : initialData?.imageUri;

  return (
    <Popup size="small" title={initialData ? 'Edit Disclaimer' : 'Create disclaimer'} onClose={onClose}>
      <>
        <div>
          <div className={styles.field}>
            {disclaimerImage && (
              <div className={styles.disclaimerImg}>
                <img src={disclaimerImage} alt="Disclaimer" />
              </div>
            )}
            <AppButton
              btnType={ButtonsType.outlined}
              label={disclaimerImage ? 'Change image' : 'Add image'}
              isInputFile
              onFileChange={onFileLogoChange}
            />
            {errors.imageUri && <span className={styles.error}>{errors.imageUri.message}</span>}
          </div>
          <div className={styles.field}>
            <InputField
              control={control}
              defaultValue=""
              name="text"
              type={InputFieldType.textarea}
              register={register}
              validate={{ required }}
              errors={errors.text}
              placeholder="Add dislaimer here"
            />
          </div>
        </div>
        <div className={styles.footerActions}>
          <AppButton
            htmlType="button"
            disabled={loading}
            btnType={ButtonsType.borderedTransparentLowBorder}
            onClick={onClose}
            label="Cancel"
          />
          <AppButton
            isLoading={loading}
            btnType={ButtonsType.simpleSave}
            htmlType="button"
            label={initialData ? 'Update' : 'Create'}
            onClick={handleSubmit(onCreate)}
          />
        </div>
      </>
    </Popup>
  );
};

export default CreateConnectionPopup;
