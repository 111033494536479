import * as React from 'react';
import { SVGProps } from 'react';

export const UserIconTrend = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.8346 12.1069V10.8568C13.8346 9.69172 13.0378 8.71278 11.9594 8.43522M9.77161 1.03722C10.6879 1.40813 11.3343 2.30648 11.3343 3.35579C11.3343 4.40511 10.6879 5.30346 9.77161 5.67437M10.7092 12.1069C10.7092 10.9419 10.7092 10.3594 10.5189 9.89994C10.2651 9.28729 9.77839 8.80054 9.16574 8.54677C8.70625 8.35645 8.12375 8.35645 6.95874 8.35645H5.0835C3.91849 8.35645 3.33599 8.35645 2.8765 8.54677C2.26385 8.80054 1.7771 9.28729 1.52333 9.89994C1.33301 10.3594 1.33301 10.9419 1.33301 12.1069M8.52144 3.35579C8.52144 4.73669 7.40201 5.85612 6.02112 5.85612C4.64023 5.85612 3.52079 4.73669 3.52079 3.35579C3.52079 1.9749 4.64023 0.855469 6.02112 0.855469C7.40201 0.855469 8.52144 1.9749 8.52144 3.35579Z"
      stroke="#F2F4F7"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
