import InputFIeldsValidator from 'services/InputFIeldsValidator';

class PasswordGenerator {
  pattern = InputFIeldsValidator.passwordPatternValue;

  getRandomChar() {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&';
    return charset.charAt(Math.floor(Math.random() * charset.length));
  }

  generate(length: number): string {
    let result = '';

    while (!this.pattern.test(result)) {
      result = Array(length).fill(null).map(this.getRandomChar).join('');
    }

    return result;
  }
}

export default new PasswordGenerator();
