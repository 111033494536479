import classNames from 'classnames';
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export enum AppLogoSize {
  medium = '148px',
  small = '140px'
}

const AppLogo: React.FC<{ size: AppLogoSize }> = ({ size }) => {
  const config = useWhiteLabelConfig();
  const { logo, alias } = config;

  return (
    <div className={classNames(styles.wrapper)}>
      <Link to={'/'}>
        <img src={logo} alt="" className={styles[alias]} style={{ width: size, height: 'auto' }} />
      </Link>
    </div>
  );
};

export default AppLogo;
