import useDataSourceOptions from 'hooks/useDataSourceOptions';
import ContentBlock from 'pages/Admin/ContentBlock';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrudActions, CrudTable, CustomSelect, EllipsisWithTooltip } from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import { Datasource, IConversationUser, ITableColumns, IUserInList } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';

const rows: ITableColumns<Partial<IConversationUser & IUserInList>>[] = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    render: (item, value) => <EllipsisWithTooltip text={value} width={60} />,
    width: 100
  },
  {
    title: 'User Name',
    dataIndex: 'userName',
    key: 'userName',
    render: (item, value) => {
      const { profileUrl } = item;
      if (profileUrl) {
        return (
          <a href={profileUrl} rel="noreferrer" target="_blank">
            {value}
          </a>
        );
      }

      return value;
    }
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    key: 'displayName'
  },
  {
    title: 'Data Source',
    dataIndex: 'dataSource',
    key: 'dataSource',
    render: (item, value, refresh, t) => {
      return t(`dataSource.${value}`);
    }
  },
  {
    title: 'Is Bot',
    dataIndex: 'isBot',
    key: 'isBot'
  },
  {
    title: 'Is Robot',
    dataIndex: 'isRobot',
    key: 'isRobot'
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return <CrudActions toEdit={`${APP_ROUTS.BLACKLIST_USERS_EDIT}/${value}`} refresh={refresh} />;
    },
    align: 'right'
  }
];

interface FormValues {
  dataSource: Datasource | 'all';
}

export const BlackListUsersList = () => {
  const { t } = useTranslation();
  const { register, control, watch } = useForm<FormValues>({
    defaultValues: { dataSource: 'all' }
  });

  const queryOptions: { isBlackList: boolean; dataSource?: Datasource } = {
    isBlackList: true
  };

  const dataSource = watch('dataSource');
  const dataSourceOptions = useDataSourceOptions();

  if (dataSource && dataSource !== 'all') {
    queryOptions.dataSource = dataSource;
  }

  return (
    <DashboardLayout
      title={t('pages.admin.usersBlackList.title')}
      createLink={<Link to={APP_ROUTS.BLACKLIST_USERS_CREATE} label={'Create'} linkType={LinkType.simple} />}
    >
      <>
        <ContentBlock>
          <CustomSelect
            name="dataSource"
            register={register}
            control={control}
            data={dataSourceOptions}
            placeholder="Data Source"
          />
        </ContentBlock>
        <CrudTable<Partial<IConversationUser>>
          columns={rows}
          requestUrl={API_ROUTES.CONVERSATION_USERS}
          queryOptions={queryOptions}
        />
      </>
    </DashboardLayout>
  );
};
