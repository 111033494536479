import { Layout } from 'components';
import { SectionCard } from 'components';
import BluresSpinner from 'components/BluresSpinner';
import CompanyInfo from 'components/CompanyInfo';
import { useAppSelector } from 'hooks';
import Heading from 'modules/Charts/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';

import RecommendedList from './components/RecommendedList';
import styles from './styles.module.scss';

const RecommendedActionsPage = () => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { _id } = user;

  if (!_id) {
    return <BluresSpinner />;
  }

  return (
    <>
      <Layout>
        <div className={styles.wrapper}>
          <CompanyInfo />
          <SectionCard noBorder>
            <>
              <div className={styles.headingWrapper}>
                <Heading title={t('pages.recommendedActions.title')} />
              </div>
              <RecommendedList />
            </>
          </SectionCard>
        </div>
      </Layout>
    </>
  );
};

export default RecommendedActionsPage;
