import { Spinner } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Datasource, IInfluencerConfig } from 'types';
import { fetchWithConfig, getDateSourceIcon } from 'utils';

import styles from './styles.module.scss';

export interface CreateInfluencerFormValues {
  influencerFollowersConfig: {
    [Datasource.Twitter]: number | string;
    [Datasource.StockTwits]: number | string;
    [Datasource.YahooFinance]: number | string;
    [Datasource.Reddit]: number | string;
    [Datasource.YouTube]: number | string;
  };
}

interface ICreateInfluencerConfigProps {
  stock?: string;
  onChange: (data: CreateInfluencerFormValues) => void;
}

const CreateInfluencerConfig: React.FC<ICreateInfluencerConfigProps> = ({ stock, onChange }) => {
  const { register, control, watch, setValue } = useForm<CreateInfluencerFormValues>();

  const allData = watch();
  const { t } = useTranslation();

  useEffect(() => {
    onChange(allData);
  }, [JSON.stringify(allData)]);

  const sources = Object.keys(Datasource)
    .filter((key) => !isNaN(Number(key)))
    .map((val) => +val);

  const { data, isLoading, isValidating } = useSWR<IInfluencerConfig>(
    stock ? `${API_ROUTES.INFLUENCER_CONFIG}/${stock}` : null,
    (url): Promise<IInfluencerConfig> => {
      return fetchWithConfig({
        url
      });
    }
  );

  useEffect(() => {
    if (!data) {
      setValue('influencerFollowersConfig', {
        [Datasource.Twitter]: '',
        [Datasource.StockTwits]: '',
        [Datasource.YahooFinance]: '',
        [Datasource.Reddit]: '',
        [Datasource.YouTube]: ''
      });
      return;
    }

    setValue('influencerFollowersConfig', data.influencerFollowersConfig);
  }, [data, setValue]);

  const regex = /^(\s*|0|[1-9][0-9]*)$/;

  return (
    <div className={styles.wrapper}>
      {stock && (isLoading || isValidating) ? (
        <Spinner staticPosition size={150} />
      ) : (
        <>
          <div className={styles.title}>Influencer Level setup</div>
          {sources.map((source) => {
            const Icon = getDateSourceIcon(source);
            return (
              <div className={styles.row} key={source}>
                <div className={styles.sourceData}>
                  <Icon />
                  <span>{t(`dataSource.${source}`)}</span>
                </div>
                <InputField
                  writePattern={regex}
                  control={control}
                  defaultValue={''}
                  register={register}
                  validate={{}}
                  errors={undefined}
                  placeholder={`Number of ${source === Datasource.YouTube ? 'Subscribers' : 'Followers'}`}
                  key={source}
                  type={InputFieldType.number}
                  name={`influencerFollowersConfig.${source}`}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default CreateInfluencerConfig;
