import React from 'react';

import LoaderIcon from './LoaderIcon';
import styles from './styles.module.scss';

const LoadingContent = () => {
  return (
    <>
      <div className={styles.iconWrapper}>
        <LoaderIcon />
      </div>
      <div className={styles.message}>
        We are generating a report for you, please <br /> wait a few seconds
      </div>
    </>
  );
};

const GeneratingSummaryLoader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <LoadingContent />
      </div>
    </div>
  );
};

export default GeneratingSummaryLoader;
