import * as React from 'react';
import { SVGProps } from 'react';

export const ResponderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.5">
      <path
        d="M4 12.2109C4 10.0892 4.84285 8.05437 6.34315 6.55408C7.84344 5.05379 9.87827 4.21094 12 4.21094C14.1217 4.21094 16.1566 5.05379 17.6569 6.55408C19.1571 8.05437 20 10.0892 20 12.2109V17.3009C20 18.1489 20 18.5709 19.874 18.9099C19.7738 19.1786 19.617 19.4225 19.4143 19.6252C19.2116 19.828 18.9676 19.9847 18.699 20.0849C18.36 20.2109 17.937 20.2109 17.09 20.2109H12C9.87827 20.2109 7.84344 19.3681 6.34315 17.8678C4.84285 16.3675 4 14.3327 4 12.2109Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M9 11.2109H15M12 15.2109H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </g>
  </svg>
);
