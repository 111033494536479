import { Skeleton } from '@mui/material';
import React from 'react';

import styles from './styles.module.scss';

const SkeletonItem = () => {
  return (
    <div className={styles.skeletonItem}>
      <div className={styles.skeletonHead}>
        <Skeleton variant="circular" height={34} width={34} />
        <Skeleton variant="text" height={34} width={100} />
      </div>
      <Skeleton variant="text" height={34} width="100%" />
      <Skeleton variant="text" height={34} width="80%" />
      <Skeleton variant="text" height={34} width="50%" />
    </div>
  );
};

export default SkeletonItem;
