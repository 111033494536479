import * as React from 'react';
import { SVGProps } from 'react';

export const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox={'0 0 20 20'} width={20} height={20} fill="none" {...props}>
      <path
        stroke={props.stroke || '#F04438'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m5 7.5 5 5 5-5"
      />
    </svg>
  );
};
