import NavItem from 'components/SidebarNav/NavItem';
import { CompetitiveIcon, HomeIcon, RecActionsIcon, ResponderIcon } from 'components/SvgComponents';
import { APP_ROUTS } from 'const';
import { useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';

import styles from './styles.module.scss';

const MainNavigation = () => {
  const { t } = useTranslation();

  const user = useAppSelector(getUser);
  const [navigation, setNavigation] = useState([
    { label: t('mainNavigation.home'), path: APP_ROUTS.HOME, Icon: HomeIcon }
  ]);

  useEffect(() => {
    if (!user.team) {
      return;
    }

    const { availablePages } = user.team;

    setNavigation((prevState) => [
      ...prevState,
      ...(availablePages.competitive
        ? [{ label: t('mainNavigation.competitive'), path: APP_ROUTS.COMPETITIVE_ANALYSIS, Icon: CompetitiveIcon }]
        : []),
      ...(availablePages.responder
        ? [{ label: t('mainNavigation.responder'), path: APP_ROUTS.RESPONDER, Icon: ResponderIcon }]
        : []),
      ...(availablePages.reports
        ? [{ label: t('mainNavigation.reports'), path: APP_ROUTS.REPORTS, Icon: ResponderIcon }]
        : [])
      // { label: t('mainNavigation.recommendedActions'), path: APP_ROUTS.RECOMMENDED_ACTIONS, Icon: RecActionsIcon }
    ]);
  }, [t, user.team]);

  if (navigation.length <= 1) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {navigation.map((item) => {
        return <NavItem key={item.path} title={item.label} to={item.path} Icon={item.Icon} />;
      })}
    </div>
  );
};
export default MainNavigation;
