import classNames from 'classnames';
import { PrPoints, SectionCard } from 'components';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone, getUser, getUserStockSymbol } from 'store/slices/authSlice';
import {
  getFilterDateRangeType,
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setLoadingState
} from 'store/slices/filtersSlice';
import useSWR from 'swr';
import { customArraySort, fetchWithConfig } from 'utils';

import ChartSpinner from '../ChartSpinner';
import MixedCharts from '../CommonCharts/MIxedCharts';
import Heading from '../Heading';
import { ChartSeriesName, IChatMarketData, IConversationSummaryItem, ILinearChartSeriesItem, IPrPoint } from '../types';
import {
  getFormattedSeries,
  getMaxAndMinFromSeries,
  getXaxisLabels,
  getXaxisTooltipLabel,
  preloaderLinearChartData,
  renderChatMarketTooltip
} from '../utils';
import CreateSummaryPopup from './CreateSummaryPopup';
import Legends from './Legends';
import SummaryItem from './SummaryItem';
import styles from './styles.module.scss';
import { ICreateSummary } from './types';

const ChartToMarket = () => {
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const filtersRangeType = useAppSelector(getFilterDateRangeType);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const user = useAppSelector(getUser);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const dispatch = useAppDispatch();
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [summaryPopupData, setSummaryPopupData] = useState<{ show: boolean; data: ICreateSummary | null }>({
    show: false,
    data: null
  });

  const [summaryActiveData, setSummaryActiveData] = useState<IConversationSummaryItem | null>(null);
  const [summaryPrevData, setSummaryPrevData] = useState<IConversationSummaryItem | null>(null);
  const [selectedLegend, setSelectedLegend] = useState<string>('');

  const { current: sseRequestId } = useRef(Date.now().toString(36) + Math.random().toString(36).substring(2));
  const [prPoints, setPrPoints] = useState<IPrPoint[]>([]);
  const { data, isLoading } = useSWR<any>(
    stockSymbol
      ? [
          `${API_ROUTES.CHART_TO_MARKET}/${stockSymbol}`,
          { from: filterFrom, to: filterTo, dataSource, withBotsAndRobots: !withoutBotsAndRobots }
        ]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  const {
    data: conversationSummaries,
    isLoading: summariesLoading,
    mutate: mutateSummaries
  } = useSWR<IConversationSummaryItem[]>(
    stockSymbol ? [`${API_ROUTES.CONVERSATION_SUMMARIES}/${stockSymbol}`, { from: filterFrom, to: filterTo }] : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig<IConversationSummaryItem[]>({
        url: options[0],
        params: options[1]
      });
    }
  );

  const chatterLoading = isLoading || summariesLoading;

  useEffect(() => {
    setSummaryActiveData(null);
    setSummaryPrevData(null);
  }, [filterTo, filterFrom, stockSymbol, filtersRangeType]);

  const renderData: IChatMarketData = useMemo(() => {
    return chatterLoading
      ? preloaderLinearChartData
      : data || { series: [], dates: [], prLinks: [], interval: '', summaries: [] };
  }, [data, chatterLoading]);

  const interval = renderData?.interval;
  const summaries = conversationSummaries || [];
  const categories = renderData?.dates?.map((item) => item) || [];
  const prData = renderData?.prLinks || [];

  const summariesMarkers = summaries
    .map((summary) => {
      const dateIndex = categories.findIndex((date) => summary.dayDate === date);
      if (dateIndex < 0) {
        return null;
      }

      return {
        seriesIndex: 1,
        dataPointIndex: dateIndex,
        fillColor: '#2b3b5b',
        strokeColor: '#fff',
        size: 7,
        shape: 'circle'
      };
    })
    .filter(Boolean);

  useEffect(() => {
    if (!user._id) {
      return;
    }

    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/conversation-summary-events/conversation-generated/${sseRequestId}`
    );
    eventSource.onmessage = ({ data }) => {
      mutateSummaries();

      setSummaryActiveData(JSON.parse(data).data.conversationSummary);
      setTimeout(() => {
        setIsGeneratingSummary(false);
      }, 1000);
    };

    return () => {
      eventSource.close();
    };
  }, [sseRequestId, user._id]);

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: chatterLoading }));
  }, [dispatch, chatterLoading]);

  const onCloseSummaryPopup = () => {
    setSummaryPopupData({ show: false, data: null });
  };

  const onMarkerClick = async (e: any, opts: any, pointData: { seriesIndex: number; dataPointIndex: number }) => {
    if (interval !== '1d') {
      return;
    }

    const { dataPointIndex, seriesIndex } = pointData;
    const onChatterMarkerClick = seriesIndex === 1;

    if (!onChatterMarkerClick || isGeneratingSummary) {
      return;
    }

    const date = renderData.dates[dataPointIndex]!;
    const summary = summaries.find(({ dayDate }) => dayDate === date);

    if (summary) {
      setSummaryPrevData(summaryActiveData);
      setSummaryActiveData(summary);
      setTimeout(() => {
        setSummaryPrevData(summary);
      }, 1000);
      return;
    }

    setSummaryPopupData({
      show: true,
      data: {
        stock: stockSymbol,
        date: DateTime.fromJSDate(new Date(date)).toISODate()!,
        dataSource: [...dataSource].sort((a: number, b: number) => a - b),
        sseRequestId
      }
    });
  };

  const onGenerateSummary = async (data: ICreateSummary) => {
    const { stock, date, dataSource, sseRequestId } = data;
    setIsGeneratingSummary(true);
    setSummaryActiveData(null);
    setSummaryPrevData(null);

    await fetchWithConfig({
      url: `${API_ROUTES.CONVERSATION_SUMMARIES}/${stockSymbol}`,
      method: 'post',
      data: {
        stock,
        date: DateTime.fromJSDate(new Date(date)).toISODate(),
        dataSource: [...dataSource].sort((a: number, b: number) => a - b),
        sseRequestId
      }
    });
  };

  const { t } = useTranslation();
  const { min: minPrice, max: maxPrice } = getMaxAndMinFromSeries(renderData.series, 'Price');
  const { max: maxChatter } = getMaxAndMinFromSeries(renderData.series, 'Chatter');

  const { show: showSummaryPopup, data: summaryCreateData } = summaryPopupData;
  const sortedArray = customArraySort(renderData?.series, ChartSeriesName.price);
  const newSeries = getFormattedSeries(sortedArray);
  const newDataLabels = renderData?.dates?.map((item: any) => {
    return item;
  });

  const options = useMemo(() => {
    return {
      stroke: {
        width: 2,
        curve: ['smooth', 'smooth', 'smooth'],
        colors: ['#1570EF', '#FF7526', '#98A2B300']
      },
      colors: ['#1570EF', '#FF7526', '#344054'],
      fill: {
        opacity: newSeries.map(({ name }: ILinearChartSeriesItem) => {
          if (!selectedLegend) {
            return 1;
          }

          if (selectedLegend === name) {
            return 1;
          }

          if (selectedLegend) {
            return 0.2;
          }

          return 1;
        }),
        colors: ['#1570EF', '#FF7526', '#344054']
      },
      markers: {
        size: 0.000001,
        discrete: !selectedLegend || selectedLegend === 'Chatter' ? summariesMarkers : [],
        hover: {
          size: 10
        }
      },
      chart: {
        id: 'chat-market-trend',
        animations: {
          enabled: false
        },
        events: {
          dataPointSelection: onMarkerClick,
          updated() {
            const linesSeriesWrapper: SVGAElement | null = document.querySelector(
              '.chat-market-wrapper .apexcharts-inner.apexcharts-graphical'
            );

            if (!prData || !linesSeriesWrapper) {
              return;
            }

            const linesSeries = [...linesSeriesWrapper.querySelectorAll('.apexcharts-xaxis-tick')];
            const pointWidth = 24;
            const prOverlay = document.querySelector('.chat-market-wrapper .pr-overlay')!;
            const newPrPoints = prData
              .map((pr) => {
                const { dayDate } = pr;
                const dateIndex = categories?.findIndex((dateVal) => {
                  return dateVal === dayDate;
                });
                if (dateIndex < 0) {
                  return null;
                }

                const htmlItem = linesSeries[dateIndex];

                if (!htmlItem) {
                  return null;
                }

                const graphicalWrapper: SVGAElement | null = document.querySelector(
                  '.chat-market-wrapper .apexcharts-inner.apexcharts-graphical'
                );

                if (prOverlay && graphicalWrapper) {
                  prOverlay.setAttribute('style', `left: ${graphicalWrapper?.getCTM()?.e}px`);
                }

                const firstLine = linesSeriesWrapper.querySelector('.apexcharts-xcrosshairs');
                const heightLine = firstLine?.getAttribute('height') || 0;

                const xLinePosition = parseFloat(htmlItem.getAttribute('x1') || '0');

                const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                line.setAttribute('x', xLinePosition.toString());
                line.setAttribute('x1', xLinePosition.toString());
                line.setAttribute('x2', xLinePosition.toString());
                line.setAttribute('y1', '0');
                line.setAttribute('y', '0');
                line.setAttribute('y2', heightLine.toString());
                line.setAttribute('width', '1');
                line.setAttribute('height', heightLine.toString());
                line.setAttribute('stroke', '#b6b6b6');
                line.setAttribute('stroke-opacity', '0.4');
                line.setAttribute('stroke-dasharray', '3');
                line.setAttribute('class', 'apexcharts-xcrosshairs apexchart-summary-crosshair');
                line.setAttribute('fill', '#b1b9c4');

                document
                  .querySelector('.chat-market-wrapper .apexcharts-inner.apexcharts-graphical')
                  ?.appendChild(line);

                const x = xLinePosition - pointWidth / 2;

                return {
                  ...pr,
                  x
                };
              })
              .filter(Boolean);

            if (JSON.stringify(prPoints) !== JSON.stringify(newPrPoints)) {
              setPrPoints(newPrPoints as IPrPoint[]);
            }
          }
        }
      },
      xaxis: {
        labels: {
          categories,
          type: 'datetime',
          formatter: function (value: any) {
            return getXaxisLabels(value, filtersRangeType, selectedTimeZone);
          },
          rotate: 45,
          rotateAlways: true,
          offsetY: 30,
          offsetX: 10,
          style: {
            colors: renderData?.dates?.map((item: any) => {
              if (summaryActiveData?.dayDate === item) {
                return '#fff';
              }
              return '#98A2B3';
            })
          }
        },
        tooltip: {
          formatter: function (value: any, data: any) {
            return getXaxisTooltipLabel(value, data, selectedTimeZone);
          }
        }
      },
      yaxis: [
        {
          tooltip: {
            enabled: true
          },
          title: {
            text: t('charts.labels.price'),
            style: {
              color: '#1570EF'
            }
          },
          max: maxPrice,
          min: minPrice,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(2);
            }
          }
        },
        {
          tooltip: {
            enabled: true
          },
          opposite: true,
          title: {
            text: t('charts.labels.chatter'),
            style: {
              color: '#FF7526'
            }
          },
          max: maxChatter,
          min: 0,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(0);
            }
          }
        }
      ],
      legend: {
        show: false
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return renderChatMarketTooltip(
            { series, seriesIndex, dataPointIndex, w, interval },
            styles,
            selectedTimeZone
          );
        }
      }
    };
  }, [categories, filtersRangeType, maxPrice, minPrice, selectedTimeZone, selectedLegend, t]);

  return (
    <div className={styles.componentWrapper}>
      <SectionCard>
        <>
          <div className={styles.heading}>
            <div className={styles.headingText}>
              <Heading title={t('charts.chartToMarket')} />
              {interval === '1d' && (
                <div className={styles.clickSectionMessage}>
                  Click on a section of the graph to get a generated report of what users were writing about at that
                  time
                </div>
              )}
            </div>
            <Legends activeLegend={selectedLegend} onChangeLegend={setSelectedLegend} />
          </div>
          <div className={styles.wrapper}>
            <div
              className={classNames('chat-market-wrapper', styles.chartWrapper, {
                [styles.chatterWithClick]: interval === '1d'
              })}
            >
              <MixedCharts
                series={newSeries}
                labels={newDataLabels}
                /*@ts-ignore*/
                options={options}
                height={460}
              />
              <PrPoints points={prPoints} customClassName="pr-overlay" />
            </div>
            {(summaryActiveData || isGeneratingSummary) && (
              <SummaryItem
                isLoading={isGeneratingSummary}
                data={summaryActiveData}
                prevData={summaryPrevData}
                onClose={() => {
                  setSummaryActiveData(null);
                  setSummaryPrevData(null);
                }}
              />
            )}
          </div>
          {chatterLoading && <ChartSpinner />}
        </>
      </SectionCard>
      {showSummaryPopup && (
        <CreateSummaryPopup onClose={onCloseSummaryPopup} data={summaryCreateData!} onSubmit={onGenerateSummary} />
      )}
    </div>
  );
};

export default React.memo(ChartToMarket);
