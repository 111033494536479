import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Datasource } from 'types';

const useDataSourceOptions = () => {
  const { t } = useTranslation();

  const dataSourceOptions = useMemo(() => {
    return [
      { _id: 'all', name: 'All' },
      ...Object.keys(Datasource)
        .filter((item) => !isNaN(+item))
        .map((item) => ({
          _id: item,
          name: t(`dataSource.${item}`)
        }))
    ];
  }, [t]);

  return dataSourceOptions;
};

export default useDataSourceOptions;
