import classNames from 'classnames';
import React from 'react';
import { IReport } from 'types';

import styles from './styles.module.scss';

interface IReportStatusProps {
  report: IReport;
}

const ReportStatus: React.FC<IReportStatusProps> = ({ report }) => {
  const { status, archived } = report;
  const statusValue = archived ? 'archived' : status;
  return <span className={classNames(styles.status, styles[statusValue])}>{statusValue}</span>;
};

export default ReportStatus;
