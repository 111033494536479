import * as React from 'react';
import { SVGProps } from 'react';

export const ActionCollectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.5" clipPath="url(#clip0_1894_1638)">
      <path
        d="M28.5 15H7.5C5.8455 15 4.5 16.3455 4.5 18V30C4.5 31.6545 5.8455 33 7.5 33H28.5C30.1545 33 31.5 31.6545 31.5 30V18C31.5 16.3455 30.1545 15 28.5 15ZM7.5 30V18H28.5L28.503 30H7.5ZM7.5 9H28.5V12H7.5V9ZM10.5 3H25.5V6H10.5V3Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1894_1638">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
