import InputField, { InputFieldType } from 'components/InputField';
import useDelayedWatch from 'hooks/useDelayedWatch';
import { DateTime } from 'luxon';
import ContentBlock from 'pages/Admin/ContentBlock';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrudActions, CrudTable, EllipsisWithTooltip, Title, TitleType } from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import { ITableColumns, IUser, IUserInList } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';

const rows: ITableColumns<Partial<IUser & IUserInList>>[] = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    render: (item, value) => <EllipsisWithTooltip text={value} width={60} />,
    width: 100
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('dd/MM/yyyy')}</span>;
    }
  },
  {
    title: 'Last activity',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('dd/MM/yyyy')}</span>;
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sortable: true,
    render: (item, value) => {
      return (
        <a href={`mailto:${value}`} target={'_blank'} rel={'noopener noreferrer'}>
          {value}
        </a>
      );
    }
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return (
        <CrudActions
          toDetails={`${APP_ROUTS.USERS}/${value}`}
          toEdit={`${APP_ROUTS.USERS_EDIT}/${value}`}
          onRemoveUrl={`${API_ROUTES.USERS}/${value}`}
          dialogTitle={
            <div>
              <Title type={TitleType.h3}>
                <>Confirm delete user.</>
              </Title>
              <Title type={TitleType.h4}>
                <>User ID: {value}</>
              </Title>
              <Title type={TitleType.h5}>
                <> User email: {item.email}</>
              </Title>
            </div>
          }
          refresh={refresh}
        />
      );
    },
    align: 'right'
  }
];

type FilterValues = {
  email: string;
};

export const UsersList = () => {
  const { t } = useTranslation();

  const { register, control, watch } = useForm<FilterValues>({
    defaultValues: { email: '' }
  });

  const delayedValues = useDelayedWatch(watch, ['email'], 1000);
  const queryOptions = useMemo(() => {
    const options: { email?: string } = {};

    (Object.keys(delayedValues) as Array<'email'>).forEach((key) => {
      if (delayedValues[key]) {
        options[key] = delayedValues[key];
      }
    });

    return options;
  }, [delayedValues]);

  return (
    <DashboardLayout
      title={t('pages.admin.users')}
      createLink={<Link to={APP_ROUTS.USERS_CREATE} label={'create'} linkType={LinkType.simple} />}
    >
      <>
        <ContentBlock>
          <InputField
            control={control}
            name="email"
            defaultValue=""
            errors={undefined}
            placeholder="Enter an email"
            register={register}
            type={InputFieldType.text}
            validate={{}}
          />
        </ContentBlock>
        <CrudTable<Partial<IUser>> columns={rows} requestUrl={API_ROUTES.USERS} queryOptions={queryOptions} />
      </>
    </DashboardLayout>
  );
};
