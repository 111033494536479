import { Checkbox } from '@mui/material';
import { APP_ROUTS, TOKEN_KEY } from 'const';
import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { mailRule, required } from 'services/InputFIeldsValidator/rules';
import { initDateRangeFilter } from 'store/slices/filtersSlice';
import { IUser } from 'types';
import { fetchWithConfig } from 'utils';

import { authUser } from '../../../../store/slices/authSlice';
import AppButton, { ButtonsType } from '../../../AppButton';
import InputField, { InputFieldType } from '../../../InputField';
import Link, { LinkType } from '../../../Link';
import { EyeIcon, LockIcon, MailIcon, VisibilityOfIcon } from '../../../SvgComponents';
import styles from './styles.module.scss';

type FormValues = {
  email: string;
  password: string;
  confirmPassword?: string;
};

const LoginForm: React.FC<{ fetchRout: string }> = ({ fetchRout }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || APP_ROUTS.HOME;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues
  } = useForm<FormValues>();
  const [showPass, setShow] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');
  const isLogin = useMatch(APP_ROUTS.LOGIN);

  const { t } = useTranslation();
  const RightIcon = showPass ? VisibilityOfIcon : EyeIcon;

  const onRightIconClick = () => {
    setShow(!showPass);
  };
  const onSubmit = async (data: FormValues) => {
    setGlobalError('');
    try {
      setLoading(true);
      const responseData = await fetchWithConfig<{
        accessToken: string;
        user: IUser;
      }>({
        url: fetchRout,
        method: 'POST',
        data: {
          email: data.email,
          password: data.password
        }
      });

      if (checked) {
        localStorage.setItem(TOKEN_KEY, responseData.accessToken);
      }

      dispatch(authUser({ ...responseData.user, accessToken: responseData.accessToken }));
      dispatch(initDateRangeFilter(responseData.user.timeZone));
      setLoading(false);
      navigate(from, { replace: true });
    } catch (error: any) {
      const errorResponse = error?.response?.data?.error;

      if (Array.isArray(errorResponse?.data) || Array.isArray(error?.response?.data?.error?.data)) {
        errorResponse.data.forEach((err: any) => {
          setError(err.field, {
            type: 'manual',
            message: err.error
          });
        });
      }
      if (typeof errorResponse?.data === 'string') {
        setGlobalError(errorResponse?.data);
      }
      setLoading(false);
    }
  };

  const onCheckboxChange = () => {
    setChecked(!checked);
  };

  const onInputFocus = () => {
    setGlobalError('');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <div className={styles.box}>
        <InputField
          onFocus={onInputFocus}
          label={t('auth.emailLabel')}
          control={control}
          defaultValue={''}
          name={'email'}
          type={InputFieldType.email}
          register={register}
          placeholder={t('auth.emailPlaceholder')}
          validateText={t('errors.notValidEmail')}
          LeftIcon={MailIcon}
          validate={{
            mailRule,
            required
          }}
          errors={errors.email}
        />
      </div>
      <div className={styles.box}>
        <InputField
          onFocus={onInputFocus}
          label={t('auth.passLabel')}
          control={control}
          defaultValue={''}
          name={'password'}
          type={showPass ? InputFieldType.text : InputFieldType.password}
          register={register}
          placeholder={t('auth.pathPlaceholder')}
          validateText={t('errors.requiredField')}
          LeftIcon={LockIcon}
          RightIcon={RightIcon}
          onRightIconClick={onRightIconClick}
          validate={{
            required
          }}
          errors={errors.password}
        />
      </div>
      {!!globalError && <div className={styles.error}>{globalError}</div>}
      <div className={styles.chexBoxRow}>
        {isLogin && (
          <>
            <label>
              <Checkbox checked={checked} onChange={onCheckboxChange} />
              <span>{t('auth.remember')}</span>
            </label>
            <div>
              <Link to={APP_ROUTS.FORGOT_PASS} label={t('auth.forgotPass')} linkType={LinkType.simple} />
            </div>
          </>
        )}
      </div>
      <AppButton
        disabled={Boolean(errors.email || errors.password)}
        isLoading={loading}
        label={t('auth.signIn')}
        btnType={ButtonsType.signIn}
      />
    </form>
  );
};

export default LoginForm;
