import * as React from 'react';
import { SVGProps } from 'react';

export const RedditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g>
      <path
        fill="#FC471E"
        d="M10 1.25c-4.826 0-8.75 3.924-8.75 8.75 0 4.826 3.924 8.75 8.75 8.75 4.826 0 8.75-3.924 8.75-8.75 0-4.826-3.924-8.75-8.75-8.75Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.512 5.568a.949.949 0 0 0 .925 1.114c.518 0 .938-.43.938-.96s-.42-.96-.938-.96a.921.921 0 0 0-.605.228l-2.434-.615-.88 3.603c-1.17.079-2.224.442-3.018.99a1.231 1.231 0 0 0-.875-.366c-.69 0-1.25.573-1.25 1.28 0 .491.271.918.669 1.133A2.518 2.518 0 0 0 5 11.48C5 13.425 7.239 15 10 15s5-1.575 5-3.519c0-.158-.015-.314-.043-.466.397-.215.668-.642.668-1.134 0-.706-.56-1.28-1.25-1.28-.34 0-.65.14-.875.367-.865-.597-2.038-.974-3.335-1.004l.687-2.813 1.66.417Zm-4.7 6.233c.518 0 .938-.43.938-.96s-.42-.96-.938-.96a.949.949 0 0 0-.937.96c0 .53.42.96.938.96Zm4.375 0c.518 0 .938-.43.938-.96s-.42-.96-.938-.96a.949.949 0 0 0-.937.96c0 .53.42.96.938.96Zm-4.201 1.013a.308.308 0 0 0-.434.09.325.325 0 0 0 .087.443c.68.464 1.52.696 2.361.696.84 0 1.68-.232 2.36-.696a.325.325 0 0 0 .088-.444.308.308 0 0 0-.434-.089c-.575.393-1.294.59-2.014.59-.427 0-.853-.07-1.25-.208a3.249 3.249 0 0 1-.764-.382Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
