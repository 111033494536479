import { Spinner } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { IRedditSourceConfig } from 'types';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

export interface CreateRedditSourcesFormValues {
  stockSubReddit: string;
  generalSubReddit: string;
  generalSubRedditQuery: string;
  mainQuery: string;
  mainSubRedditQuery: string;
}

interface ICreateRedditSourcesProps {
  stock?: string;
  onChange: (data: CreateRedditSourcesFormValues) => void;
}

const CreateRedditSources: React.FC<ICreateRedditSourcesProps> = ({ stock, onChange }) => {
  const { register, control, watch, setValue } = useForm<CreateRedditSourcesFormValues>();

  const allData = watch();

  useEffect(() => {
    onChange(allData);
  }, [JSON.stringify(allData)]);

  const writePatternCommas = /^(?!.*,,).*$/;

  const { data, isLoading, isValidating } = useSWR<IRedditSourceConfig>(
    stock ? `${API_ROUTES.REDDIT_SOURCE_CONFIG}/${stock}` : null,
    (url): Promise<IRedditSourceConfig> => {
      return fetchWithConfig({
        url
      });
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setValue('stockSubReddit', data.stockSubReddit.join(', '));
    setValue('generalSubReddit', data.generalSubReddit.join(', '));
    setValue('generalSubRedditQuery', data.generalSubRedditQuery);
    setValue('mainQuery', data.mainQuery);
    setValue('mainSubRedditQuery', data.mainSubRedditQuery);
  }, [data, setValue]);

  return (
    <div className={styles.wrapper}>
      {stock && (isLoading || isValidating) ? (
        <Spinner staticPosition size={150} />
      ) : (
        <>
          <div className={styles.title}>Reddit Sources</div>
          <div className={styles.row}>
            <InputField
              label="Stock Subreddit"
              writePattern={writePatternCommas}
              control={control}
              defaultValue={''}
              name="stockSubReddit"
              type={InputFieldType.text}
              register={register}
              placeholder="sub1, sub2, sub3"
              validate={{}}
              errors={undefined}
            />
          </div>
          <div className={styles.rows}>
            <div className={styles.row}>
              <InputField
                label="General Subreddit"
                writePattern={writePatternCommas}
                control={control}
                defaultValue={''}
                name="generalSubReddit"
                type={InputFieldType.text}
                register={register}
                placeholder="sub1, sub2, sub3"
                validate={{}}
                errors={undefined}
              />
            </div>
            <div className={styles.row}>
              <InputField
                label="General Subreddit Query"
                control={control}
                defaultValue={''}
                name="mainQuery"
                type={InputFieldType.text}
                register={register}
                placeholder="Contain 'stock'"
                validate={{}}
                errors={undefined}
              />
            </div>
          </div>
          <div className={styles.rows}>
            <div className={styles.row}>
              <InputField
                label="Main Query"
                control={control}
                defaultValue={''}
                name="generalSubRedditQuery"
                type={InputFieldType.text}
                register={register}
                placeholder="$3M"
                validate={{}}
                errors={undefined}
              />
            </div>
            <div className={styles.row}>
              <InputField
                label="Main Subreddit Query"
                control={control}
                defaultValue={''}
                name="mainSubRedditQuery"
                type={InputFieldType.text}
                register={register}
                placeholder="Stock or ..."
                validate={{}}
                errors={undefined}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateRedditSources;
