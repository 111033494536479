import React, { JSX } from 'react';

import { Datasource } from '../../../types';
import DataSourceTagItem from './SourceTagItem';
import styles from './styles.module.scss';

type SourceFiltersType = {
  data: {
    Icon?: JSX.Element;
    title: string;
    counter?: number;
    counters?: Record<string, number>;
    dataSource: Datasource | string;
    onClick: () => void;
    isActive: boolean;
    disabled?: boolean;
  }[];
  isLoading: boolean;
};

export const SourceFilters: React.FC<SourceFiltersType> = ({ data, isLoading }) => {
  return (
    <div className={styles.wrapper}>
      {data.map((item, index) => {
        const Icon = item.Icon;
        return (
          <DataSourceTagItem
            title={item.title}
            disabled={item.disabled}
            dataSource={item.dataSource}
            Icon={Icon}
            counter={item?.counter}
            counters={item?.counters}
            key={`${item.title}_${index}`}
            loading={isLoading}
            isActive={item.isActive}
            onClick={item.onClick}
          />
        );
      })}
    </div>
  );
};
