import * as React from 'react';
import { SVGProps } from 'react';

export const YahooFinance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="svg8"
    width={20}
    height={20}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 4266.667 4266.667"
    {...props}
  >
    <defs id="defs12">
      <linearGradient id="linearGradient891-4">
        <stop
          id="stop887"
          offset={0}
          style={{
            stopColor: '#6001d1',
            stopOpacity: 1
          }}
        />
        <stop
          id="stop889"
          offset={1}
          style={{
            stopColor: '#7859ff',
            stopOpacity: 1
          }}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#linearGradient891-4"
        id="linearGradient902"
        x1={782.688}
        x2={-3483.979}
        y1={3436.962}
        y2={-829.704}
        gradientTransform="translate(3483.979 829.704)"
        gradientUnits="userSpaceOnUse"
      />
    </defs>
    <style id="style16" type="text/css">
      {'.st0{fill:#5f01d1}'}
    </style>
    <g id="g974">
      <rect
        id="rect46"
        width={4266.667}
        height={4266.667}
        x={0}
        y={0}
        ry={940}
        style={{
          fill: 'url(#linearGradient902)',
          fillOpacity: 1,
          fillRule: 'evenodd',
          strokeWidth: 0
        }}
      />
      <g id="g77">
        <path
          id="path1139"
          d="M743.7 3199.475h168.046l97.85 254.671 99.122-254.671h163.62l-246.376 602.89H861.306l67.44-159.756z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path1141"
          d="M1442.786 3189.336c-126.26 0-206.078 115.195-206.078 229.91 0 129.089 87.51 231.433 203.683 231.433 86.66 0 119.336-53.712 119.336-53.712v41.84h146.568v-439.33h-146.568v39.94s-36.455-50.08-116.94-50.08zm31.176 141.184c58.258 0 88.32 46.895 88.32 89.209 0 45.564-32.207 90.28-88.32 90.28-46.506 0-88.532-38.663-88.532-88.34 0-50.381 33.804-91.15 88.532-91.15z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path1145"
          d="M1756.772 3638.805v-633.32h153.301v235.453s36.413-51.547 112.674-51.547c93.284 0 147.938 70.706 147.938 171.742v277.672h-152.18V3399.17c0-34.193-16.01-67.224-52.276-67.224-36.917 0-56.156 33.532-56.156 67.224v239.635z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path1147"
          d="M2432.058 3189.388c-144.592 0-230.69 111.85-230.69 231.686 0 136.375 104.245 229.605 231.237 229.605 123.087 0 230.792-88.999 230.792-227.317 0-151.345-112.77-233.974-231.34-233.974zm1.38 142.425c51.076 0 86.415 43.275 86.415 89.424 0 39.361-32.93 87.91-86.414 87.91-49.008 0-85.787-39.992-85.787-88.34 0-46.575 30.578-88.994 85.787-88.994z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path1153"
          d="M2918.79 3189.388c-144.592 0-230.69 111.85-230.69 231.686 0 136.375 104.245 229.605 231.237 229.605 123.087 0 230.792-88.999 230.792-227.317 0-151.345-112.77-233.974-231.34-233.974zm1.38 142.425c51.075 0 86.415 43.275 86.415 89.424 0 39.361-32.93 87.91-86.415 87.91-49.008 0-85.786-39.992-85.786-88.34 0-46.575 30.577-88.994 85.786-88.994z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <ellipse
          id="path1155"
          cx={3271.094}
          cy={3544.574}
          rx={101.798}
          ry={103.558}
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.950688,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path1157"
          d="M3406.342 3402.974h-183.263l162.649-397.49h182.578z"
          style={{
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 0.942813,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2.6131,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
      </g>
      <g id="g960">
        <path
          id="rect79"
          d="m788.303 2298.258 925.12-779.913 623.816 533.936 1142.196-954.023v1448.145H788.303Z"
          style={{
            opacity: 0.99,
            fill: '#fff',
            strokeWidth: 10.0911
          }}
        />
        <path
          id="rect123"
          d="M347.943 59.826 233.725 155.23l-62.383-53.395-92.512 77.992v22l92.512-77.992 62.383 53.395 114.218-95.403z"
          style={{
            opacity: 0.99,
            fill: '#afe400',
            strokeWidth: 1,
            fillOpacity: 1
          }}
          transform="scale(10)"
        />
      </g>
    </g>
  </svg>
);
