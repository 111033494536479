import * as React from 'react';
import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 20 20'} width={20} height={20} fill="none" {...props}>
    <path
      fill="#636C79"
      fillRule="evenodd"
      d="M10 2.502a3.332 3.332 0 0 0-3.332 3.331v1.665h6.664V5.833A3.332 3.332 0 0 0 10 2.502Zm5.002 4.996V5.833a5.002 5.002 0 1 0-10.004 0v1.665H4.5a2.835 2.835 0 0 0-2.835 2.835v6A2.835 2.835 0 0 0 4.5 19.168h11a2.835 2.835 0 0 0 2.835-2.835v-6A2.835 2.835 0 0 0 15.5 7.498h-.498Zm-.835 1.67H4.5c-.643 0-1.165.522-1.165 1.165v6c0 .644.522 1.165 1.165 1.165h11c.643 0 1.165-.521 1.165-1.165v-6c0-.643-.521-1.165-1.165-1.165h-1.333ZM10 11.665c.461 0 .835.374.835.835v1.667a.835.835 0 0 1-1.67 0V12.5c0-.461.374-.835.835-.835Z"
      clipRule="evenodd"
    />
  </svg>
);
