import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCard, SectionCardHeading } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getTopUsersTabsState,
  setTabFilters,
  setTopActiveUserId,
  updateRequestedListPage
} from '../../../store/slices/filtersSlice';
import { TabConfig, UserFilter } from '../../../types';
import { TabFilters } from '../../index';
import UsersList from './UsersList';
import styles from './styles.module.scss';

const TopUsers = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { value } = useAppSelector(getTopUsersTabsState);

  const tabConfig: TabConfig[] = useMemo(() => {
    return [
      {
        name: t('users.followers'),
        isActive: value === UserFilter.followers,
        toggleFn: () => {
          dispatch(setTopActiveUserId(null));
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'usersFilters' }));
          dispatch(setTabFilters({ keyName: 'usersFilters', value: UserFilter.followers }));
        }
      },
      {
        name: t('users.impression'),
        isActive: value === UserFilter.impression,
        toggleFn: () => {
          dispatch(setTopActiveUserId(null));
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'usersFilters' }));
          dispatch(setTabFilters({ keyName: 'usersFilters', value: UserFilter.impression }));
        }
      }
    ];
  }, [dispatch, t, value]);

  return (
    <SectionCard nativeClassName={styles.flexGrow}>
      <>
        <SectionCardHeading title={t('users.pageTitle')} />
        <TabFilters data={tabConfig} />
        <UsersList />
      </>
    </SectionCard>
  );
};

export default TopUsers;
