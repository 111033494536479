import { useEffect, useState } from 'react';
import { FieldValues, Path, UseFormWatch } from 'react-hook-form';

type DelayedValues<T> = {
  [K in keyof T]: T[K];
};

function useDelayedWatch<T extends FieldValues>(
  watch: UseFormWatch<T>,
  fields: Path<T>[],
  delay = 500
): DelayedValues<T> {
  const [delayedValues, setDelayedValues] = useState<DelayedValues<T>>(() =>
    fields.reduce((acc, field) => ({ ...acc, [field]: watch(field) }), {} as DelayedValues<T>)
  );

  useEffect(() => {
    const timeouts = fields.map((field) => {
      const value = watch(field);

      return setTimeout(() => {
        setDelayedValues((prev) => ({ ...prev, [field]: value }));
      }, delay);
    });

    return () => timeouts.forEach(clearTimeout);
  }, [fields.map((field) => watch(field)).toString(), delay]);

  return delayedValues;
}

export default useDelayedWatch;
