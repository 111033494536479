import { useAppSelector } from 'hooks';
import useSWR from 'swr';
import { IShortCompetitiveStock } from 'types';

import { API_ROUTES } from '../const';
import { getUserCompetitiveStocks, getUserStockSymbol } from '../store/slices/authSlice';
import { fetchWithConfig } from '../utils';

const useCompetitiveStocks = () => {
  const competitiveStocks = useAppSelector(getUserCompetitiveStocks);
  const mainStock = useAppSelector(getUserStockSymbol);

  const { data, error, isLoading } = useSWR<IShortCompetitiveStock[]>(
    `${API_ROUTES.STOCKS_COMPETITIVE_SHORT_INFO}?stocks=${[...competitiveStocks, mainStock].join(',')}`,
    (url) => fetchWithConfig({ url })
  );

  const mainStockDefaultInfo = { symbol: mainStock, name: mainStock, image: '' };

  if (!data) {
    return {
      competitiveStocks: [],
      mainStock: mainStockDefaultInfo,
      loading: isLoading
    };
  }

  const mainStockInfo = data.find(({ symbol }) => symbol === mainStock);
  const competitiveStocksInfo = data.filter(({ symbol }) => symbol !== mainStock);

  return {
    competitiveStocks: competitiveStocksInfo,
    mainStock: mainStockInfo || mainStockDefaultInfo,
    loading: isLoading
  };
};

export default useCompetitiveStocks;
