import { AppButton, BlurredSpinner, ButtonsType, CheckboxComponent, CustomSelect } from 'components';
import { FormErrorsLabel } from 'components/FormErrorsLabel';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import useTeams from 'hooks/useTeams';
import ContentBlock from 'pages/Admin/ContentBlock';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { passwordRule, required } from 'services/InputFIeldsValidator/rules';
import PasswordGenerator from 'services/PasswordGenerator';
import ToastifyService from 'services/ToastifyService';
import useSWR from 'swr';
import { ITeam, IUser } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import DashboardLayout from '../../DashboardLayout';
import styles from '../styles.module.scss';

type FormValues = {
  teamId: string;
  mailingList: {
    alerts: boolean;
  };
  email: string;
  password?: string;
};

export const UserCreateEdit = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { teamsData, teamsLoading } = useTeams();

  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');
  const { data, error, isLoading, mutate } = useSWR<IUser>(
    userId ? `${API_ROUTES.USERS}/${userId}` : null,
    (url): Promise<IUser> => {
      return fetchWithConfig({
        url
      });
    }
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    setValue
  } = useForm<FormValues>({
    mode: 'all'
  });

  const onSubmit = async (data: FormValues) => {
    setGlobalError('');
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: userId ? `${API_ROUTES.USERS}/${userId}` : API_ROUTES.USERS,
        method: userId ? 'PATCH' : 'POST',
        data: {
          teamId: data?.teamId,
          mailingList: data.mailingList,
          email: data.email,
          password: data?.password
        }
      });

      ToastifyService.setToast(`User ${userId ? 'updated' : 'created'} succesfuly`, 'success');
      setLoading(false);
      await mutate();
    } catch (error: any) {
      catchRequestErrors(error, setError, setGlobalError, setLoading);
    }
  };

  const onGeneratePassword = () => {
    setError('password', {});
    setValue('password', PasswordGenerator.generate(8));
  };

  return (
    <DashboardLayout
      title={userId ? data?.email || t('pages.admin.usersEdit') : t('pages.admin.usersCreate')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton
          disabled={Boolean(errors.teamId)}
          isLoading={loading}
          label={userId ? 'Update' : 'Create'}
          btnType={ButtonsType.signIn}
        />
      }
    >
      {isLoading && userId ? (
        <BlurredSpinner />
      ) : (
        <div>
          {!userId && (
            <>
              <ContentBlock>
                <div className={styles.row}>
                  <div className={styles.labelWrapper}>
                    <p className={styles.label}>Email</p>
                  </div>
                  <div className={styles.labelRight}>
                    <InputField
                      control={control}
                      defaultValue=""
                      name={'email'}
                      type={InputFieldType.email}
                      register={register}
                      placeholder={'Email'}
                      validate={{
                        required
                      }}
                      errors={errors.email}
                    />
                  </div>
                </div>
              </ContentBlock>
              <ContentBlock>
                <div className={styles.row}>
                  <div className={styles.labelWrapper}>
                    <p className={styles.label}>Password</p>
                  </div>
                  <div className={styles.labelRight}>
                    <InputField
                      control={control}
                      defaultValue=""
                      name={'password'}
                      type={InputFieldType.text}
                      register={register}
                      placeholder={'Password'}
                      validate={{
                        passwordRule
                      }}
                      errors={errors.password}
                    />
                    <AppButton
                      htmlType="button"
                      onClick={onGeneratePassword}
                      btnType={ButtonsType.borderedTransparent}
                      label="Generate Password"
                    />
                  </div>
                </div>
              </ContentBlock>
            </>
          )}
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.teamSelect')}</p>
                </div>
                <div className={styles.labelRight}>
                  <CustomSelect<Partial<ITeam>>
                    key={new Date().getMilliseconds().toString()}
                    data={teamsData! || []}
                    placeholder={''}
                    loading={isLoading}
                    name={'teamId'}
                    required
                    control={control}
                    isMultiple={false}
                    register={register}
                    defaultValue={data?.teamId || ''}
                    errors={errors.teamId}
                  />
                </div>
              </div>
            </>
          </ContentBlock>
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.userMailingList.title')}</p>
                </div>
                <div className={styles.labelRight}>
                  <CheckboxComponent
                    control={control}
                    defaultValue={!!data?.mailingList?.alerts}
                    register={register}
                    name="mailingList.alerts"
                    label={t('pages.admin.userMailingList.alerts')}
                  />
                </div>
              </div>
            </>
          </ContentBlock>
          <FormErrorsLabel message={globalError} />
        </div>
      )}
    </DashboardLayout>
  );
};
