import { Close as CloseIcon } from '@mui/icons-material';
import classNames from 'classnames';
import { Title, TitleType } from 'components/Title';
import useLockedBody from 'hooks/useLockedBody';
import React, { useEffect } from 'react';

import styles from './styles.module.scss';

interface IPopupProps {
  children: React.ReactElement;
  title: string;
  onClose: () => void;
  size?: 'small' | 'normal';
  fullWidth?: boolean;
  additionalClass?: string;
  hideCloseIcon?: boolean;
}

const Popup: React.FC<IPopupProps> = ({
  children,
  title,
  onClose,
  size = 'normal',
  fullWidth,
  additionalClass,
  hideCloseIcon
}) => {
  const titleType = size === 'normal' ? 'h2' : 'h3';
  const [_, setLocked] = useLockedBody();

  useEffect(() => {
    setLocked(true);

    return () => {
      setLocked(false);
    };
  }, []);

  return (
    <>
      <div className={classNames(styles.wrapper, additionalClass, styles[size], { [styles.fullWidth]: !!fullWidth })}>
        <div className={styles.heading}>
          <Title type={TitleType[titleType]}>{title}</Title>
          {!hideCloseIcon && <CloseIcon onClick={onClose} className={styles.closeIcon} />}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.overlay} />
    </>
  );
};

export { Popup };
