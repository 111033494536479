import { useAppDispatch, useAppSelector } from 'hooks';
import React, { JSX, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFiltersDatasource, setDatasourceFilters, updateRequestedListPage } from 'store/slices/filtersSlice';
import { Datasource } from 'types';
import { getDateSourceIcon } from 'utils';

const useDataSource = (loading = false) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filteredDatasource = useAppSelector(getFiltersDatasource);
  const sources = [1, 2, 3, 4, 6];
  const onClick = useCallback(
    (dataSource: Datasource) => {
      if (loading) {
        return;
      }

      dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'responderFilters' }));
      dispatch(setDatasourceFilters(dataSource));
    },
    [dispatch, loading]
  );

  const dataSourceConfig: {
    Icon: JSX.Element;
    title: string;
    counter?: number;
    dataSource: Datasource;
    onClick: () => void;
    isActive: boolean;
  }[] = useMemo(() => {
    return sources.map((dataSource) => {
      const Icon = getDateSourceIcon(dataSource);
      return {
        Icon: <Icon />,
        title: t(`dataSource.${dataSource}`),
        dataSource: dataSource,
        onClick: () => {
          onClick(dataSource);
        },
        disabled: loading,
        isActive: filteredDatasource.some((itemFiltered) => itemFiltered === dataSource)
      };
    });
  }, [filteredDatasource, loading, onClick, t]);
  return {
    dataSourceConfig
  };
};

export default useDataSource;
