import HomePage from 'pages/HomePage';
import React from 'react';

import PagePopup from './PagePopup';
import styles from './styles.module.scss';

const OutOfStock: React.FC<{ Popup?: React.ReactElement }> = ({ Popup }) => {
  return (
    <div className={styles.wrapper}>
      <HomePage blurContent />
      {Popup || <PagePopup />}
    </div>
  );
};

export default OutOfStock;
