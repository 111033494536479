import { APP_ROUTS } from 'const';
import { useAppSelector } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUser } from 'store/slices/authSlice';
import { ITeam } from 'types';

import { Spinner } from '../Spinner';

interface IProtectedAvailablePageProps {
  page: keyof ITeam['availablePages'];
  Component: React.ElementType;
}

const ProtectedAvailablePage = ({ page, Component }: IProtectedAvailablePageProps) => {
  const user = useAppSelector(getUser);

  if (!user._id || !user.team) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  const {
    team: { availablePages }
  } = user;

  if (!availablePages[page]) {
    return <Navigate to={APP_ROUTS.HOME} />;
  }

  return <Component />;
};

export default ProtectedAvailablePage;
